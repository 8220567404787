import React from 'react';
import { ReactComponent as Sort } from '../../../../icons/sort.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setGetHistoryEvent } from '../../../../../store/history_event/thunk/setGetHistoryEvent';
import { format } from 'date-fns';
import PaginationLogs from '../paginationLogs';
import FakeTable from '../FakeTable';
import { translate } from '../../../../../settings/dictionary';

const Logs = ({
}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetHistoryEvent({
            page: 1,
            rowsPerPage: 5,
        }));
    }, []);

    const list = useSelector(state => state.eventHistory.list);

    const columns = [
        { label: translate('common.66'), value: "created_at"},
        { label: translate('common.134'), value: "action" },
        { label: "IP", value: "ip" },
        { label: translate('common.480'), value: "device" }
    ];

    const handleGetName = (value) => {
        const name = value?.toString().toLowerCase();
        switch(name) {
            case "login": return translate('common.244');
            case "created wallet": return translate('common.245');
            case "change password": return translate('common.465');
        }
    }

    return (
        <div>
            <div className="wallet-table__header">
                {
                    columns.map((item) => (
                        <div className="wallet-table__header__column">
                            <span className="wallet-table__header__column--text">{item.label}</span>
                            <Sort className="wallet-table__header__column--sort-icon" />
                        </div>
                    ))
                }
            </div>

            {
                list?.data?.map((item, key) => (
                    <div className={`wallet-table__header ${key && "wallet-table__header--border"}`} >
                        <div className="wallet-table__header__row">
                            <span className="wallet-table__header__row--text">{format(new Date(Number(item.created_at)), 'HH:mm dd.MM.yyyy')}</span>
                        </div>
                        <div className="wallet-table__header__row">
                            <span className="wallet-table__header__row--text">{handleGetName(item.event)}</span>
                        </div>
                        <div className="wallet-table__header__row">
                            <span className="wallet-table__header__row--text">{item.ip}</span>
                        </div>
                        <div className="wallet-table__header__row">
                            <span className="wallet-table__header__row--text">{item.browser}</span>
                        </div>
                    </div>
                ))
            }
            {
                list?.loading && (
                    <FakeTable
                        rows={5}
                        columns={4}
                        height_row={71}
                    />
                )
            }
            {
                list.pagination.totalPage > 1 && <PaginationLogs pagination={list.pagination} />
            }
        </div>
    )
}

export default Logs;