import React from 'react';
import Banner from '../../icons/banner/referral.png';
import Filter from './block/filter';
import Control from '../control';
import { translate } from '../../../settings/dictionary';

const Referral = () => {
    const columns = [
        { label: translate('common.152'), name: "period", width: "165%" },
        { label: translate('common.543'), name: "referral", width: "165%" },
        { label: translate('common.68'), name: "amount", width: "165%" }
    ];


    const list = {
        pagination: {
            total: 1,
            currentPage: 1,
            totalPage: 1
        },
        data: []
    }

    const handleChangePage = (page) => {
        // dispatch(setGetPromo({
        //     page,
        //     rowsPerPage: 10,
        // }));
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 20}}>
            <div>
                <img src={Banner} alt="" style={{width: '100%', display: 'block'}}/>
            </div>
            <Filter />
            <Control.Table
                columns={columns}
                list={list}
                handleChangePage={handleChangePage}
            />
        </div>
    )
}

export default Referral;