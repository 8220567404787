import React from 'react';
import { ReactComponent as ChipTopLeft } from './chipTopLeft.svg';
import { ReactComponent as ChipTopRight } from './chipTopRight.svg';
import { ReactComponent as ChipBottomRight } from './chipBottomRight.svg';
import { ReactComponent as ChipBottomLeft } from './chipBottomLeft.svg';
import { ReactComponent as ChipPaddingLeft } from './chipPaddingLeft.svg';
import { ReactComponent as ChipPaddingRight } from './chipPaddingRight.svg';
import { ReactComponent as ChipPaddingBottom } from './chipPaddingBottom.svg';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Success } from './success.svg';
import { translate } from '../../../../settings/dictionary';

const ModalSuccessRegistration = ({
    handleIsShowSuccesRegistration
}) => {
    return (
        <div style={{ position: 'fixed', zIndex: 999999, top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.2)' }}>
            <div style={{
                position: 'absolute',
                top: 'calc(50% - 300px)',
                left: 'calc(50% - 496.5px)',
                background: '#211F1C',
                minWidth: 993,
                width: 993,
                maxWidth: 993,
                minHeight: 572,
                maxHeight: 572,
                height: 572,
                zIndex: 11,
                border: '5px solid #FFD951',
                borderRadius: 20,
            }}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: 30,
                    overflow: 'hidden'
                }}>
                    <div>
                        <Success style={{width: 58, height: 58}} />
                    </div>
                    <div style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 26,
                            fontWeight: 400
                        }}>{translate('common.228')} </span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 26,
                            fontWeight: 400
                        }}>{translate('common.229')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 26,
                            fontWeight: 400
                        }}>{translate('common.230')}</span>
                    </div>
                    <ChipTopLeft style={{ position: 'absolute', top: 18, left: 23 }} />
                    <ChipTopRight style={{ position: 'absolute', top: -5, right: 136 }} />
                    <ChipBottomLeft style={{ position: 'absolute', bottom: 65, left: 22 }} />
                    <ChipBottomRight style={{ position: 'absolute', bottom: 7, right: 16 }} />
                    <ChipPaddingRight style={{ position: 'absolute', top: 173, right: 62 }} />
                    <ChipPaddingLeft style={{ position: 'absolute', top: 115, left: 279 }} />
                    <ChipPaddingBottom style={{ position: 'absolute', bottom: 57, right: 309 }} />
                    <Cross style={{ position: 'absolute', top: 30, right: 30, cursor: 'pointer' }} onClick={handleIsShowSuccesRegistration}/>
                </div>
            </div>
        </div>
    )
}

export default ModalSuccessRegistration;