import { setGetTemplatePromoAction } from '../action';
import { getTemplatePromo } from '../api/getTemplatePromo';

export const setGetTemplatePromo = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTemplatePromo(params);
            dispatch(setGetTemplatePromoAction({
                data: data.data,
                pagination: data.pagination
            }))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}