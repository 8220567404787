import React from 'react';
import BackogrundImage from './bg.png';
import {ReactComponent as Logo} from './logo.svg';
import { translate } from '../../../../settings/dictionary';
import { useRouter } from '../../../../hooks/useRouter';
import { Select } from 'antd';

const Header = ({
    handleIsShowSignup,
    handleIsShowSignin
}) => {
    const {pathname} = useRouter();

    const handleGetTitle = () => {
        switch(pathname) {
            case "/": return translate('common.310');
            case "/privacy": return translate('common.590');
            case "/agreements": return translate('common.591');
        }
    }

    const countries = [
        { code: 'ru', label: 'Russian Federation', label: 'ru' },
        { code: 'us', label: 'United States', label: 'en' }
    ];

    return (
        <div style={{background: `url(${BackogrundImage})`, backgroundSize: 'cover', height: 391, width: '100%', position: 'relative'}}>
            <div style={{position: 'absolute', left: 5, top: 30}}>
                    <Select onChange={(v) => {
                        localStorage.setItem('language', v);
                        window.location.replace(window.location.origin)
                    }}
                        value={localStorage.getItem('language') || 'RU'} style={{ background: 'none', border: 'none', color: '#fff' }}
                    >
                        {countries.map((c, key) => (
                            <Select.Option key={c.code} value={c.code}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                                    alt={`Flag of ${c.label}`}
                                />
                                <span style={{paddingLeft: 5}}>{c.label?.toUpperCase()}</span>
                               
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            <div style={{display: 'flex', columnGap: 10, paddingTop: 20, justifyContent: 'flex-end', padding: '20px 18px 0'}}>
                <div onClick={handleIsShowSignup} style={{padding: '15px 30px', borderRadius: 20, background: '#7b549e', boxShadow: '0px 0px 4px 0px rgba(255, 255, 255, 0.25)'}}>
                    <span style={{
                        color: '#FFF',
                        fontSize: 16,
                        fontWeight: 700,
                        textTransform: 'uppercase'
                    }}>{translate('common.247')}</span>
                </div>
                <div onClick={handleIsShowSignin} style={{padding: '15px 30px', borderRadius: 20, background: '#7b549e', boxShadow: '0px 0px 4px 0px rgba(255, 255, 255, 0.25)'}}>
                    <span style={{
                        color: '#FFF',
                        fontSize: 16,
                        fontWeight: 700,
                        textTransform: 'uppercase'
                    }}>{translate('common.510')}</span>
                </div>
            </div>
            <div style={{paddingTop: 40, textAlign: 'center', display: 'flex', flexDirection: 'column', rowGap: 30}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 24,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>
                    {handleGetTitle()}
                </span>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Logo />
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div 
                        onClick={handleIsShowSignup}
                    style={{
                        padding: '15px 30px',
                        borderRadius: 20,
                        background: '#7B549E'
                    }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 18,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.335')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;