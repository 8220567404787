import React from 'react';
import { ReactComponent as ArrowRightNext } from '../../../../../../components/assets/arrowrightnext.svg';
import { ReactComponent as ArrowLeftBack } from '../../../../../../components/assets/arrowleftback.svg';
import { translate } from '../../../../../../../settings/dictionary';

const Step2 = ({
    step,
    setValue,
    promo_type
}) => {

    const handleGetNamePromo = (value) => {
        const name = value?.toString().toLowerCase();
        switch (name) {
            case "direct_link": return "Direct Link";
            case "landing": return translate('common.270');
            case "thematics_landing": return translate('common.271');
            case "banners": return translate('common.536');
            case "frame_slots": return translate('common.495');
            case "video": return translate('common.272');
        }
    }

    return (
        <div className="create-promo__step-one" style={{ background: step > 2 && 'rgba(123, 84, 158, 0.20)' }}>
            <span className="create-promo__step-one--title">{translate('common.140')}</span>
            {
                step >= 2 && (
                    <>
                        <div style={{ height: 19.5 }}>
                            <span lassName="create-promo__step-one--text">{handleGetNamePromo(promo_type)}</span>
                        </div>
                        {
                            step === 2 && (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="create-promo__step-one__next" onClick={() => setValue('step', step - 1)}>
                                            <ArrowLeftBack />
                                            <span lassName="create-promo__step-one--text">{translate('common.532')}</span>
                                        </div>

                                        {
                                            promo_type && (
                                                <div className="create-promo__step-one__next" onClick={() => setValue('step', step + 1)}>
                                                    <span lassName="create-promo__step-one--text">{translate('common.531')}</span>
                                                    <ArrowRightNext />
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default Step2;