import React from 'react';
import { translate } from '../../../../settings/dictionary';

const Postback = () => {
    return (
        <div>
            <h3>{translate('common.189')}</h3>
        </div>
    )
}

export default Postback;