import React from 'react';
import Filter from './block/filter';
import { setGetGeneral } from '../../../../store/statistics/thunk/setGetGeneral';
import { useDispatch, useSelector } from 'react-redux';
import { renderer } from '../../../../settings/renderer';
import format from 'date-fns/format';
import Control from '../../../../desktop2/components/control';
import { translate } from '../../../../settings/dictionary';

const General = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetGeneral({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.statistics.general);

    const columns = [
        {
            label: translate('common.152'), 
            name: 'day', 
            width: 100,
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
        },
        {label: translate('common.485'), name: 'offer', width: 200},
        // {label: translate('common.18'), name: 'geo', width: 200},
        {label: translate('common.19'), name: 'stream', width: 300},
        {
            label: 'SubID',
            multiply: true,
            width: 200,
            selector: (item) => [item.sub_1, item.sub_2, item.sub_3, item.sub_4, item.sub_5]?.filter(item => item).join(', ') || '-'
        },
        {
            label:  translate('common.164'),
            name: 'visit_count', 
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.166'), 
            name: 'registration_count',
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.165'), 
            name: 'registration_unique', 
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: 'CR R/B', 
            width: 200,
            multiply: true,
            selector: (item) => {
                const result = item?.registration_count / item?.visit_count * 100;

                return `${renderer.formatAmount(result || 0, 2)}%`;
            }
        },
        {
            label: 'FD', 
            name: 'fd_count',
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: 'FD/RG', 
            multiply: true,
            selector: (item) => {
                const result = item?.fd_count / item?.registration_count * 100;

                return `${renderer.formatAmount(result || 0, 2)}%`;
            },
            width: 200
        },
        {
            label: 'RD', 
            name: 'rd_count', 
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.169'), 
            name: 'total_replenishment', 
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.488'), 
            name: 'total_withdrawals', 
            width: 200,
            selector: renderer.formatAmount
        },
        // {
        //     label: 'CPA', 
        //     name: 'cpa', 
        //     width: 200
        // },
        // {
        //     label: 'Revenue Share', 
        //     name: 'revenue_share', 
        //     width: 200
        // },
        // {
        //     label: translate('common.535'), 
        //     name: 'referral', 
        //     width: 200
        // },
        {
            label: translate('common.301'), 
            name: 'total_fiat_amount', 
            width: 200,
            selector: (v) => renderer.formatAmount(v) + '$'
        }
    ];

    const handleChangePage = (page) => {
        dispatch(setGetGeneral({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.317')}
                </h3>
            </div>
            <div>
                {/* <Filter /> */}
                <Control.Table
                    columns={columns}
                    list={list}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    )
}

export default General;