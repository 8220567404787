import {types} from './types';

const initialState = {
    list: {
        data: [],
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0,
        },
        loading: true
    }
}

export function eventHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_HISTORY_EVENT:
            return { ...state, list: action.payload};
        default:
            return state
    }
}