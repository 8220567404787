import { isFunction } from "lodash";
import { getSignin } from "../api/getSignin";
import { translate } from "../../../settings/dictionary";

export const setGetSignin = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getSignin(params);

            if (data.status === 'success') {
                localStorage.setItem('token', data.token);
                window.location.replace(window.location.origin);
            } else if (isFunction(params?.setError)) {
                params.setError("wrong_password", { types: { require: translate('common.320') } });
            }
            
        } catch(error) {
            if (isFunction(params?.setError)) {
                params.setError("wrong_password", { types: { require: translate('common.320') } });
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}