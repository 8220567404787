import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as Cross } from '../../../../../../components/assets/cross2.svg';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setPostPostback } from '../../../../../../../store/postback/thunk/setPostPostback';
import Select from '../../../../../../../desktop2/components/select';
import { translate } from '../../../../../../../settings/dictionary';

const ModalCreate = ({
    handleUpdateShowModalCreate
}) => {
    const dispatch = useDispatch();

    const { handleSubmit,
        reset,
        register
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            stream: "",
            url: "",
            type: ""
        }
    });

    const { enqueueSnackbar } = useSnackbar();

    const handleShowMessage = ({ message, status }) => {
        if (status === 'success') {
            reset();
            handleUpdateShowModalCreate();
        }
        enqueueSnackbar(message, { variant: status });
    }

    const onSubmit = data => dispatch(setPostPostback(data, handleShowMessage));

    const options = [{
        label: translate('common.214'),
        value: 'registration'
    }, {
        label: translate('common.248'),
        value: 'fd'
    }, {
        label: translate('common.167'),
        value: "rd"
    }, {
        label: translate('common.256'),
        value: 'baseline'
    }]

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="wallet__create">
            <div className="wallet__create__wrap" style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                minWidth: 'auto',
                padding: '40px',
                position: 'relative',
            }}>
                <div className="wallet__create__wrap__cross">
                    <div
                        onClick={() => handleUpdateShowModalCreate()}
                        className="wallet__create__wrap__cross__box"
                        style={{
                            position: 'absolute',
                            top: 61,
                            right: 21
                        }}
                    >
                        <Cross
                            className="wallet__create__wrap__cross__box--icon"
                        />
                    </div>
                </div>
                <div className="wallet__create__wrap__title">
                    <h3 className="wallet__create__wrap__title--text">{translate('common.313')}</h3>
                </div>
                <div>
                    <input
                        {...register('stream')}
                        type="text"
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.19')}
                    />
                </div>
                <Select
                    options={options}
                    placeholder={translate('common.540')}
                    register={register}
                    name="type"
                />
                <div>
                    <input
                        {...register('url')}
                        type="text"
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.471')}
                    />
                </div>
                <div className="wallet__create__wrap__container">
                    <button
                        type="submit"
                        className="wallet__create__wrap__container__button"
                    >
                        <span className="wallet__create__wrap__container__button--text">
                            {translate('common.312')}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ModalCreate;