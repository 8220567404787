import React from 'react';
import LayoutHeader from './Heander';
import Navigation from './Navigation';
import LayoutContent from './Content';

const Layout = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpenClose = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20
        }}>
            <LayoutHeader 
                handleOpenClose={handleOpenClose}
            />
            <Navigation
                isOpen={isOpen}
                handleOpenClose={handleOpenClose}
            />
            <LayoutContent />
        </div>
    )
}

export default Layout