import React from 'react';
import { ReactComponent as ArrowLeft } from '../../../../icons/arrowleft.svg';
import { ReactComponent as ArrowRight } from '../../../../icons/arrowright.svg';
import { useDispatch } from 'react-redux';
import { setGetWithdrawals } from '../../../../../store/withdrawals/thunk/setGetWithdrawals';
import { setGetWithdrawalsAction } from '../../../../../store/withdrawals/action';

const PaginationFinance = ({
    pagination
}) => {
    const dispatch = useDispatch();

    return (
        <div style={{
            display: 'flex',
            columnGap: 20,
            justifyContent: 'center',
            marginTop: 20
        }}>
            <div style={{
                width: 36,
                height: 36,
                borderRadius: 7,
                border: pagination.currentPage === 1 ? '1px solid #666' : '1px solid #7B549E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
                onClick={() => {
                    if (pagination.currentPage > 1) {
                        dispatch(setGetWithdrawalsAction({
                            data: [],
                            pagination: {
                                total: 0,
                                currentPage: 0,
                                totalPage: 0,
                            },
                            loading: true
                        }))
                        dispatch(setGetWithdrawals({
                            page: pagination.currentPage - 1,
                            rowsPerPage: 10
                        }));
                    }
                }}
            >
                <ArrowLeft style={{fill: pagination.currentPage === 1 ? '#666' : '#7B549E',}}/>
            </div>
            {
                pagination.currentPage - 2 < pagination.currentPage && pagination.currentPage - 2 > 0 && (
                    <div
                        style={{
                            width: 36,
                            height: 36,
                            borderRadius: 7,
                            border: '1px solid #7B549E',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            dispatch(setGetWithdrawalsAction({
                                data: [],
                                pagination: {
                                    total: 0,
                                    currentPage: 0,
                                    totalPage: 0,
                                },
                                loading: true
                            }))
                            dispatch(setGetWithdrawals({
                                page: pagination.currentPage - 2,
                                rowsPerPage: 10,
                            }));
                        }}
                    >
                        <span style={{
                            color: '#7B549E',
                            fontFamily: 'Work Sans, sans-serif',
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: '17.304px'
                        }}>{pagination.currentPage - 2}</span>
                    </div>
                )
            }

            {
                pagination.currentPage - 1 < pagination.currentPage && pagination.currentPage - 1 > 0 && (
                    <div
                        style={{
                            width: 36,
                            height: 36,
                            borderRadius: 7,
                            // background: pagination.currentPage === 2 && '#7B549E',
                            border: '1px solid #7B549E',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            dispatch(setGetWithdrawalsAction({
                                data: [],
                                pagination: {
                                    total: 0,
                                    currentPage: 0,
                                    totalPage: 0,
                                },
                                loading: true
                            }))
                            dispatch(setGetWithdrawals({
                                page: pagination.currentPage - 1,
                                rowsPerPage: 10,
                            }));
                        }}
                    >
                        <span style={{
                            color: '#7B549E',
                            fontFamily: 'Work Sans, sans-serif',
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: '17.304px'
                        }}>{pagination.currentPage - 1}</span>
                    </div>
                )
            }

            <div
                style={{
                    width: 36,
                    height: 36,
                    borderRadius: 7,
                    background: '#7B549E',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    dispatch(setGetWithdrawalsAction({
                        data: [],
                        pagination: {
                            total: 0,
                            currentPage: 0,
                            totalPage: 0,
                        },
                        loading: true
                    }))
                    dispatch(setGetWithdrawals({
                        page: pagination.currentPage,
                        rowsPerPage: 10,
                    }));
                }}
            >
                <span style={{
                    color: '#FFFFFF',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: '17.304px'
                }}>{pagination.currentPage}</span>
            </div>

            {pagination.currentPage + 1 < pagination.totalPage && (
                <div
                    style={{
                        width: 36,
                        height: 36,
                        borderRadius: 7,
                        border: '1px solid #7B549E',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        dispatch(setGetWithdrawalsAction({
                            data: [],
                            pagination: {
                                total: 0,
                                currentPage: 0,
                                totalPage: 0,
                            },
                            loading: true
                        }))
                        dispatch(setGetWithdrawals({
                            page: pagination.currentPage + 1,
                            rowsPerPage: 10,
                        }));
                    }}
                >
                    <span style={{
                        color: '#7B549E',
                        fontFamily: 'Work Sans, sans-serif',
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: '17.304px'
                    }}>{pagination.currentPage + 1}</span>
                </div>
            )}

            {
                pagination.currentPage + 2 < pagination.totalPage && (
                    <div
                        style={{
                            width: 36,
                            height: 36,
                            borderRadius: 7,
                            border: '1px solid #7B549E',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            dispatch(setGetWithdrawalsAction({
                                data: [],
                                pagination: {
                                    total: 0,
                                    currentPage: 0,
                                    totalPage: 0,
                                },
                                loading: true
                            }))
                            dispatch(setGetWithdrawals({
                                page: pagination.currentPage + 2,
                                rowsPerPage: 10,
                            }));
                        }}
                    >
                        <span style={{
                            color: '#7B549E',
                            fontFamily: 'Work Sans, sans-serif',
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: '17.304px'
                        }}>...</span>
                    </div>
                )
            }

            {
                pagination.currentPage < pagination.totalPage && (
                    <div style={{
                        width: 36,
                        height: 36,
                        borderRadius: 7,
                        border: pagination.currentPage === pagination.totalPage ? '1px solid #666666' : '1px solid #7B549E',
                        background: pagination.currentPage === pagination.totalPage && '#7B549E',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                        onClick={() => {
                            dispatch(setGetWithdrawalsAction({
                                data: [],
                                pagination: {
                                    total: 0,
                                    currentPage: 0,
                                    totalPage: 0,
                                },
                                loading: true
                            }))
                            dispatch(setGetWithdrawals({
                                page: pagination.totalPage,
                                rowsPerPage: 10,
                            }));
                        }}
                    >
                        <span style={{
                            color: '#7B549E',
                            fontFamily: 'Work Sans, sans-serif',
                            fontSize: 14,
                            fontWeight: 600,
                            lineHeight: '17.304px'
                        }}>{pagination.totalPage}</span>
                    </div>
                )
            }

            <div style={{
                width: 36,
                height: 36,
                borderRadius: 7,
                border: pagination.currentPage === pagination.totalPage ? '1px solid #666666' : '1px solid #7B549E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
            onClick={() => {
                if (pagination.currentPage < pagination.totalPage) {
                    dispatch(setGetWithdrawalsAction({
                        data: [],
                        pagination: {
                            total: 0,
                            currentPage: 0,
                            totalPage: 0,
                        },
                        loading: true
                    }))
                    dispatch(setGetWithdrawals({
                        page: pagination.currentPage + 1,
                        rowsPerPage: 10,
                    }));
                }
            }}
            >
                <ArrowRight style={{fill: pagination.currentPage === pagination.totalPage ? '#666' : '#7B549E',}}/>
            </div>
        </div>
    )
}

export default PaginationFinance;