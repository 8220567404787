import React from 'react';
import Filter from './block/filter';
import Control from '../../control';
import { useDispatch, useSelector } from 'react-redux';
import { setGetForDays } from '../../../../store/statistics/thunk/setGetForDays';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';
import { useForm, useWatch } from 'react-hook-form';

const Days = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetForDays({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.statistics.for_days);

    const columns = [
        {
            label: translate('common.66'), 
            name: 'day', 
            width: 100, 
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
        },
        {
            label:  translate('common.164'), 
            name: 'visite_count',
            width: 150,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.166'), 
            name: 'registration_count', 
            width: 150,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.165'), 
            name: 'registration_unique', 
            width: 150,
            selector: renderer.formatAmount
        },
        {
            label: 'FD', 
            name: 'fd_count', 
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: 'RD', 
            name: 'rd_count', 
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.169'), 
            name: 'total_amount', 
            width: 200,
            selector: renderer.formatAmount
        },
        // {label: translate('common.299'), name: 'betting_rs', width: 200},
        // {label: translate('common.171'), name: 'casino_rs', width: 200},
        {
            label: translate('common.522'), 
            name: 'profit', 
            width: 200,
            selector: renderer.formatAmount
        }
    ];

    const { handleSubmit, setValue, control, register } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            stream_id: ''
        }
    });

    const [stream_id] = useWatch({
        control,
        name: ['stream_id']
    })

    const handleChangePage = (page) => {
        dispatch(setGetForDays({
            page,
            rowsPerPage: 10,
            stream_id
        }));
    }

    const onSubmit = (store) => {
        dispatch(setGetForDays({
            page: 1,
            rowsPerPage: 10,
            stream_id: store.stream_id,
            date_to: store.dateTo ? new Date(store.dateTo).getTime() : undefined,
            date_from: store.dateFrom ? new Date(store.dateFrom).getTime() : undefined
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Filter control={control} register={register} setValue={setValue} />
            <Control.Table
                columns={columns}
                list={list}
                handleChangePage={handleChangePage}
            />
        </form>
    )
}

export default Days;