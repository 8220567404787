import React from 'react';
import WalletTabs from '../walletTabs';
import WalletHeader from '../walletHeader';
import WalletTable from '../walletTable';
import WalletCreate from '../walletCreate';

const Wallet = () => {
    const [isEdit, setIsEdit] = React.useState(false);
    const [isShowWalletCreate, setIsShowWalletCreate] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState('all');

    const handleUpdateMode = () => {
        setIsEdit(!isEdit);
    }

    const handleUpdateShowWalletCreate = () => {
        setIsShowWalletCreate(!isShowWalletCreate);
    }

    return (
        <div className="wallet">
            <WalletHeader handleUpdateMode={handleUpdateMode}/>
            <WalletTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <WalletTable 
                isEdit={isEdit} 
                handleUpdateShowWalletCreate={handleUpdateShowWalletCreate}
                activeTab={activeTab}
            />
            {
                isShowWalletCreate && (
                    <WalletCreate 
                        handleUpdateShowWalletCreate={handleUpdateShowWalletCreate}
                        activeTab={activeTab}
                    />
                )
            }
        </div>
    )
}

export default Wallet;