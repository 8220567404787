import { translate } from "../../../settings/dictionary";

export const faq_list = [
    {
        title: translate('common.336'),
        description: translate('common.337'),
    },
    {
        title: translate('faq.1.4'),
        description: translate('faq.1.5'),
    },
    {
        title: translate('common.338'),
        description: translate('common.339'),
    },
    {
        title: translate('common.340'),
        description: translate('common.341'),
    },
    {
        title: translate('common.342'),
        description: translate('common.343'),
    },
    {
        title: translate('common.344'),
        description: translate('common.345'),
    },
    {
        title: translate('common.346'),
        description: translate('common.347'),
    },
    {
        title: translate('common.348'),
        description: translate('common.349'),
    },
    {
        title: translate('common.352'),
        description: translate('common.353'),
    },
    {
        title: translate('common.354'),
        description: translate('common.355')
    },
    {
        title: translate('common.356'),
        description: translate('common.357'),
    }
];