import React from 'react';
import Home from '../../../documents/home';
import { Route, Switch, Redirect } from 'react-router-dom';
import Profile from '../../../documents/profile';
import Finance from '../../../documents/finance';
import Faq from '../../../documents/faq';
import MyPromo from '../../../documents/promo/my';
import PromoCreate from '../../../documents/promo/create';
import StatisticGeneral from '../../../documents/statistics/general';
import StatisticPromo from '../../../documents/statistics/promo';
import StatisticGamer from '../../../documents/statistics/gamer';
import StatisticKohort from '../../../documents/statistics/kohort';
import StatisticByDay from '../../../documents/statistics/days';

const LayoutContent = () => {
    return (
        <>
            <Switch>
                <Route exact={true} path="/home" render={() => <Home />} />
                <Route exact={true} path="/profile" render={() => <Profile />} />
                <Route exact={true} path="/finance" render={() => <Finance />} />
                <Route exact={true} path="/faq" render={() => <Faq />} />
                <Route exact={true} path="/promo/my" render={() => <MyPromo />} />
                <Route exact={true} path="/promo/create" render={() => <PromoCreate />} />
                <Route exact={true} path="/statistic/general" render={() => <StatisticGeneral />} />
                <Route exact={true} path="/statistic/promo" render={() => <StatisticPromo />} />
                <Route exact={true} path="/statistic/gamer" render={() => <StatisticGamer />} />
                <Route exact={true} path="/statistic/kohort" render={() => <StatisticKohort />} />
                <Route exact={true} path="/statistic/days" render={() => <StatisticByDay />} />
                <Redirect exact from="/" to="home" />
            </Switch>
        </>
    )
}

export default LayoutContent;