import {types} from './types';

const initialState = {
    count: {
        visite: 0,
        registration: 0,
        total_amount: 0
    }
}

export function informationReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_INFORMATION:
            return { ...state, count: action.payload};
        default:
            return state
    }
}