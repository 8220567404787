import React from 'react';
import { useRouter } from '../../hooks/useRouter';
import HeaderLanding from './header';
import Contacts from '../components/landing/contacts';
import SignIn from '../components/landing/signin';
import SignUp from '../components/landing/signup';
import ModalSuccessRegistration from '../components/landing/verification';
import { translate } from '../../settings/dictionary';

const AgreementRules = () => {
    const [isShowSignIn, setIsShowSignIn] = React.useState(false);
    const [isShowSignUp, setIsShowSignUp] = React.useState(false);
    const [isShowSuccessRegistration, setIsShowSuccessRegistration] = React.useState(false);
    const router = useRouter();

    const handleIsShowSignin = () => {
        setIsShowSignIn(!isShowSignIn);
    }

    const handleIsShowSignup = () => {
        setIsShowSignUp(!isShowSignUp);
    }

    const handleIsShowSuccesRegistration = () => {
        setIsShowSuccessRegistration(!isShowSuccessRegistration);
        setIsShowSignUp(false);
    }

    const handleOpenIsShowSuccessRegistration = () => {
        setIsShowSuccessRegistration(true)
    }

    return (
        <div style={{
            background: '#050021',
            height: '100%',
            width: '100%',
            paddingBottom: 100,
            position: 'relative'
        }}>
            <HeaderLanding
                handleIsShowSignin={handleIsShowSignin}
                handleIsShowSignup={handleIsShowSignup}
                label={translate('common.591')}
            />
            <div style={{ width: 1337, margin: '50px auto' }}>
                <div style={{ color: '#ffffff' }}>
                    <h3 className="agreement__title__big" style={{ color: '#ffffff' }}>{translate('common.372')}</h3>
                    <ul className="agreement__list" style={{ color: '#ffffff' }}>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.358')}</span> — {translate('common.373')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.359')}</span> — {translate('common.374')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.360')}</span> — {translate('common.375')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.361')}</span> — {translate('common.376')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.362')}</span> — {translate('common.377')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.363')}</span> — {translate('common.378')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.364')}</span> — {translate('common.379')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.365')}</span> — {translate('common.380')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.366')}</span> — {translate('common.381')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.367')}</span> — {translate('common.382')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.368')}</span> — {translate('common.383')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.369')}</span> — {translate('common.384')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold">{translate('common.370')}</span> — {translate('common.385')}</li>
                        <li className="agreement__text" style={{ color: '#ffffff' }}><span className="agreement__bold"></span> — {translate('common.386')}</li>
                    </ul>
                    <h3 className="agreement__title" style={{ color: '#ffffff' }}>{translate('common.387')}</h3>
                    <div className="agreement__rules">
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.388')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.389')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.390')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.391')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.392')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.393')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.394')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.395')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.396')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.397')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.398')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.399')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.400')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.401')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.402')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.403')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.404')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.405')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.406')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.407')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.408')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.409')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.410')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.411')}</span>
                    </div>
                    <h3 className="agreement__title" style={{ color: '#ffffff' }}>{translate('common.412')}</h3>
                    <div className="agreement__comission">
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.413')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.414')}</span>
                        <ul className="agreement__list__comission">
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.415')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.416')}</li>
                        </ul>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.417')}</span>
                    </div>
                    <h3 className="agreement__title" style={{ color: '#ffffff' }}>{translate('common.418')}</h3>
                    <div className="agreement__calculation">
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.419')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.420')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.421')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.422')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.423')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.424')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.425')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.426')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.427')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.428')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.429')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.430')}</span>
                    </div>
                    <h3 className="agreement__title" style={{ color: '#ffffff' }}>{translate('common.431')}</h3>
                    <div className="agreement__calculation">
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.432')}</span>
                        <ul className="agreement__list__comission">
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.433')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.434')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.435')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.436')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.437')}</li>
                        </ul>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.438')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.439')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.440')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.441')}</span>
                        <ul className="agreement__list__comission">
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.442')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.443')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.444')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.445')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.446')}</li>
                        </ul>
                    </div>
                    <h3 className="agreement__title" style={{ color: '#ffffff' }}>{translate('common.447')}</h3>
                    <div className="agreement__calculation">
                        <ul className="agreement__list__comission">
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.448')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.449')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.450')}</li>
                            <li className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.451')}</li>
                        </ul>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.452')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.453')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.454')}</span>
                        <span className="agreement__text" style={{ color: '#ffffff' }}>{translate('common.455')}</span>
                    </div>
                </div>
                <Contacts />
                <div style={{ display: 'flex', columnGap: 10 }}>
                    <span onClick={() => router.history.push('/privacy')} style={{
                        color: '#FFF',
                        fontSize: 20,
                        fontWeight: 400,
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>{translate('common.590')}</span>
                    <span onClick={() => router.history.push('/agreements')} style={{
                        color: '#FFF',
                        fontSize: 20,
                        fontWeight: 400,
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>{translate('common.591')}</span>
                </div>
                <div>
                    <span style={{color: '#fff'}}>
                        {translate('common.596')}
                    </span>
                </div>
            </div>
            {
                isShowSignIn && (
                    <SignIn handleIsShowSignin={handleIsShowSignin} />
                )
            }
            {
                isShowSignUp && (
                    <SignUp handleIsShowSignup={handleIsShowSignup} handleOpenIsShowSuccessRegistration={handleOpenIsShowSuccessRegistration} />
                )

            }
            {
                isShowSuccessRegistration && (
                    <ModalSuccessRegistration handleIsShowSuccesRegistration={handleIsShowSuccesRegistration} />
                )
            }
        </div>
    )
}

export default AgreementRules;