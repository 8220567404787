import {types} from './types';

const initialState = {
    list: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0,
        },
        data: [],
        loading: true
    }
}

export function withdrawalsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_WITHDRAWALS:
            return { ...state, list: action.payload};
        default:
            return state
    }
}