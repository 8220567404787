import { combineReducers } from 'redux'
import { authReducer } from '../sign/reducer';
import { eventHistoryReducer } from '../history_event/reducer';
import { walletReducer } from '../wallet/reducer';
import { offersReducer } from '../offers/reducer';
import { promoReducer } from '../promo/reducer';
import { trafficReducer } from '../traffic/reducer';
import { profileReducer } from '../profile/reducer';
import { postbackReducer } from '../postback/reducer';
import { templatePromoReducer } from '../template_promo/reducer';
import { informationReducer } from '../information/reducer';
import { statisticsReducer } from '../statistics/reducer';
import { financeReducer } from '../financeOperation/reducer';
import { withdrawalsReducer } from '../withdrawals/reducer';
import { subaffiliatsReducer } from '../subaffiliats/reducer';

export const rootReducer = (history) => {
  return combineReducers({
    auth: authReducer,
    eventHistory: eventHistoryReducer,
    wallet: walletReducer,
    offers: offersReducer,
    promo: promoReducer,
    traffic: trafficReducer,
    profile: profileReducer,
    postback: postbackReducer,
    templatePromo: templatePromoReducer,
    information: informationReducer,
    statistics: statisticsReducer,
    finance: financeReducer,
    withdrawals: withdrawalsReducer,
    subaffiliats: subaffiliatsReducer
  })
}