import React from 'react';
import {ReactComponent as Book} from './book.svg';
import {ReactComponent as Security} from './security.svg';
import {ReactComponent as Key} from './key.svg';
import {ReactComponent as Magnet} from './magnet.svg';
import {ReactComponent as Diamond} from './diamond.svg';
import {ReactComponent as Prize} from './prize.svg';
import {ReactComponent as Vertical} from './vertical.svg';
import {ReactComponent as Horizontal} from './horizontal.svg';
import Btn from '../components/btn';
import { translate } from '../../../../settings/dictionary';

const Why = ({
    handleIsShowSignup
}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 55 }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-1.1px',
                    lineHeight: '60px'
                }}>{translate('common.520')}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: 80, position: 'relative', marginBottom: 66}}>
                <div style={{
                    height: 100,
                    width: 392,
                    display: 'flex',
                    columnGap: 20
                }}>
                    <Book style={{minWidth: 100, width: 100, maxWidth: 100, height: 100, minHeight: 100, maxHeight: 100}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', columnGap: 3, marginBottom: 5}}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.4px'
                            }}>{translate('common.466')}</span>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '1.9px'
                            }}>{translate('common.231')}</span>
                        </div>
                        <span
                            style={{
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: '1px'
                            }}
                        >{translate('common.232')}</span>
                    </div>
                </div>
                <div style={{
                    height: 100,
                    width: 392,
                    display: 'flex',
                    columnGap: 20
                }}>
                    <Security style={{minWidth: 100, width: 100, maxWidth: 100, height: 100, minHeight: 100, maxHeight: 100}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: 5}}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2px'
                            }}>{translate('common.233')}</span>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '1.9px'
                            }}>{translate('common.234')}</span>
                        </div>
                        <span
                            style={{
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: '1px'
                            }}
                        >{translate('common.91')}</span>
                    </div>
                </div>
                <div style={{
                    height: 100,
                    width: 392,
                    display: 'flex',
                    columnGap: 20
                }}>
                    <Key style={{minWidth: 100, width: 100, maxWidth: 100, height: 100, minHeight: 100, maxHeight: 100}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', columnGap: 3, marginBottom: 5}}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '1.7px'
                            }}>{translate('common.332')}</span>
                        </div>
                        <span
                            style={{
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: '1.2px'
                            }}
                        >{translate('common.235')}</span>
                    </div>
                    
                </div>
                <div style={{
                    height: 100,
                    width: 392,
                    display: 'flex',
                    columnGap: 20
                }}>
                    <Magnet style={{minWidth: 100, width: 100, maxWidth: 100, height: 100, minHeight: 100, maxHeight: 100}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', columnGap: 3, marginBottom: 5}}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.5px'
                            }}>{translate('common.236')}</span>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '1.9px'
                            }}>{translate('common.237')}</span>
                        </div>
                        <span
                            style={{
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: '1px'
                            }}
                        >{translate('common.238')}</span>
                    </div>
                </div>
                <div style={{
                    height: 100,
                    width: 392,
                    display: 'flex',
                    columnGap: 20
                }}>
                    <Diamond style={{minWidth: 100, width: 100, maxWidth: 100, height: 100, minHeight: 100, maxHeight: 100}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'column', columnGap: 3, marginBottom: 5}}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.2px'
                            }}>{translate('common.509')}</span>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.1px'
                            }}>{translate('common.239')}</span>
                        </div>
                        <span
                            style={{
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: '1.2px'
                            }}
                        >{translate('common.240')}</span>
                    </div>
                </div>
                <div style={{
                    height: 100,
                    width: 392,
                    display: 'flex',
                    columnGap: 20
                }}>
                    <Prize style={{minWidth: 100, width: 100, maxWidth: 100, height: 100, minHeight: 100, maxHeight: 100}}/>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', columnGap: 3, marginBottom: 5}}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.5px'
                            }}>{translate('common.241')}</span>
                            <span style={{
                                color: '#FFF',
                                fontSize: 18,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.2px'
                            }}>{translate('common.242')}</span>
                        </div>
                        <span
                            style={{
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: 14,
                                fontWeight: 400,
                                letterSpacing: '1px'
                            }}
                        >{translate('common.243')}</span>
                    </div>
                </div>
                <Vertical style={{position: 'absolute', top: 0, left: 432}}/>
                <Vertical style={{position: 'absolute', top: 0, right: 428}}/>
                <Horizontal style={{position: 'absolute', top: 136, left: 0}} />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Btn
                    text={translate('common.335')}
                    small
                    handleClick={handleIsShowSignup}
                />
            </div>
           
        </div>
    )
}

export default Why;