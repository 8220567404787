import {types} from './types';

const initialState = {
    view: {},
    social_network: [],
}

export function profileReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_PROFILE:
            return { ...state, view: action.payload};
        
        case types.SET_GET_SOCIAL_NETWORK:
            return { ...state, social_network: action.payload};
        default:
            return state
    }
}