import {types} from './types';

const initialState = {
    list: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0
        },
        data: []
    }
}

export function templatePromoReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_TEMPLATE_PROMO: 
            return { ...state, list: action.payload};
        default:
            return state
    }
}