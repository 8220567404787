import React from 'react';
import Checkbox from '../checkbox';

const ItemPromo = ({
    Icon,
    label,
    type,
    watch,
    setValue
}) => {
    return (
        <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            padding: '10px 20px', 
            background: '#fff', 
            boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25', 
            borderRadius: 5, 
            width: 'calc(33% - 50px)' 
        }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 6 }}>
                <Icon />
                <span>{label}</span>
            </div>
            <div>
                <Checkbox name="promo_type" watch={watch} setValue={setValue} type={type} label="" />
            </div>
        </div>
    )
}

export default ItemPromo;