import {types} from './types';

const initialState = {
    list: {
        data: [],
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0,
        }
    }
}

export function financeReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_FINANCE_OPERATION:
            return { ...state, list: action.payload};
        default:
            return state
    }
}