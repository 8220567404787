import React from 'react';
import HeaderCreatePromo from './block/Header';
import { useForm } from 'react-hook-form';
import ContentStep3 from './block/Content/step3';
import ContentStep1 from './block/Content/step1';
import ContentStep2 from './block/Content/step2';
import { useDispatch } from 'react-redux';
import { setPostPromo } from '../../../../store/promo/thunk/setPostPromo';

const PromoCreate = () => {
    const { 
        handleSubmit, 
        setValue, 
        watch, 
        control,
        reset, 
        register,
        getValues 
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            step: 1,
            subid: [{
                value: ""
            }],
            id: "",
            country: "all"
        }
    });
    const dispatch = useDispatch();
    const step = watch('step');

    const onSubmit = (store) => {
        dispatch(setPostPromo(store))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{paddingBottom: 100}}>
            <HeaderCreatePromo watch={watch} setValue={setValue} register={register} />
            {
                step === 1 && <ContentStep1 control={control} register={register} watch={watch} setValue={setValue}/>
            }
            {
                step === 2 && <ContentStep2 control={control} register={register} watch={watch} setValue={setValue}/>
            }
            {
                step === 3 && <ContentStep3 control={control} register={register} watch={watch} setValue={setValue}/>
            }
        </form>
    )
}

export default PromoCreate;