import React from 'react';
import Filter from './block/filter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetGamers } from '../../../../store/statistics/thunk/setGetGamers';
import Control from '../../control';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';
import { useForm, useWatch } from 'react-hook-form';

const Gamer = () => {
    const dispatch = useDispatch();
  
    const { handleSubmit, setValue, watch, control, register } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            currency: '',
            stream_id: '',
            page: 1
        }
    });

    const [currency, stream_id] = useWatch({
        control,
        name: ['currency', 'stream_id']
    })

    React.useEffect(() => {
        dispatch(setGetGamers({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const columns = [
        { label: translate('common.16'), name: 'id', width: 100, },
        { label: translate('common.17'), name: 'platform_id', width: 150, },
        { label: translate('common.19'), name: 'stream', width: 300, },
        {
            label: translate('common.534'),
            multiply: true,
            width: 200,
            selector: (item) => [item.sub_1, item.sub_2, item.sub_3, item.sub_4, item.sub_5]?.filter(item => item).join(', ') || '-'
        },
        { 
            label: translate('common.22'), 
            name: 'created_at', 
            width: 200,
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
         },
        { 
            label: translate('common.23'), 
            name: 'last_visite',
            width: 200, 
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy HH:mm')
            }
        },
        { label: translate('common.25'), name: 'currency', width: 100, },
        { 
            label: translate('common.26'), 
            name: 'count_deposit', 
            width: 200, 
            selector: renderer.formatAmount
        },
        { 
            label: translate('common.27'), 
            name: 'sum_deposit',
            width: 200,
            selector: renderer.formatAmount
        },
        { 
            label: translate('common.494'),
            name: 'count_withdrawals', 
            width: 200,
            selector: renderer.formatAmount
        },
        { 
            label: translate('common.31'), 
            name: 'sum_withdrawals', 
            width: 200,
            selector: renderer.formatAmount
         },
        { 
            label: translate('common.276'), 
            name: 'profit', 
            width: 200,
            selector: renderer.formatAmount
        }
    ];

    const list = useSelector(state => state.statistics.gamers);

    const handleChangePage = (page) => {
        dispatch(setGetGamers({
            page,
            rowsPerPage: 10,
            stream_id: stream_id || undefined,
            currency: currency || undefined
        }));
    }


    const onSubmit = (store) => {
        dispatch(setGetGamers({
            page: 1,
            rowsPerPage: 10,
            stream_id: store?.stream_id || undefined,
            currency: store?.currency || undefined,
            date_to: store.dateTo ? new Date(store.dateTo).getTime() : undefined,
            date_from: store.dateFrom ? new Date(store.dateFrom).getTime() : undefined
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Filter setValue={setValue} watch={watch} control={control} register={register} />
            <Control.Table 
                columns={columns}
                list={list} 
                handleChangePage={handleChangePage} 
            />
        </form>
    )
}

export default Gamer;