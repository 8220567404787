import React from 'react';
import ModalGlobal from './block/modal_global';
import ModalApplication from './block/modal_application';
import ModalCreate from './block/modal_create';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPostback } from '../../../../../store/postback/thunk/setGetPostback';
import { format } from 'date-fns';
import PaginationPostback from './paginationPostback';
import { translate } from '../../../../../settings/dictionary';

const Postback = () => {
    const dispatch = useDispatch();

    const [isShowModalGlobal, setIsShowModalGlobal] = React.useState(false);
    const [isShowModalApplication, setIsShowModalApplication] = React.useState(false);
    const [isShowModalCreate, setIsShowModalCreate] = React.useState(false);

    const handleUpdateShowModalGlobal = () => {
        setIsShowModalGlobal(!isShowModalGlobal);
    }

    const handleUpdateShowModalApplication = () => {
        setIsShowModalApplication(!isShowModalApplication);
    }

    const handleUpdateShowModalCreate = () => {
        setIsShowModalCreate(!isShowModalCreate);
    }

    React.useEffect(() => {
        dispatch(setGetPostback({
            page: 1,
            rowsPerPage: 5
        }))
    }, [])

    const columns = [
        { label: translate('common.246'), value: "type", align: 'flex-start' },
        { label: translate('common.19'), value: "stream", align: 'flex-end' }
    ];

    const list = useSelector((state) => state.postback.list);

    const handleGetType = (type) => {
        const value = type?.toString().toLowerCase();

        switch(value){
            case "registration": return translate('common.214');
            case "fd": return translate('common.248');
            case "rd": return translate('common.249');
            default: return translate('common.250')
        }
    }

    return (
        <div>
            <div className="postback__header" style={{marginBottom: 20}}>
                {/* <div className="postback__header__btn" onClick={handleUpdateShowModalGlobal}>
                    <Settings />
                    <span className="postback__header__btn--text">{translate('common.252')}</span>
                </div>
                <div className="postback__header__btn" onClick={handleUpdateShowModalApplication}>
                    <Settings />
                    <span className="postback__header__btn--text">{translate('common.251')}</span>
                </div> */}
                <div className="postback__header__btn" onClick={handleUpdateShowModalCreate} style={{position: 'relative', top: 0, right: 0, width: '100%', justifyContent: 'center'}}>
                    <span className="postback__header__btn--text">{translate('common.312')}</span>
                </div>
            </div>
            {
                isShowModalGlobal && <ModalGlobal handleUpdateShowModalGlobal={handleUpdateShowModalGlobal} />
            }
            {
                isShowModalApplication && <ModalApplication handleUpdateShowModalApplication={handleUpdateShowModalApplication} />
            }
            {
                isShowModalCreate && <ModalCreate handleUpdateShowModalCreate={handleUpdateShowModalCreate} />
            }
            <div>
                <div className="wallet-table__header">
                    {
                        columns.map((item) => (
                            <div className="wallet-table__header__column" style={{width: '50%', justifyContent: item.align}}>
                                <span className="wallet-table__header__column--text">{item.label}</span>
                                {/* <Sort className="wallet-table__header__column--sort-icon" /> */}
                            </div>
                        ))
                    }
                </div>

                {
                    list?.data?.map((item, key) => (
                        <div className={`wallet-table__header ${key && "wallet-table__header--border"}`} style={{alignItems: 'flex-start'}}>
                            <div className="wallet-table__header__row" style={{width: '50%'}}>
                                <span className="wallet-table__header__row--text">{handleGetType(item.type)}</span>
                            </div>
                            <div className="wallet-table__header__row" style={{width: '50%', textAlign: 'end'}}>
                                <span className="wallet-table__header__row--text">{item.stream}</span>
                            </div>
                        </div>
                    ))
                }
                {
                    list.pagination.totalPage > 1 && <PaginationPostback pagination={list.pagination} />
                }
            </div>
        </div>
    )
}

export default Postback;