import React from 'react';
import { ReactComponent as Sort } from '../../../components/assets/sort.svg';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import PaginationFinance from '../paginationFinance';
import { renderer } from '../../../../settings/renderer';
import { setGetWithdrawals } from '../../../../store/withdrawals/thunk/setGetWithdrawals';
import { translate } from '../../../../settings/dictionary';

const Table = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
       dispatch(setGetWithdrawals({
            page: 1,
            rowsPerPage: 10,
        }))
    }, [])

    const columns = [
        { label: translate('common.66'), value: "date", align: 'flex-start', },
        { label: translate('common.68'), value: "sum", align: 'center' },
        { label: translate('common.32'), value: "status", align: 'flex-end' }
    ];

    const list = useSelector((state) => state.withdrawals.list);

    const handleGetStatusText = (status) => {
        const value = status?.toString()?.toLowerCase();

        switch(value) {
            case "await": return translate('common.72');
            case "success": return translate('common.73');
            case "canceled": return translate('common.530');
        }
    }

    const handleGetStatusColor = (status) => {
        const value = status?.toString()?.toLowerCase();

        switch(value) {
            case "await": return "#F9C800";
            case "success": return "#48BB78";
            case "canceled": return "#FF403D";
        }
    }

    return (
        <div className="finance-table">
            <div>
                <h3 className="finance-table--title">{translate('common.12')}</h3>
            </div>
            <div className="finance-table__header">
                {
                    columns.map((item) => (
                        <div className="finance-table__header__column" style={{width: '33%', justifyContent: item.align}}>
                            {
                                Boolean(item.align === 'flex-end') && (
                                    <Sort className="finance-table__header__column--sort-icon" />
                                )
                            }
                            <span className="finance-table__header__column--text">{item.label}</span>
                            {
                                Boolean(item.align === 'flex-start' || item.align === 'center') && (
                                    <Sort className="finance-table__header__column--sort-icon" />
                                )
                            }
                        </div>
                    ))
                }
            </div>

            {
                list?.data?.map((item, key) => (
                    <div className="finance-table__header" style={{borderTop: key && '1px solid #F2F2F2', display: 'flex', alignItems: 'center'}}>
                        <div className="finance-table__header__row" style={{width: '33%'}}>
                            <span className="finance-table__header__row--text">{format(new Date(Number(item.created_at)), 'dd.MM.yyyy')}</span>
                        </div>
                        <div className="finance-table__header__row" style={{width: '33%', justifyContent: 'center'}}>
                            <span className="finance-table__header__row--text">{renderer.formatAmount(item.sum, 2)} {item.currency}</span>
                        </div>
                       
                        <div className="finance-table__header__row" style={{width: '33%', justifyContent: 'flex-end'}}>
                            <div className="finance-table__header__row__button" style={{background: handleGetStatusColor(item.status)}}>
                                <span className="finance-table__header__row__button--text">{handleGetStatusText(item.status)}</span>
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                list.pagination.totalPage > 1 && <PaginationFinance pagination={list.pagination} />
            }
        </div>
    )
}

export default Table;