import React from 'react';

const Language = ({
    Icon,
    title,
    id,
    setValue,
    country
}) => {
    const handleSetCountry = () => {
        setValue('country', id);
    }
    return (
        <div style={{
            padding: '10px 20px',
            background: '#ffffff',
            borderRadius: 5,
            boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25)',
            display: 'flex', 
            alignItems: 'center', 
            columnGap: 6,
            border: country === id && '1px solid #7B549E',
            cursor: 'pointer'
        }}
            onClick={handleSetCountry}
        >
            <Icon />
            <span style={{
                color: '#0D183F',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '16px'
            }}>{title}</span>
        </div>
    )
}

export default Language;