import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ReactComponent as Cross } from '../../../../icons/cross.svg';
import { useDispatch } from 'react-redux';
import { setPostWallet } from '../../../../../store/wallet/thunk/setPostWallet';
import { setGetWallet } from '../../../../../store/wallet/thunk/setGetWallet';
import { useSnackbar } from 'notistack';
import { setGetWalletAction } from '../../../../../store/wallet/action';
import { translate } from '../../../../../settings/dictionary';

const WalletCreate = ({
    handleUpdateShowWalletCreate,
    activeTab
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, 
        reset, 
        register,
        control
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            payment: "",
            wallet: "",
            status: ""
        }
    });

    const payment = useWatch({
        control,
        name: 'payment',
    })

    const wallet = useWatch({
        control,
        name: 'wallet'
    })

    const onSubmit = (store) => {
        const params = {
            payment: store?.payment,
            wallet: store.wallet,
            enqueueSnackbar: enqueueSnackbar
        };
        dispatch(setPostWallet(params, () => {
            reset({});
            handleUpdateShowWalletCreate();
            dispatch(setGetWalletAction({
                data: [],
                pagination: {
                    total: 0,
                    currentPage: 0,
                    totalPage: 0
                },
                loading: true
            }));
            dispatch(setGetWallet({
                page: 1,
                rowsPerPage: 5,
                is_active: activeTab
            }));
            enqueueSnackbar(translate('common.93'), {variant: 'success'});
        }));
    }

    const isValid = !payment || !wallet

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="wallet__create">
            <div className="wallet__create__wrap">
                <div className="wallet__create__wrap__cross">
                    <div 
                        onClick={() => handleUpdateShowWalletCreate()}
                        className="wallet__create__wrap__cross__box"
                    >
                        <Cross 
                            className="wallet__create__wrap__cross__box--icon"
                        />
                    </div>
                </div>
                <div className="wallet__create__wrap__title">
                    <h3 className="wallet__create__wrap__title--text">{translate('common.265')}</h3>
                </div>
                <div>
                    <select className="wallet__create__wrap__select" {...register('payment')}>
                        <option value="" className="wallet__create__wrap__select--none">{translate('common.62')}</option>
                        <option value="mastercard">MasterCard</option>
                        <option value="visa">VISA</option>
                        <option value="world">{translate('common.188')}</option>
                        <option value="usdt_trc_20">USDT(TRC20)</option>
                    </select>
                </div>
                <div>
                    <input 
                        {...register('wallet')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.67')}
                    />
                </div>
                <div className="wallet__create__wrap__container">
                    <button 
                        type="submit"
                        className="wallet__create__wrap__container__button"
                        style={{ background: isValid ? '#666666' : '#7B549E', cursor: isValid ? 'default' : 'pointer' }}
                        disabled={isValid}
                    >
                        <span className="wallet__create__wrap__container__button--text">
                            {translate('common.60')}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default WalletCreate;