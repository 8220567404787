import React from 'react';
import SecurityTabs from '../securityTabs';
import Logs from '../logs';
import ChangePassword from '../changePassword';
import Postback from '../postback';

const Security = () => {
    const [activeTab, setActiveTab] = React.useState('logs');
    
    const handleUpdateActiveTab = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div style={{
            boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25)',
            padding: 20,
            borderRadius: 5,
            background: '#ffffff',
            position: 'relative'
        }}>
            <SecurityTabs handleUpdateActiveTab={handleUpdateActiveTab} activeTab={activeTab}/>
            {
                activeTab === 'logs' && <Logs />
            }
            {
                activeTab === 'security' && <ChangePassword />
            }
            {
                activeTab === 'postback' && <Postback />
            }
        </div>
    )
}

export default Security;