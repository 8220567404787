import {types} from './types';

const initialState = {
    authorize: null,
    admin: {}
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_AUTHORIZE:
            return { ...state, authorize: action.payload};
        case types.SET_ADMIN: 
            return { ...state, admin: action.payload}
        default:
            return state
    }
}