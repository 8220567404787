
import { isFunction } from "lodash";
import { postPostbackApplication } from "../api/postPostbackApplication";

export const setPostPostbackApplication = (params, handleShowMessage) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postPostbackApplication(params);

            if (isFunction(handleShowMessage)) {
                handleShowMessage(data);
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else if (isFunction(handleShowMessage)) {
                handleShowMessage(error?.response?.data);
            }
        }
    }
}