import React from 'react';
import { ReactComponent as ChipTopLeft } from './chipTopLeft.svg';
import { ReactComponent as ChipTopRight } from './chipTopRight.svg';
import { ReactComponent as ChipBottomRight } from './chipBottomRight.svg';
import { ReactComponent as ChipBottomLeft } from './chipBottomLeft.svg';
import { ReactComponent as ChipPaddingLeft } from './chipPaddingLeft.svg';
import { ReactComponent as ChipPaddingRight } from './chipPaddingRight.svg';
import { ReactComponent as ChipPaddingBottom } from './chipPaddingBottom.svg';
import { ReactComponent as Cross } from './cross.svg';
import { useForm, useWatch } from "react-hook-form";
import { setGetSignin } from '../../../../store/sign/thunk/setSignin';
import { useDispatch } from 'react-redux';
import { translate } from '../../../../settings/dictionary';

const SignIn = ({
    handleIsShowSignin
}) => {
    const dispatch = useDispatch();
    const { handleSubmit, register, control, setError, formState: { errors }, clearErrors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            login: "",
            password: ""
        }
    });

    const login = useWatch({
        control,
        name: 'login'
    })

    const password = useWatch({
        control,
        name: 'password'
    })

    React.useEffect(() => {
        clearErrors('wrong_password');
    }, [login, password])

    const onSubmit = (store) => {
        store.setError = setError;
        if (store.login && store.password) {
            dispatch(setGetSignin(store))
        } else {
            setError("wrong_password", { types: { require: translate('common.218') } });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'fixed', zIndex: 999999, top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.2)' }}>
            <div style={{
                position: 'absolute',
                top: 'calc(50% - 300px)',
                left: 'calc(50% - 496.5px)',
                background: '#211F1C',
                minWidth: 993,
                width: 993,
                maxWidth: 993,
                minHeight: 572,
                maxHeight: 572,
                height: 572,
                zIndex: 11,
                border: '5px solid #FFD951',
                borderRadius: 20,
            }}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: 40,
                    overflow: 'hidden'
                }}>
                    <div>
                        <span style={{
                            color: '#FFF',
                            fontSize: 40,
                            fontWeight: 400
                        }}>{translate('common.510')}</span>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                        position: 'relative'
                    }}>
                        <input
                            {...register('login')}
                            type="text"
                            style={{
                                borderRadius: 5,
                                border: '1px solid #8F8F8F',
                                background: '#FFF',
                                outline: 'none',
                                color: '#000',
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '12px 20px',
                                minWidth: 330,
                                width: 330,
                                maxWidth: 330
                            }}
                            placeholder={translate('common.516')}
                        />
                        <input
                            {...register('password')}
                            type="password"
                            style={{
                                borderRadius: 5,
                                border: '1px solid #8F8F8F',
                                background: '#FFF',
                                outline: 'none',
                                color: '#000',
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '12px 20px',
                                minWidth: 330,
                                width: 330,
                                maxWidth: 330
                            }}
                            placeholder={translate('common.515')}
                        />
                        {
                            errors?.wrong_password?.types?.require && (
                                <span style={{
                                    color: '#E6252C',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    position: 'absolute',
                                    bottom: -28
                                }}>{errors?.wrong_password?.types?.require}</span>
                            )
                        }
                    </div>
                    
                    <div>
                        <button type="submit" style={{
                            background: '#7B549E',
                            borderRadius: 15,
                            padding: '10px 40px',
                            borderRadius: 15,
                            cursor: 'pointer',
                            border: 'none'
                        }}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                            }}>{translate('common.219')}</span>
                        </button>
                    </div>
                    <ChipTopLeft style={{ position: 'absolute', top: 18, left: 23 }} />
                    <ChipTopRight style={{ position: 'absolute', top: -5, right: 136 }} />
                    <ChipBottomLeft style={{ position: 'absolute', bottom: 65, left: 22 }} />
                    <ChipBottomRight style={{ position: 'absolute', bottom: 7, right: 16 }} />
                    <ChipPaddingRight style={{ position: 'absolute', top: 173, right: 62 }} />
                    <ChipPaddingLeft style={{ position: 'absolute', top: 115, left: 279 }} />
                    <ChipPaddingBottom style={{ position: 'absolute', bottom: 57, right: 309 }} />
                    <Cross style={{ position: 'absolute', top: 30, right: 30, cursor: 'pointer' }} onClick={handleIsShowSignin}/>
                </div>
            </div>
        </form>
    )
}

export default SignIn;