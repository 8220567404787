import React from 'react';
import { ReactComponent as ChipTopLeft } from './chipTopLeft.svg';
import { ReactComponent as ChipTopRight } from './chipTopRight.svg';
import { ReactComponent as ChipBottomRight } from './chipBottomRight.svg';
import { ReactComponent as ChipBottomLeft } from './chipBottomLeft.svg';
import { ReactComponent as ChipPaddingLeft } from './chipPaddingLeft.svg';
import { ReactComponent as ChipPaddingRight } from './chipPaddingRight.svg';
import { ReactComponent as ChipPaddingBottom } from './chipPaddingBottom.svg';
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from 'react-redux';
import Messanger from '../components/messanger';
import { ReactComponent as Rus } from './rus.svg';
import { ReactComponent as Cross } from './cross.svg';
import { setGetSignup } from '../../../../store/sign/thunk/setSignup';
import { translate } from '../../../../settings/dictionary';
import { useRouter } from '../../../../hooks/useRouter';

const SignUp = ({
    handleIsShowSignup,
    handleOpenIsShowSuccessRegistration
}) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const { handleSubmit, register, control, setValue, setError, formState: { errors } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            password: "",
            name: "",
            email: "",
            phone: "",
            messanger: "",
            messanger_type: "telegram",
            password: "",
            repeat_password: ""
        }
    });
    const [isDisabledRegistration, setIsDisabledRegistration] = React.useState(false);

    const email = useWatch({
        control,
        name: 'email'
    });

    const name = useWatch({
        control,
        name: 'name'
    })

    const phone = useWatch({
        control,
        name: 'phone'
    })

    const messanger = useWatch({
        control,
        name: 'messanger'
    });

    const password = useWatch({
        control,
        name: 'password'
    })

    const repeat_password = useWatch({
        control,
        name: 'repeat_password'
    })

    const messanger_type = useWatch({
        control,
        name: "messanger_type"
    })

    const isFormValid = !email
        || !phone
        || !messanger
        || !password
        || !repeat_password
        || !name
        || (password !== repeat_password);

    const onSubmit = (store) => {
        if (!isFormValid && !isDisabledRegistration) {
            setIsDisabledRegistration(true)
            store.setError = setError;
            store.setIsDisabledRegistration = setIsDisabledRegistration;
            store.handleOpenIsShowSuccessRegistration = handleOpenIsShowSuccessRegistration;
            dispatch(setGetSignup(store))
        }
    }

    const handleCheckValidation = () => {
        if (!email) {
            setError("email", { types: { require: translate('common.220') } });
        }

        if (!phone) {
            setError("phone", { types: { require: translate('common.221') } });
        }

        if (!phone) {
            setError("name", { types: { require: translate('common.222') } });
        }

        if (!password) {
            setError("password", { types: { require: translate('common.223') } });
        }

        if (!repeat_password) {
            setError("repeat_password", { types: { require: translate('common.224') } });
        }
        if (!messanger) {
            setError("messanger", { types: { require: `${translate('common.225')} ${messanger_type?.[0].toUpperCase() + messanger_type.slice(1)}` } });
        }

        if (repeat_password !== password) {
            setError("password", { types: { require: translate('common.90') } });
            setError("repeat_password", { types: { require: translate('common.90') } });
        }
    }

    const error_name = errors?.name?.types?.require;
    const error_email = errors?.email?.types?.require;
    const error_phone = errors?.phone?.types?.require;
    const error_messanger = errors?.messanger?.types?.require;
    const error_password = errors?.password?.types?.require;
    const error_repeat_password = errors?.repeat_password?.types?.require;
    const error_registration = errors?.error_registration?.types?.require;

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'fixed', zIndex: 999999, top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.2)' }}>
            <div style={{
                position: 'absolute',
                top: 'calc(50% - 300px)',
                left: 'calc(50% - 496.5px)',
                background: '#211F1C',
                minWidth: 993,
                width: 993,
                maxWidth: 993,
                minHeight: 572,
                maxHeight: 572,
                height: 572,
                zIndex: 11,
                border: '5px solid #FFD951',
                borderRadius: 20,
            }}>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: 40,
                    overflow: 'hidden'
                }}>
                    <div>
                        <span style={{
                            color: '#FFF',
                            fontSize: 40,
                            fontWeight: 400
                        }}>{translate('common.214')}</span>
                    </div>
                    <div style={{ display: 'flex', columnGap: 40, position: 'relative' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            position: 'relative'
                        }}>
                            <label htmlFor='name' style={{ position: 'relative' }}>
                                <input
                                    {...register('name')}
                                    type="text"
                                    style={{
                                        borderRadius: 5,
                                        border: error_name ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        minWidth: 330,
                                        width: 330,
                                        maxWidth: 330
                                    }}
                                    placeholder={translate('common.517')}
                                />
                                {
                                    error_name && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_name}</span>
                                    )
                                }
                            </label>
                            <label htmlFor="" style={{ position: 'relative' }}>
                                <input
                                    {...register('email')}
                                    type="text"
                                    style={{
                                        borderRadius: 5,
                                        border: error_email ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        minWidth: 330,
                                        width: 330,
                                        maxWidth: 330
                                    }}
                                    placeholder={translate('common.518')}
                                />
                                {
                                    error_email && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_email}</span>
                                    )
                                }
                            </label>

                            <label htmlFor="phone" style={{ position: 'relative' }}>
                                <Rus style={{ position: 'absolute', top: 12, left: 20, borderRadius: '50%' }} />
                                <input
                                    {...register('phone')}
                                    type="text"
                                    style={{
                                        borderRadius: 5,
                                        border: error_phone ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px 12px 55px',
                                        minWidth: 295,
                                        width: 295,
                                        maxWidth: 295
                                    }}
                                    placeholder={translate('common.519')}
                                />
                                {
                                    error_phone && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_phone}</span>
                                    )
                                }
                            </label>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20
                        }}>
                            <Messanger
                                register={register}
                                control={control}
                                setValue={setValue}
                                error_messanger={error_messanger}
                            />
                            <label htmlFor="" style={{ position: 'relative' }}>
                                <input
                                    {...register('password')}
                                    type="password"
                                    style={{
                                        borderRadius: 5,
                                        border: error_password ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        minWidth: 330,
                                        width: 330,
                                        maxWidth: 330
                                    }}
                                    placeholder={translate('common.515')}
                                />
                                {
                                    error_password && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_password}</span>
                                    )
                                }
                            </label>

                            <label htmlFor="" style={{ position: 'relative' }}>
                                <input
                                    {...register('repeat_password')}
                                    type="password"
                                    style={{
                                        borderRadius: 5,
                                        border: error_repeat_password ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        minWidth: 330,
                                        width: 330,
                                        maxWidth: 330
                                    }}
                                    placeholder={translate('common.226')}
                                />
                                {
                                    error_repeat_password && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_repeat_password}</span>
                                    )
                                }
                            </label>
                        </div>
                        {
                            error_registration && (
                                    <span style={{
                                        color: '#E6252C',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        position: 'absolute',
                                        bottom: -28,
                                        left: 100
                                    }}>{error_registration}</span>
                            )
                        }
                    </div>
                    <div style={{width: 600, wordBreak: 'break-word', textAlign: 'center'}}>
                        <span style={{
                                color: 'rgb(255, 255, 255)',
                                fontSize: 20,
                                fontWeight: 400
                        }}>
                            {translate('common.592')} <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location.replace('/agreements')}>{translate('common.593')}</span> {translate('common.594')} <span  style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location.replace('/privacy')}>{translate('common.595')}</span>
                        </span>
                    </div>
                    <div>
                        <div onClick={() => {
                            if (isFormValid) {
                                handleCheckValidation()
                            }
                        }}>
                            <button
                                type={isFormValid ? "button" : "submit"}
                                style={{
                                    background: '#7B549E',
                                    borderRadius: 15,
                                    padding: '10px 40px',
                                    borderRadius: 15,
                                    cursor: 'pointer',
                                    border: 'none'
                                }}
                            >
                                <span style={{
                                    color: '#FFF',
                                    fontSize: 20,
                                    fontWeight: 400,
                                }}>{translate('common.227')}</span>
                            </button>
                        </div>
                    </div>
                    <ChipTopLeft style={{ position: 'absolute', top: 18, left: 23 }} />
                    <ChipTopRight style={{ position: 'absolute', top: -5, right: 136 }} />
                    <ChipBottomLeft style={{ position: 'absolute', bottom: 65, left: 22 }} />
                    <ChipBottomRight style={{ position: 'absolute', bottom: 7, right: 16 }} />
                    <ChipPaddingRight style={{ position: 'absolute', top: 173, right: 62 }} />
                    <ChipPaddingLeft style={{ position: 'absolute', top: 115, left: 279 }} />
                    <ChipPaddingBottom style={{ position: 'absolute', bottom: 57, right: 309 }} />
                    <Cross style={{ position: 'absolute', top: 30, right: 30, cursor: 'pointer' }} onClick={handleIsShowSignup} />
                </div>
            </div>
        </form>
    )
}

export default SignUp;