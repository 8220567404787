import React from 'react';
import Datepicker from '../../../../datepicker';
import Select from '../../../../select';
import { translate } from '../../../../../../settings/dictionary';

const Filter = ({
    control, 
    register
}) => {
    
    return (
        <div className="filter-statistics-days" >
            
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10, width: '100%' }}>
                    <div style={{ display: 'flex', columnGap: 10 }}>
                        <div style={{
                            width: 123,
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 6
                        }}>
                            <span style={{
                                color: '#0D183F',
                                fontFamily: 'Work Sans, sans-serif',
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: '16px'
                            }}>{translate('common.25')}</span>
                            <Select
                                control={control}
                                options={[{ label: 'RUB', value: 'RUB' }, { label: 'UZS', value: 'UZS' }, { label: 'KZT', value: 'KZT' }]}
                                placeholder={translate('common.37')}
                                name="currency"
                                register={register}
                            />
                        </div>
                        <div style={{
                            width: 123,
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 6
                        }}>
                            <span style={{
                                color: '#0D183F',
                                fontFamily: 'Work Sans, sans-serif',
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: '16px'
                            }}>Stream ID</span>
                            <input 
                                {...register('stream_id')}
                                type="text" 
                                className="wallet__create__wrap__field"
                                placeholder="Stream ID"
                                style={{width: 300}}
                            />
                        </div>
                    </div>
                    <Datepicker control={control} register={register} />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    columnGap: 20,
                    marginTop: 20
                }}>
                    <button type="submit" className="filter-statistics-days__wrap__button" style={{border: 'none', outline: 'none'}}>
                        <span className="filter-statistics-days__wrap__button--text">{translate('common.40')}</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Filter;