import React from 'react';
import {translate} from '../../../../settings/dictionary';

const Api = () => {
    return (
        <div>
            <h3>{translate('common.274')}</h3>
            {/* <h3>{translate('common.275')}</h3>
            <ul>
                <li>{translate('common.275.1')}</li>
                <li>{translate('common.275.2')}</li>
            </ul>
            <h4>{translate('common.276')}</h4>
            <div>
                <span>{translate('common.277')}</span>
                <span>{translate('common.278')}</span>
            </div>
            <h4>{translate('common.279')}</h4>
            <ul>
                <li>{translate('common.279.1')}</li>
                <li>{translate('common.279.2')}</li>
                <li>{translate('common.279.3')}</li>
                <li>{translate('common.279.4')}</li>
                <li>{translate('common.279.5')}</li>
            </ul> */}
        </div>
    )
}

export default Api;