import { getPromo } from '../api/getPromo';
import { setGetPromoAction } from '../action';

export const setGetPromo = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPromo(params);
            dispatch(setGetPromoAction(data))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}