import React from 'react';
import Item from './item';
import { translate } from '../../../../settings/dictionary';

const Faq = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 32, }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-1.1px',
                    lineHeight: '60px'
                }}>FAQ</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', rowGap: 20}}>
            <Item
                    label={translate('common.336')}
                    description={translate('common.337')}
                />
                <Item
                    label={translate('common.338')}
                    description={translate('common.339')}  
                />
                <Item
                    label={translate('common.340')}
                    description={translate('common.341')}
                />
                <Item
                    label={translate('common.342')}
                    description={translate('common.343')}
                />
                <Item
                    label={translate('common.344')}
                    description={translate('common.345')}
                />
                <Item
                    label={translate('common.346')}
                    description={translate('common.347')}
                />
                <Item
                    label={translate('common.348')}
                    description={translate('common.349')}
                />
                <Item
                    label={translate('common.350')}
                    description={translate('common.351')}
                />
                <Item
                    label={translate('common.352')}
                    description={translate('common.353')}
                />
                <Item
                    label={translate('common.354')}
                    description={translate('common.355')}
                />
                <Item
                    label={translate('common.356')}
                    description={translate('common.357')}
                />
            </div>
        </div>
    )
}

export default Faq;