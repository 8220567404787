import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
);


const Graphic = () => {
    const lineChartData = {
        labels: ["October", "November", "December"],
        datasets: [
            {
                data: [8137119, 9431691, 10266674],
                label: "Infected",
                borderColor: "#3333ff",
                fill: true,
                lineTension: 0.5
            },
            {
                data: [1216410, 1371390, 1477380],
                label: "Deaths",
                borderColor: "#ff3333",
                backgroundColor: "rgba(255, 0, 0, 0.5)",
                fill: true,
                lineTension: 0.5
            }
        ]
    };

    return (
        <div style={{ width: '75%', background: '#FFFFFF', borderRadius: 3 }}>
            <div style={{ padding: '20px 40px' }}>
                <Line
                    type="line"
                    width={160}
                    height={60}
                    options={{
                        title: {
                            display: true,
                            text: "COVID-19 Cases of Last 6 Months",
                            fontSize: 20
                        },
                        legend: {
                            display: true, //Is the legend shown?
                            position: "top" //Position of the legend.
                        }
                    }}
                    data={lineChartData}
                />
            </div>
        </div>
    )
}

export default Graphic;