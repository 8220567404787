
import { isFunction } from "lodash";
import { setGetPostbackAction } from "../action";
import { getPostback } from "../api/getPostback.js";

export const setGetPostback = (params, handleShowMessage) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPostback(params);
            dispatch(setGetPostbackAction({
                data: data.data,
                pagination: {
                    total: data?.pagination?.total,
                    currentPage:  data?.pagination?.currentPage,
                    totalPage: data?.pagination?.totalPage
                },
            }));

        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else if (isFunction(handleShowMessage)) {
                handleShowMessage(error?.response?.data);
            }
        }
    }
}