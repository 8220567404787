import React from 'react';
import {ReactComponent as Search} from '../../../../icons/search.svg';
import {ReactComponent as Clip} from '../../../../icons/clip.svg';
import { translate } from '../../../../../settings/dictionary';

const Filter = () => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 20

        }}>
            <div style={{position: 'relative', width: 321}}>
                <input 
                    type="text" 
                    style={{
                        padding: '12px 50px 12px 20px',
                        border: 'none',
                        borderRadius: 5,
                        background: '#ffffff',
                        outline: 'none',
                        width: 251
                    }}
                    placeholder={translate('common.298')}
                />
                <Search style={{position: 'absolute', top: 10, right: 20}}/>
            </div>
            <div style={{
                display: 'flex', alignItems: 'center', columnGap: 6
            }}>
                <Clip />
                <a 
                    href="http://endless.horse/" 
                    target="_blank"
                    rel="noopener noreferrer" 
                    style={{
                        color: '#0D183F',
                        fontFamily: 'Work Sans, sans-serif',
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: '16px',
                        textDecoration: 'none'
                    }}
                >http://endless.horse/</a>
            </div>
        </div>
    )
}

export default Filter;