import React from 'react';

const Select = ({
    options,
    placeholder,
    register,
    name
}) => {
    return (
        <div>
            <select className="wallet__create__wrap__select" {...register(name)}>
                <option value="" className="wallet__create__wrap__select--none">{placeholder}</option>
                {
                    options?.map((item) => <option value={item.value}>{item.label}</option>)
                }
            </select>
        </div>
    )
}

export default Select;