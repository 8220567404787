import React from 'react';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const HeaderCreatePromo = ({
    watch,
    setValue,
    register
}) => {

    const step = watch('step');
    const id = watch('id');
    const promo_type = watch('promo_type');
    
    return (
        <div style={{
            display: 'flex',
            alignItems: 'flex-start',
            columnGap: 20,
            minHeight: 149
        }}>
            <Step1 step={step} setValue={setValue} id={id} />
            <Step2 step={step} setValue={setValue} promo_type={promo_type} />
            <Step3 step={step} setValue={setValue} register={register} />
        </div>
    )
}

export default HeaderCreatePromo;