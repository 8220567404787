import React from 'react';
import {ReactComponent as ArrowDown} from '../../../assets/arrowdown.svg';
import {ReactComponent as ArrowUp} from '../../../assets/arrowup.svg';
import { useRouter } from '../../../../../hooks/useRouter';

const NavigationItem = ({
    label,
    Icon,
    children,
    path,
    handleOpenClose
}) => {
    const router = useRouter();

    const [isShowChildren, setIsShowChildren] = React.useState(false);

    const handleChangeShow = (length) => {
        if (length) {
            setIsShowChildren(!isShowChildren);
        }
    }
    const handleChangePath = (path) => {
        if (path) {
            router.history.push(path);
            handleOpenClose()
        }
    }
    const isActive = router.location.pathname === path;

    return (
        <div>
            <div 
                className={`navigation__wrap__item ${isActive && 'navigation__wrap__item--active'}`}
                onClick={() => {
                    handleChangeShow(children?.length);
                    handleChangePath(path);
                }}
            >
                <div className="navigation__wrap__item__icon-title">
                    <Icon className={isActive && 'navigation__wrap__item__icon-title__icon--active'}/>
                    <span className={`navigation__wrap__item__icon-title--text ${isActive && 'navigation__wrap__item__icon-title--text--active'}`}>{label}</span>
                </div>
                {
                    children?.length && (
                        <div>
                            {isShowChildren ? <ArrowUp /> : <ArrowDown />}
                        </div>
                    )
                }
            </div>
            {
                isShowChildren && children?.map(({label, path}, key) => {
                    const isActive = router.location.pathname === path;

                    return (
                        <div 
                            key={key}
                            className={`navigation__wrap__item navigation__wrap__item__children ${isActive && 'navigation__wrap__item__children--active'}`}
                            onClick={() => handleChangePath(path)}>
                            <span className={`navigation__wrap__item__children--text ${isActive && 'navigation__wrap__item__children--text--active'}`}>{label}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default NavigationItem;