import React from 'react';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Hammer } from './hammer.svg';
import { ReactComponent as Sword } from './sword.svg';
import { ReactComponent as Coin } from './coin.svg';
import { translate } from '../../../../settings/dictionary';

const Comission = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 40 }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <span style={{
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>{translate('common.207')}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 40 }}>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10 }}>
                    <Star style={{minWidth: 94, maxWidth: 94, width: 94, minHeight: 94, maxHeight: 94, height: 94}} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 21,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}> Sub-affilate 5%</span>
                        <ul style={{paddingLeft: 12}}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.208')}</li>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.306')}</li>
                        </ul>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10 }}>
                    <Hammer style={{minWidth: 94, maxWidth: 94, width: 94, minHeight: 94, maxHeight: 94, height: 94}} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 21,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>Rev Share</span>
                        <ul style={{paddingLeft: 12}}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.208')}</li>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.306')}</li>
                        </ul>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10 }}>
                    <Sword style={{minWidth: 94, maxWidth: 94, width: 94, minHeight: 94, maxHeight: 94, height: 94}} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 21,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.332')}</span>
                        <ul style={{paddingLeft: 12}}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.210')}</li>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.307')}</li>
                        </ul>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10 }}>
                    <Coin style={{minWidth: 94, maxWidth: 94, width: 94, minHeight: 94, maxHeight: 94, height: 94}} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 21,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>CPA</span>
                        <ul style={{paddingLeft: 12}}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 12,
                                fontWeight: 400
                            }}>{translate('common.308')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comission;