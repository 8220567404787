import React from 'react';
import Information from '../../Information';
import { useDispatch, useSelector } from 'react-redux';
import { setGetTemplatePromo } from '../../../../../../../store/template_promo/thunk/setGetTemplatePromo';
import ItemTemplatePromo from './block/Item';

const ContentStep3 = ({
    control,
    register,
    watch,
    setValue
}) => {
    const dispatch = useDispatch();
    const promo_type = watch('promo_type');

    React.useEffect(() => {
        dispatch(setGetTemplatePromo({
            page: 1,
            rowsPerPage: 25,
            type: promo_type,
        }))
    }, [])
    const template_promo = useSelector(state => state.templatePromo.list);

    return (
        <div>
            <Information control={control} register={register} setValue={setValue} watch={watch} />
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 20,
                marginTop: 20
            }}>
                {
                    template_promo.data?.map((item) => (
                        <ItemTemplatePromo 
                            watch={watch}
                            setValue={setValue}
                            {...item}
                        />
                    ))
                }
                
            </div>
        </div>
    )
}

export default ContentStep3;