import React from 'react';
import ProfileInformation from './components/profileInformation';
import Wallet from './components/wallet';
import Security from './components/security';
import { translate } from '../../../settings/dictionary';

const Profile = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 20, paddingBottom: 50}}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.130')}
                </h3>
            </div>
            <ProfileInformation />
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20, width: 'calc(100% - 40px)' }}>
                <Wallet />
                <Security />
            </div>
        </div>
    )
}

export default Profile;