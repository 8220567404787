import { setGetWallet } from "./setGetWallet";
import { putWallet } from "../api/putWallet";

export const setPutWallet = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putWallet(params);

            dispatch(setGetWallet({
                page: params.page,
                rowsPerPage: 5
            }));
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}