import React from 'react';
import { ReactComponent as Sort } from '../../../../icons/sort.svg';
import { ReactComponent as Cross } from '../../../../icons/cross.svg';
import { format } from 'date-fns';
import Pagination from '../pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setGetWallet } from '../../../../../store/wallet/thunk/setGetWallet';
import clsx from 'clsx';
import { setPutWallet } from '../../../../../store/wallet/thunk/setPutWallet';
import FakeTable from '../FakeTable';
import { translate } from '../../../../../settings/dictionary';

const Table = ({
    isEdit,
    handleUpdateShowWalletCreate,
    activeTab
}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetWallet({
            page: 1,
            rowsPerPage: 5,
            is_active: activeTab === 'active' ? true : activeTab === 'noactive' ? false : undefined
        }));
    }, []);

    const list = useSelector(state => state.wallet.list);

    const columns = [
        { label: translate('common.268'), value: "created_at" },
        { label: translate('common.36'), value: "status" },
        { label: translate('common.67'), value: "wallet" },
        { label: translate('common.62'), value: "payment_system" }
    ];

    const handleGetNameByPaymentSystem = (value) => {
        const payment_system = value?.toString()?.toLowerCase();

        switch (payment_system) {
            case "visa": return "Visa";
            case "mastercard": return "MasterCard";
            case "world": return translate('common.188');
            case "ustd_trc_20": return "USDT(TRC20)";
        }
    }

    const handleGetStatus = (status) => {
        const value = status?.toString().toLowerCase();

        switch (value) {
            case "await": return translate('common.503');
            case "verified": return translate('common.183');
            case "canceled": return translate('common.74');
            case "archive": return translate('common.269')
        }
    }

    const handleGetStatusColor = (status) => {
        const value = status?.toString().toLowerCase();

        switch (value) {
            case "await": return "wallet-table__header__row__button--yellow";
            case "verified": return "";
            case "canceled": return "wallet-table__header__row__button--red";
            case "archive": return "wallet-table__header__row__button--canceled"
        }
    }

    return (
        <div>
            <div className="wallet-table__header">
                {
                    columns.map((item) => (
                        <div className="wallet-table__header__column">
                            <span className="wallet-table__header__column--text">{item.label}</span>
                            <Sort className="wallet-table__header__column--sort-icon" />
                        </div>
                    ))
                }
            </div>

            {
                list?.data?.map((item, key) => (
                    <div className={`wallet-table__header ${key && "wallet-table__header--border"}`}>
                        <div className="wallet-table__header__row">
                            <span className="wallet-table__header__row--text">{format(new Date(Number(item.created_at)), 'dd.MM.yyyy')}</span>
                        </div>
                        <div className="wallet-table__header__row">
                            <div className={clsx("wallet-table__header__row__button", handleGetStatusColor(item?.status))}>
                                <span className="wallet-table__header__row__button--text">{handleGetStatus(item.status)}</span>
                            </div>
                        </div>
                        <div className="wallet-table__header__row">
                            <span className="wallet-table__header__row--text">{item.wallet}</span>
                        </div>
                        <div className="wallet-table__header__row">
                            <div className="wallet-table__header__row__payment-system">
                                <span className="wallet-table__header__row__payment-system--text">
                                    {handleGetNameByPaymentSystem(item.payment_system)}
                                </span>
                            </div>
                        </div>
                        {
                            isEdit && item.status !== 'ARCHIVE' && (
                                    <Cross className="wallet-table__header--cross" onClick={() => {
                                        dispatch(setPutWallet({
                                            wallet_id: item.id,
                                            page: list.pagination.currentPage
                                        }))
                                    }} />
                            )
                        }
                    </div>
                ))
            }
            {
                list?.loading && (
                    <FakeTable 
                        rows={5}
                        columns={4}
                    />
                )
            } 
            {
                isEdit && (
                    <div className="wallet-table__container">
                        <div
                            onClick={() => handleUpdateShowWalletCreate()}
                            className="wallet-table__container__button"
                        >
                            <span className="wallet-table__container__button--text">{translate('common.265')}</span>
                        </div>
                    </div>
                )
            }
            {
                list.pagination.totalPage > 1 && <Pagination pagination={list.pagination} activeTab={activeTab} />
            }
        </div>
    )
}

export default Table;