import { types } from './types';

const initialState = {
    global: {},
    application: {},
    list: {
        data: [],
        pagination: {
            total: 0,
            currentPage:  0,
            totalPage: 0
        },
        loading: true
    }
}

export function postbackReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_GLOBAL_POSTBACK:
            return { ...state, global: action.payload };
        case types.SET_GET_APPLICATION_POSTBACK:
            return { ...state, application: action.payload };
        case types.SET_GET_POSTBACK:
            return { ...state, list: action.payload };
        default:
            return state
    }
}