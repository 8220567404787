import React from 'react';
import { translate } from '../../../../settings/dictionary';
import Contacts from '../contacts';
import { useRouter } from '../../../../hooks/useRouter';

const Privacy = () => {
    const router = useRouter();

    return (
        <div style={{ color: '#fff', padding: '0 15px' }}>
            <h1>{translate('common.559')}</h1>
            <p>
                {translate('common.560')}
            </p>
            <p>
                {translate('common.561')}
            </p>
            <h3>{translate('common.562')}</h3>
            <p>
                {translate('common.563')}
            </p>
            <ul>
                <li>{translate('common.564')}</li>
                <li>{translate('common.565')}</li>
                <li>{translate('common.566')}</li>
                <li>{translate('common.567')}</li>
            </ul>
            <h3>
                {translate('common.568')}
            </h3>
            <p>
                {translate('common.569')}
            </p>
            <p>
                {translate('common.570')}
            </p>
            <p>
                {translate('common.571')}
            </p>
            <h3>
                {translate('common.572')}
            </h3>
            <p>
                {translate('common.573')}
            </p>
            <ul>
                <li>{translate('common.574')}</li>
                <li>{translate('common.575')}</li>
                <li>{translate('common.576')}</li>
                <li>{translate('common.577')}</li>
                <li>{translate('common.578')}</li>
            </ul>
            <h3>
                {translate('common.579')}
            </h3>
            <p>
                {translate('common.580')}
            </p>
            <p>
                {translate('common.581')}
            </p>
            <h3>
                {translate('common.582')}
            </h3>
            <p>
                {translate('common.583')}
            </p>
            <h3>{translate('common.584')}</h3>
            <p>
                {translate('common.585')}
            </p>
            <h3>
                {translate('common.586')}
            </h3>
            <p>
                {translate('common.587')}
            </p>
            <h3>{translate('common.588')}</h3>
            <p>
                {translate('common.589')}
            </p>
            <Contacts />
            <div style={{ display: 'flex', columnGap: 10, marginTop: 20 }}>
                <span onClick={() => router.history.push('/privacy')} style={{
                    color: '#FFF',
                    fontSize: 20,
                    fontWeight: 400,
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}>{translate('common.590')}</span>
                <span onClick={() => router.history.push('/agreements')} style={{
                    color: '#FFF',
                    fontSize: 20,
                    fontWeight: 400,
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}>{translate('common.591')}</span>
            </div>
        </div>
    )
}

export default Privacy;