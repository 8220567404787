import React from 'react';
import {ReactComponent as Telegram} from './telegram.svg';
import {ReactComponent as Instagram} from './instagram.svg';
import { translate } from '../../../../settings/dictionary';

const Contacts = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 80, }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-1.1px',
                    lineHeight: '60px'
                }}>{translate('common.511')}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: 10}}>
                    <span style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 700
                    }}>Email</span>
                    <a 
                        href="mailto:info@winbet.partners"
                        style={{
                            color: '#FFF',
                            fontSize: 20,
                            fontWeight: 400,
                            textDecoration: 'none'
                        }}
                    >info@winbet.partners</a>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: 10}}>
                    <span style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 700
                    }}>{translate('common.550')}</span>
                    <a 
                        href="https://t.me/win_support" 
                        target="_blank"
                        style={{
                            color: '#FFF',
                            fontSize: 20,
                            fontWeight: 400,
                            textDecoration: 'none'
                        }}
                    >https://t.me/win_support</a>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: 10}}>
                    <span style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 700
                    }}>{translate('common.215')}</span>
                    <div style={{display: 'flex', justifyContent: 'center', columnGap: 12}}>
                        <Telegram style={{cursor: 'pointer'}} onClick={() => window.open("https://t.me/winbingo", "_target")} />
                        <Instagram style={{cursor: 'pointer'}} onClick={() => window.open("https://www.instagram.com/win777.fun", "_target")} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacts;