import React from 'react';
import Mobile from './mobile';
import Desktop from './desktop2';
import axios from 'axios';
import { useRouter } from './hooks/useRouter';

const App = () => {
    
    axios.defaults.headers['Authorization'] = localStorage.getItem('token');
    const router = useRouter();
    const utm_source = router.query?.utm_source;
    const utm_medium = router.query?.utm_medium;
    const utm_campaign = router.query?.utm_campaign;
    const utm_content = router.query?.utm_content;
    const utm_term = router.query?.utm_term;
    const sub_1 = router.query?.sub_1;
    const sub_2 = router.query?.sub_2;
    const sub_3 = router.query?.sub_3;
    const sub_4 = router.query?.sub_4;
    const sub_5 = router.query?.sub_5;
    const stream = router.query?.stream;
    const token = router.query?.token;
    const lang = router.query?.lang;

    if (token) {
        localStorage.setItem('token', `Bearer ${token}`);
        window.location.replace(window.location.origin);
        window.history.pushState(null, document.title, '/');
    }

    if (utm_source) {
        localStorage.setItem('utm_source', utm_source)
    }
    if (utm_term) {
        localStorage.setItem('utm_term', utm_term)
    }
    if (utm_medium) {
        localStorage.setItem('utm_medium', utm_medium)
    }
    if (utm_campaign) {
        localStorage.setItem('utm_campaign', utm_campaign)
    }
    if (utm_content) {
        localStorage.setItem('utm_content', utm_content)
    }
    if (sub_1) {
        localStorage.setItem('sub_1', sub_1)
    }
    if (sub_2) {
        localStorage.setItem('sub_2', sub_2)
    }
    if (sub_3) {
        localStorage.setItem('sub_3', sub_3)
    }
    if (sub_4) {
        localStorage.setItem('sub_4', sub_4)
    }
    if (sub_5) {
        localStorage.setItem('sub_5', sub_5)
    }
    if (!lang && !localStorage.getItem('language')) {
        localStorage.setItem('language', 'ru');
    }
    if (lang) {
        localStorage.setItem('language', lang);
    }

    
    if (stream) {
        localStorage.setItem('stream', stream);
        window.history.pushState(null, document.title, '/');
    }


    

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) { 
        return (
            <Mobile />
        )
    } 
    else {
        return <Desktop />
    }
}

export default App