import React from 'react';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Hammer } from './hammer.svg';
import { ReactComponent as Sword } from './sword.svg';
import { ReactComponent as Coin } from './coin.svg';
import { translate } from '../../../../settings/dictionary';

const ComissionPartners = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 56 }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-0.4px',
                    lineHeight: '60px'
                }}>{translate('common.207')}</span>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 60, paddingBottom: 100 }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 17,
                    padding: '56px 57px 56px 17px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20
                }}>
                    <Star style={{ width: '100%', minWidth: 245, maxWidth: 245 }} />
                    <div style={{ paddingTop: 12 }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '2px'
                        }}>sub-affilate 5%</span>
                        <ul style={{
                            paddingLeft: 0,
                            marginLeft: 17,
                            marginTop: 20
                        }}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                                paddingLeft: 0,
                                marginLeft: 0,
                                letterSpacing: '1.6px'
                            }}><span>{translate('common.208')}</span></li>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                            }}><div style={{ display: 'flex', flexDirection: 'column' }}><span style={{ letterSpacing: '1.7px' }}>{translate('common.209')}</span><span style={{ letterSpacing: '1.7px' }}>{translate('common.545')}</span></div></li>
                        </ul>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 42,
                    padding: '56px 58px 56px 16px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20
                }}>
                    <Hammer style={{ width: '100%', minWidth: 245, maxWidth: 245 }} />
                    <div style={{ paddingTop: 12 }}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '2px'
                        }}>rev share</span>
                        <ul style={{
                            paddingLeft: 0,
                            marginLeft: 13,
                            marginTop: 20
                        }}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                                paddingLeft: 0,
                                marginLeft: 0,
                                letterSpacing: '1.6px'
                            }}><span>{translate('common.208')}</span></li>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                            }}><div style={{ display: 'flex', flexDirection: 'column' }}><span style={{ letterSpacing: '1.7px' }}>{translate('common.209')}</span><span style={{ letterSpacing: '1.7px' }}>{translate('common.545')}</span></div></li>
                        </ul>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 42,
                    padding: '56px 58px 56px 16px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20
                }}>
                    <Sword style={{ width: '100%', minWidth: 245, maxWidth: 245 }} />
                    <div style={{ paddingTop: 12 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 24,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.5px'
                            }}>{translate('common.467')}</span>
                            <span style={{
                                color: '#FFF',
                                fontSize: 24,
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                letterSpacing: '2.5px'
                            }}>{translate('common.468')}</span>
                        </div>

                        <ul style={{
                            paddingLeft: 0,
                            marginLeft: 13,
                            marginTop: 20
                        }}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                                paddingLeft: 0,
                                marginLeft: 0,
                                letterSpacing: '1.6px'
                            }}><span>{translate('common.210')}</span></li>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                            }}><div style={{ display: 'flex', flexDirection: 'column' }}><span style={{ letterSpacing: '1.7px' }}>{translate('common.211')}</span><span style={{ letterSpacing: '1.7px' }}>{translate('common.212')}</span><span style={{ letterSpacing: '1.7px' }}>{translate('common.213')}</span></div></li>
                        </ul>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 37,
                    padding: '56px 58px 56px 16px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20
                }}>
                    <Coin style={{ width: '100%', minWidth: 245, maxWidth: 245 }} />
                    <div style={{ paddingTop: 12 }}>
                        {/* <div style={{display: 'flex', flexDirection: 'column'}}> */}
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '3px'
                        }}>CPA</span>
                        {/* </div> */}

                        <ul style={{
                            paddingLeft: 0,
                            marginLeft: 18,
                            marginTop: 20
                        }}>
                            <li style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                            }}><div style={{ display: 'flex', flexDirection: 'column' }}><span style={{ letterSpacing: '1.7px' }}>{translate('common.209')}</span><span style={{ letterSpacing: '1.7px' }}>{translate('common.545')}</span></div></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComissionPartners;