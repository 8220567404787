import React from 'react';
import {useFieldArray} from 'react-hook-form';
import {ReactComponent as World} from '../../../../../icons/language/world.svg';
import {ReactComponent as RuFlag} from '../../../../../icons/language/ru.svg';
import {ReactComponent as AzFlag} from '../../../../../icons/language/az.svg';
import {ReactComponent as BrFlag} from '../../../../../icons/language/br.svg';
import {ReactComponent as CaFlag} from '../../../../../icons/language/ca.svg';
import {ReactComponent as ClFlag} from '../../../../../icons/language/cl.svg';
import {ReactComponent as PeFlag} from '../../../../../icons/language/pe.svg';
import {ReactComponent as InFlag} from '../../../../../icons/language/in.svg';
import {ReactComponent as KzFlag} from '../../../../../icons/language/kz.svg';
import {ReactComponent as TrFlag} from '../../../../../icons/language/tr.svg';
import {ReactComponent as UaFlag} from '../../../../../icons/language/ua.svg';
import {ReactComponent as UzFlag} from '../../../../../icons/language/uz.svg';
import Language from '../Language';
import { translate } from '../../../../../../settings/dictionary';

const Information = ({
    control, register, setValue, watch
}) => {
    const { fields, append } = useFieldArray({
        control,
        name: 'subid'
    });

    const country = watch("country");

    return (
        <div style={{
            marginTop: 20
        }}>
            <div style={{
                marginBottom: 20
            }}>
                <span style={{
                    color: '#0D183F',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: '22px',
                    letterSpacing: '0.32px'
                }}>{translate('common.459')}</span>
            </div>
            <div style={{display: 'flex', columnGap: 10, alignItems: 'flex-end'}}>
                {
                    fields?.map((item, key) => (
                        <label htmlFor={`subid[${key}]`} style={{display: 'flex', flexDirection: 'column', rowGap: 6}}>
                            <span style={{
                                color: '#0D183F',
                                fontFamily: 'Work Sans, sans-serif',
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: '16px'
                            }}>SubId{key + 1}:</span>
                            <input {...register(`subid[${key}].value`)} type="text" style={{
                                borderRadius: 5,
                                border: 'none',
                                background: '#FFF',
                                boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25)',
                                outline: 'none',
                                width: 123,
                                padding: '4px 10px'
                            }}/>
                        </label>
                    ))
                }
                {
                    fields.length < 5 && (
                        <button 
                            type="button" 
                            onClick={() => append({value: ""})}
                            style={{
                                boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25)',
                                borderRadius: 5,
                                background: '#FFFFFF',
                                padding: '6px 20px',
                                border: 'none',
                                color: '#0D183F',
                                fontFamily: 'Work Sans, sans-serif',
                                fontSize: 10,
                                fontWeight: 400,
                                lineHeight: '16px',
                                cursor: 'pointer'
                            }}
                        >{translate('common.60')}</button>
                    )
                }
            </div>
            <div style={{display: 'flex', columnGap: 8.6, marginTop: 20}}>
                <Language Icon={World} title={translate('common.37')} id="all" setValue={setValue} country={country}/>
                <Language Icon={RuFlag} title="RU" id="ru" setValue={setValue} country={country} />
                {/* <Language Icon={AzFlag} title="AZ" id="az" setValue={setValue} country={country} />
                <Language Icon={BrFlag} title="BR" id="br" setValue={setValue} country={country}/>
                <Language Icon={CaFlag} title="CA" id="ca" setValue={setValue} country={country} />
                <Language Icon={ClFlag} title="CL" id="cl" setValue={setValue} country={country} />
                <Language Icon={PeFlag} title="PE" id="pe" setValue={setValue} country={country} />
                <Language Icon={InFlag} title="IN" id="in" setValue={setValue} country={country} />
                <Language Icon={KzFlag} title="KZ" id="kz" setValue={setValue} country={country} />
                <Language Icon={TrFlag} title="TR" id="tr" setValue={setValue} country={country} />
                <Language Icon={UaFlag} title="UA" id="ua" setValue={setValue} country={country} />
                <Language Icon={UzFlag} title="UZ" id="uz" setValue={setValue} country={country} /> */}
            </div>
        </div>
    )
}

export default Information