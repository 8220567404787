import React from 'react';
import WithdrawalItem from './block/WithdrawalItem';
import { translate } from '../../../../settings/dictionary';

const Withdrawal = ({
    handleChangeShowModal
}) => {
    return (
        <div className="withdrawal" style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            <WithdrawalItem 
                title={translate('common.76')}
                description={translate('common.77')}
                btn_text={translate('common.78')}
                handleClick={handleChangeShowModal}
            />
            <WithdrawalItem 
                title={translate('common.79')}
                description={translate('common.80')}
                disabled={true} 
                btn_text={translate('common.81')}
            />
        </div>
    )
}

export default Withdrawal;