import React from 'react';
import {ReactComponent as Plus} from './plus.svg';

const Item = ({
    label,
    description
}) => {
    const [isShow, setIsShow] = React.useState(false);

    return (
        <div 
            onClick={() => setIsShow(!isShow)}
            style={{background: '#211F1C', padding: '15px 30px', cursor: 'pointer', display: 'flex', alignItems: 'flex-start', border: '1px solid #FFD74B', borderRadius: 10, columnGap: 20}}>
            <div>
                <Plus/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', rowGap: 10}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 16,
                    fontWeight: 700,
                    letterSpacing: '0.6px'
                }}>{label}</span>
                {
                    isShow && (
                        <span style={{
                            color: '#FFF',
                            fontSize: 14,
                            fontWeight: 400,
                        }} dangerouslySetInnerHTML={{__html: description}}/>
                    )
                }
            </div>
        </div>
    )
}

export default Item;