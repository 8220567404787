import React from 'react';
import {ReactComponent as Cash} from './cash.svg';
import {ReactComponent as Seven} from './seven.svg';
import {ReactComponent as Calendar} from './calendar.svg';
import {ReactComponent as Money} from './money.svg';
import { translate } from '../../../../settings/dictionary';

const RulesPartners = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 63, marginTop: 69}}>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-0.4px',
                    lineHeight: '60px'
                }}>{translate('common.82')}</span>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-0.5px',
                    lineHeight: '39px'
                }}>{translate('common.83')}</span>
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 60}}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    columnGap: 25, 
                    padding: 57, 
                    paddingLeft: 17,
                    paddingTop: 58,
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20}}>
                    <Cash style={{width: '100%', minWidth: 245, maxWidth: 245}} />
                    <span style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        letterSpacing: '2.4px',
                        paddingBottom: '3px'
                    }}>{translate('common.84')}</span>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    columnGap: 25, 
                    padding: '58px 70px 57px 5px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20}}>
                    <Seven style={{width: '100%', minWidth: 245, maxWidth: 245}} />
                    <span style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        letterSpacing: '2.4px',
                        paddingBottom: '3px'
                    }}>{translate('common.85')}</span>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center', 
                    columnGap: 25, 
                    padding: '56px 59px 54px 16px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20}}>
                    <Calendar style={{width: '100%', minWidth: 245, maxWidth: 245}} />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '2.4px',
                            paddingBottom: '3px',
                            lineHeight: '23px'
                        }}>{translate('common.86')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '2.7px',
                            paddingBottom: '3px'
                        }}>{translate('common.87')}</span>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center', 
                    columnGap: 25, 
                    padding: '56px 59px 54px 16px',
                    maxWidth: 504, width: '100%', minWidth: 544, minHeight: 165, maxHeight: 165, height: '100%', display: 'flex', border: '5px solid #FFD951', borderRadius: 20}}>
                    <Money style={{width: '100%', minWidth: 245, maxWidth: 245}} />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '2.4px',
                            paddingBottom: '3px',
                            lineHeight: '23px'
                        }}>{translate('common.88')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 24,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            letterSpacing: '2.7px',
                            paddingBottom: '3px'
                        }}>{translate('common.89')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RulesPartners;