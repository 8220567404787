import React from 'react';
import {ReactComponent as ArrowRightNext} from '../../../../../../components/assets/arrowrightnext.svg';
import { useSelector } from 'react-redux';
import { translate } from '../../../../../../../settings/dictionary';

const Step1 = ({
    step,
    setValue,
    id,
}) => {
    const offer_list = useSelector(state => state.offers.list?.data);
    const offer = offer_list.find((item) => item.id === id);

    console.log(id)
    return (
        <div className="create-promo__step-one" style={{background: step > 1 && 'rgba(123, 84, 158, 0.20)'}}>
            <span className="create-promo__step-one--title">{translate('common.481')}</span>
            {
                offer &&  (
                    <>
                        <span lassName="create-promo__step-one--text">{translate('common.46')}: {offer.strategy}</span>
                        <span className="create-promo__step-one--text">{translate('common.502')}: {offer.name}</span>
                        {
                            step === 1 && (
                                <div 
                                    className="create-promo__step-one__next"
                                    onClick={() => setValue('step', step + 1)}
                                >
                                    <span lassName="create-promo__step-one--text">{translate('common.531')}</span>
                                    <ArrowRightNext />
                                </div>       
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default Step1;