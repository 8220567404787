import React from 'react';
import HeaderLanding from './header';
import RulesPartners from './rules';
import ComissionPartners from './comission';
import Why from './why';
import Withdrawals from './withdrawals';
import Faq from './faq';
import Contacts from './contacts';
import SignIn from './signin';
import SignUp from './signup';
import ModalSuccessRegistration from './verification';
import { useRouter } from '../../../hooks/useRouter';
import { translate } from '../../../settings/dictionary';

const Landing = () => {
    const [isShowSignIn, setIsShowSignIn] = React.useState(false);
    const [isShowSignUp, setIsShowSignUp] = React.useState(false);
    const [isShowSuccessRegistration, setIsShowSuccessRegistration] = React.useState(false);
    const router = useRouter();

    const handleIsShowSignin = () => {
        setIsShowSignIn(!isShowSignIn);
    }

    const handleIsShowSignup = () => {
        setIsShowSignUp(!isShowSignUp);
    }

    const handleIsShowSuccesRegistration = () => {
        setIsShowSuccessRegistration(!isShowSuccessRegistration);
        setIsShowSignUp(false);
    }

    const handleOpenIsShowSuccessRegistration = () => {
        setIsShowSuccessRegistration(true)
    }

    return (
        <div style={{
            background: '#050021',
            height: '100%',
            width: '100%',
            paddingBottom: 100,
            position: 'relative'
        }}>
            <HeaderLanding 
                handleIsShowSignin={handleIsShowSignin} 
                handleIsShowSignup={handleIsShowSignup}
            />
            <div style={{ width: 1337, margin: '0 auto', display: 'flex', flexDirection: 'column', rowGap: 74 }}>
                <RulesPartners />
                <Why handleIsShowSignup={handleIsShowSignup}/>
                <ComissionPartners />
                <Withdrawals />
                <div style={{width: 1110, margin: '0 auto'}}>
                    <Faq />
                </div>
                <Contacts />

                <div style={{display: 'flex', columnGap: 10}}>
                    <span onClick={() => router.history.push('/privacy')} style={{
                        color: '#FFF',
                        fontSize: 20,
                        fontWeight: 400,
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>{translate('common.590')}</span>
                    <span onClick={() => router.history.push('/agreements')} style={{
                        color: '#FFF',
                        fontSize: 20,
                        fontWeight: 400,
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>{translate('common.591')}</span>
                </div>
                <div>
                    <span style={{color: '#fff'}}>
                        {translate('common.596')}
                    </span>
                </div>
            </div>
            {
                isShowSignIn && (
                    <SignIn handleIsShowSignin={handleIsShowSignin} />
                )
            }
            {
                isShowSignUp && (
                    <SignUp handleIsShowSignup={handleIsShowSignup} handleOpenIsShowSuccessRegistration={handleOpenIsShowSuccessRegistration}/>
                )
                
            }
            {
                isShowSuccessRegistration && (
                    <ModalSuccessRegistration handleIsShowSuccesRegistration={handleIsShowSuccesRegistration} />
                )
            }
          

        </div>
    )
}

export default Landing;