import React from 'react';
import Checkbox from '../checkbox';
import {ReactComponent as World} from '../../../../../../../../icons/language/world.svg';
import {ReactComponent as RuFlag} from '../../../../../../../../icons/language/ru.svg';
import {ReactComponent as AzFlag} from '../../../../../../../../icons/language/az.svg';
import {ReactComponent as BrFlag} from '../../../../../../../../icons/language/br.svg';
import {ReactComponent as CaFlag} from '../../../../../../../../icons/language/ca.svg';
import {ReactComponent as ClFlag} from '../../../../../../../../icons/language/cl.svg';
import {ReactComponent as PeFlag} from '../../../../../../../../icons/language/pe.svg';
import {ReactComponent as InFlag} from '../../../../../../../../icons/language/in.svg';
import {ReactComponent as KzFlag} from '../../../../../../../../icons/language/kz.svg';
import {ReactComponent as TrFlag} from '../../../../../../../../icons/language/tr.svg';
import {ReactComponent as UaFlag} from '../../../../../../../../icons/language/ua.svg';
import {ReactComponent as UzFlag} from '../../../../../../../../icons/language/uz.svg';
import { translate } from '../../../../../../../../../settings/dictionary';

const ItemOffers = ({
    title,
    target,
    watch,
    setValue,
    type,
    country: country_list,
    id,
    name,
    strategy,
    ...props
}) => {
    const country = []

    const handleGetCountryIcon = (value) => {
        const country = value.toString().toLowerCase();

        switch (country) {
            case "ru": return <RuFlag />
            case "ua": return <UaFlag />
            case "az": return <AzFlag />
            case "br": return <BrFlag />
            case "ca": return <CaFlag />
            case "pe": return <PeFlag />
            case "in": return <InFlag />
            case "kz": return <KzFlag />
            case "tr": return <TrFlag />
            case "uz": return <UzFlag />
            case "cl": return <ClFlag />
        }
    }

    return (
        <div style={{
            padding: '40px 60px',
            boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25)',
            borderRadius: 5,
            background: '#fff',
            width: 'calc(33% - 130px)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 6
        }}
        >
            <div style={{ textAlign: 'center' }}>
                <span style={{
                    color: '#0D183F',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '16px'
                }}>{translate('common.46')}: {strategy}</span>
            </div>
            <div style={{ textAlign: 'center' }}>
                <span style={{
                    color: '#0D183F',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '16px'
                }}>{translate('common.502')}: {name}</span>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Checkbox name="id" watch={watch} setValue={setValue} id={id} label="" />
            </div>
        </div>
    )
}

export default ItemOffers;