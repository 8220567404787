import React from 'react';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import NavigationItem from './block/Item';
import { navigation } from './navigation.config';
import Information from './block/Information';
import {ReactComponent as RuFlag} from '../../icons/flag/ru.svg';

const Navigation = () => {
    return (
        <div className="navigation">
            <div>
                <div className="navigation__logo">
                    <Logo />
                </div>
                <div className="navigation__wrap">
                    {
                        navigation.map((item, key) => (
                            <NavigationItem {...item} key={key} />
                        ))
                    }
                </div>
            </div>
            <div>
                {/* <Information /> */}
                {/* <div className="navigation__language">
                    <RuFlag />
                    <span className="navigation__language--text">RU</span>
                </div> */}
            </div>
        </div>
    )
}

export default Navigation;