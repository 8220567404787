import React from 'react';
import Api from '../Api';
import Postback from '../Postback';
import { translate } from '../../../../settings/dictionary';

const Documentation = () => {
    const [activeTab, setActiveTab] = React.useState('api');

    const handleChangeActiveTab = (tab) => {
        setActiveTab(tab);

    }
    return (
        <div className="documentation">
            <div className="documentation__tab">
                <div 
                    className={`documentation__tab__item ${activeTab === 'api' && 'documentation__tab__item--active'}`}
                    onClick={() => handleChangeActiveTab('api')}
                >
                   <span className="documentation__tab__item--text">{translate("common.127")}</span>  
                </div>
                <div 
                    className={`documentation__tab__item ${activeTab === 'postback' && 'documentation__tab__item--active'}`}
                    onClick={() => handleChangeActiveTab('postback')}
                >
                   <span className="documentation__tab__item--text">{translate("common.128")}</span>  
                </div>
            </div>
            {
                activeTab === 'api' && <Api />
            }
            {
                activeTab === 'postback' && <Postback />
            }
        </div>
    )
}

export default Documentation;