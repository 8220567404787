import React from 'react';
import ImageBackground from './bg.png';
import Btn from '../components/btn';
import { ReactComponent as Logo } from './logo.svg';
import { translate } from '../../../../settings/dictionary';
import { Select } from 'antd';

const HeaderLanding = ({
    handleIsShowSignin,
    handleIsShowSignup
}) => {
    const countries = [
        { code: 'ru', label: 'Russian Federation', label: 'ru' },
        { code: 'us', label: 'United States', label: 'en' }
    ];

    return (
        <div style={{ background: `url(${ImageBackground})`, height: 768, backgroundSize: 'cover', position: 'relative' }}>
            
            <div style={{ width: 1337, margin: '0 auto', position: 'relative' }}>
                <div style={{position: 'absolute', right: -100, top: 53}}>
                    <Select onChange={(v) => {
                        localStorage.setItem('language', v);
                        window.location.replace(window.location.origin)
                    }}
                        value={localStorage.getItem('language') || 'RU'} style={{ background: 'none', border: 'none', color: '#fff' }}
                    >
                        {countries.map((c, key) => (
                            <Select.Option key={c.code} value={c.code}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                                    alt={`Flag of ${c.label}`}
                                />
                                <span style={{paddingLeft: 5}}>{c.label?.toUpperCase()}</span>
                                
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: 20, height: 138, alignItems: 'center' }}>
                    <Btn
                        text={translate('common.214')}
                        handleClick={handleIsShowSignup}
                    />
                    <Btn
                        text={translate('common.510')}
                        handleClick={handleIsShowSignin}
                    />
                </div>
                <div style={{ textAlign: 'center', padding: '106px 40px', display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: 60 }}>
                    <span style={{
                        color: '#FFF',
                        fontFamily: 'Figtree, sans-serif',
                        fontSize: 40.581,
                        fontWeight: 700,
                        textTransform: 'uppercase'
                    }}>{translate('common.216')}</span>
                    <Logo style={{ width: 518 }} />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Btn
                            text={translate('common.217')}
                            handleClick={handleIsShowSignup}
                        />
                    </div>
                </div>
            </div>

        </div >
    )
}

export default HeaderLanding;