import React from 'react';
import { ReactComponent as Calendar } from '../../../../icons/calendar.svg';
import { ReactComponent as Link } from '../../../../icons/link.svg';
import { ReactComponent as Registration } from '../../../../icons/registration.svg';
import { ReactComponent as Deposit } from '../../../../icons/deposit.svg';
import { ReactComponent as Cash } from '../../../../icons/cash.svg';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { renderer } from '../../../../../settings/renderer';
import { translate } from '../../../../../settings/dictionary';

const TabsHome = () => {
    const information = useSelector(state => state.information.count);
    const list_day_of_the_week = [translate("common.513"), translate('common.202'), translate('common.203'), translate('common.542'), translate('common.548'), translate('common.204'), translate("common.512")];

    const day_of_the_week = new Date().getDay();
    const view = useSelector((state) => state.profile.view);

    const tabs = [
        {
            Icon: Calendar,
            label: format(new Date(), 'dd.MM.yyyy'),
            description: list_day_of_the_week[day_of_the_week]
        },
        {
            Icon: Link,
            label: renderer.formatAmount(Number(information.visite) || 0, 0),
            description: translate('common.477')
        },
        {
            Icon: Registration,
            label: renderer.formatAmount(Number(information.registration) || 0, 0),
            description: translate('common.205')
        },
        {
            Icon: Deposit,
            label: renderer.formatAmount(Number(information.total_amount) || 0, 0),
            description: translate('common.206')
        },
        {
            Icon: Cash,
            label: renderer.formatAmount(Number(view.balance_hold) || 0, 2) + "$",
            description: translate('common.514')
        },
    ];

    return (
        <div className="home__tabs">
            {
                tabs.map(({
                    Icon,
                    label,
                    description
                }) => (
                    <div className="home__tabs__item">
                        <div className="home__tabs__item--icon">
                            <Icon />
                        </div>
                        <div className="home__tabs__item__container">
                            <span className="home__tabs__item__container--label">{label}</span>
                            <span className="home__tabs__item__container--description">{description}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TabsHome;