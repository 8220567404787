import { Drawer } from '@mui/material';
import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as Cross } from '../../assets/cross.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Exit } from '../../assets/exit.svg';
import { navigation } from '../../../../desktop2/components/navigation/navigation.config';
import NavigationItem from './Item';
import { useDispatch, useSelector } from 'react-redux';
import { setGetProfile } from '../../../../store/profile/thunk/setGetProfile';
import { renderer } from '../../../../settings/renderer';
import { useRouter } from '../../../../hooks/useRouter';

const Navigation = ({
    isOpen,
    handleOpenClose
}) => {
    const dispatch = useDispatch();
    const router = useRouter();

    React.useEffect(() => {
        dispatch(setGetProfile());
    }, []);

    const view = useSelector((state) => state.profile.view);

    return (
        <Drawer
            open={isOpen}
            onClose={handleOpenClose}
        >
            <div style={{ width: 'calc(100vw - 40px)', padding: '10px 20px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 30, marginBottom: 80  }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Logo />
                        <Cross onClick={handleOpenClose} />
                    </div>
                    <div style={{ display: 'flex', columnGap: 11, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <div style={{
                            background: '#7B549E',
                            borderRadius: 4,
                            padding: '10px 16px'
                        }}>
                            <span style={{
                                fontFamily: 'Work Sans, sans-serif',
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: '16px',
                                color: '#ffffff',
                            }}>{renderer.formatAmount(Number(view.balance) || 0, 0)} $</span>
                        </div>
                        <User style={{ width: 40, height: 40 }} onClick={() => {
                            router.history.push('/profile');
                            handleOpenClose();
                        }}/>
                        <Exit style={{ width: 40, height: 40 }} onClick={() => {
                            localStorage.clear();
                            window.location.replace(window.location.origin)
                        }}/>
                    </div>
                </div>
                <div style={{
                    width: 'calc(100% - 40px)',
                    background: '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 27,
                    position: 'fixed',
                    height: '100vh'
                }}>
                    <div>

                        {
                            navigation.map((item, key) => (
                                <NavigationItem {...item} key={key} handleOpenClose={handleOpenClose} />
                            ))
                        }
                    </div>
                    <div>
                        {/* <Information /> */}
                        {/* <div className="navigation__language">
                        <RuFlag />
                        <span className="navigation__language--text">RU</span>
                    </div> */}
                    </div>
                </div>

            </div>
        </Drawer>
    )
}

export default Navigation;