import React from 'react';
import { useRouter } from '../../hooks/useRouter';
import HeaderLanding from './header';
import Contacts from '../components/landing/contacts';
import SignIn from '../components/landing/signin';
import SignUp from '../components/landing/signup';
import ModalSuccessRegistration from '../components/landing/verification';
import { translate } from '../../settings/dictionary';

const Landing = () => {
    const [isShowSignIn, setIsShowSignIn] = React.useState(false);
    const [isShowSignUp, setIsShowSignUp] = React.useState(false);
    const [isShowSuccessRegistration, setIsShowSuccessRegistration] = React.useState(false);
    const router = useRouter();

    const handleIsShowSignin = () => {
        setIsShowSignIn(!isShowSignIn);
    }

    const handleIsShowSignup = () => {
        setIsShowSignUp(!isShowSignUp);
    }

    const handleIsShowSuccesRegistration = () => {
        setIsShowSuccessRegistration(!isShowSuccessRegistration);
        setIsShowSignUp(false);
    }

    const handleOpenIsShowSuccessRegistration = () => {
        setIsShowSuccessRegistration(true)
    }

    return (
        <div style={{
            background: '#050021',
            height: '100%',
            width: '100%',
            paddingBottom: 100,
            position: 'relative'
        }}>
            <HeaderLanding
                handleIsShowSignin={handleIsShowSignin}
                handleIsShowSignup={handleIsShowSignup}
            />
            <div style={{ width: 1337, margin: '50px auto' }}>
                <div style={{ color: '#fff' }}>
                    <h1>{translate('common.559')}</h1>
                    <p>
                        {translate('common.560')}
                    </p>
                    <p>
                        {translate('common.561')}
                    </p>
                    <h3>{translate('common.562')}</h3>
                    <p>
                        {translate('common.563')}
                    </p>
                    <ul>
                        <li>{translate('common.564')}</li>
                        <li>{translate('common.565')}</li>
                        <li>{translate('common.566')}</li>
                        <li>{translate('common.567')}</li>
                    </ul>
                    <h3>
                        {translate('common.568')}
                    </h3>
                    <p>
                        {translate('common.569')}
                    </p>
                    <p>
                        {translate('common.570')}
                    </p>
                    <p>
                        {translate('common.571')}
                    </p>
                    <h3>
                        {translate('common.572')}
                    </h3>
                    <p>
                        {translate('common.573')}
                    </p>
                    <ul>
                        <li>{translate('common.574')}</li>
                        <li>{translate('common.575')}</li>
                        <li>{translate('common.576')}</li>
                        <li>{translate('common.577')}</li>
                        <li>{translate('common.578')}</li>
                    </ul>
                    <h3>
                        {translate('common.579')}
                    </h3>
                    <p>
                        {translate('common.580')}
                    </p>
                    <p>
                        {translate('common.581')}
                    </p>
                    <h3>
                        {translate('common.582')}
                    </h3>
                    <p>
                        {translate('common.583')}
                    </p>
                    <h3>{translate('common.584')}</h3>
                    <p>
                        {translate('common.585')}
                    </p>
                    <h3>
                        {translate('common.586')}
                    </h3>
                    <p>
                        {translate('common.587')}
                    </p>
                    <h3>{translate('common.588')}</h3>
                    <p>
                        {translate('common.589')}
                    </p>
                </div>
                <Contacts />
                <div style={{ display: 'flex', columnGap: 10 }}>
                    <span onClick={() => router.history.push('/privacy')} style={{
                        color: '#FFF',
                        fontSize: 20,
                        fontWeight: 400,
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>{translate('common.590')} </span>
                    <span onClick={() => router.history.push('/agreements')} style={{
                        color: '#FFF',
                        fontSize: 20,
                        fontWeight: 400,
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }}>{translate('common.591')} </span>
                </div>
                <div>
                    <span style={{color: '#fff'}}>
                        {translate('common.596')}
                    </span>
                </div>
            </div>
            {
                isShowSignIn && (
                    <SignIn handleIsShowSignin={handleIsShowSignin} />
                )
            }
            {
                isShowSignUp && (
                    <SignUp handleIsShowSignup={handleIsShowSignup} handleOpenIsShowSuccessRegistration={handleOpenIsShowSuccessRegistration} />
                )

            }
            {
                isShowSuccessRegistration && (
                    <ModalSuccessRegistration handleIsShowSuccesRegistration={handleIsShowSuccesRegistration} />
                )
            }
        </div>
    )
}

export default Landing;