import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from './components/landing';
import Layout from './components/layout';

const Mobile = () => {
    if (!localStorage.getItem('token')) {
        return (
            <Switch>
                <Route path="/" render={() => <Landing />} />
            </Switch>
        )
    }

    return (
        <div style={{background: '#F5F6FA', width: 'calc(100vw - 40px)', height: '100vh', padding: '10px 20px'}}>
           <Layout />
        </div>
    )
}

export default Mobile;