import React from 'react';
import { ReactComponent as Sort } from '../../../../components/assets/sort.svg';
import { ReactComponent as Cross } from '../../../../components/assets/cross2.svg';
import { format } from 'date-fns';
import Pagination from '../pagination';
import { useDispatch, useSelector } from 'react-redux';
import { setGetWallet } from '../../../../../store/wallet/thunk/setGetWallet';
import clsx from 'clsx';
import { setPutWallet } from '../../../../../store/wallet/thunk/setPutWallet';
import { translate } from '../../../../../settings/dictionary';

const Table = ({
    isEdit,
    handleUpdateShowWalletCreate,
    activeTab
}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetWallet({
            page: 1,
            rowsPerPage: 5,
            is_active: activeTab === 'active' ? true : activeTab === 'noactive' ? false : undefined
        }));
    }, []);

    const list = useSelector(state => state.wallet.list);

    const columns = [
        { label: translate('common.32'), value: "status", align: 'flex-start' },
        { label: translate('common.504'), value: "wallet", align: 'flex-end' }
    ];

    const handleGetNameByPaymentSystem = (value) => {
        const payment_system = value?.toString()?.toLowerCase();

        switch (payment_system) {
            case "visa": return "Visa";
            case "mastercard": return "MasterCard";
            case "world": return translate('common.188');
            case "ustd_trc_20": return "USDT(TRC20)";
        }
    }

    const handleGetStatus = (status) => {
        const value = status?.toString().toLowerCase();

        switch (value) {
            case "await": return translate('common.503');
            case "verified": return translate('common.183');
            case "canceled": return translate('common.74');
            case "archive": return translate('common.269')
        }
    }

    const handleGetStatusColor = (status) => {
        const value = status?.toString().toLowerCase();
  
        switch (value) {
            case "await": return "wallet-table__header__row__button--yellow";
            case "verified": return "";
            case "canceled": return "wallet-table__header__row__button--red";
            case "archive": return "wallet-table__header__row__button--canceled"
        }
    }


    return (
        <div>
            <div className="wallet-table__header">
                {
                    columns.map((item) => (
                        <div className="wallet-table__header__column" style={{width: '50%', justifyContent: item.align}}>
                            {
                                item.align === 'flex-end' && (
                                    <Sort className="wallet-table__header__column--sort-icon" />
                                )
                            }
                            <span className="wallet-table__header__column--text">{item.label}</span>
                            {
                                item.align === 'flex-start' && (
                                    <Sort className="wallet-table__header__column--sort-icon" />
                                )
                            }
                            
                        </div>
                    ))
                }
            </div>

            {
                list?.data?.map((item, key) => (
                    <div className={`wallet-table__header ${key && "wallet-table__header--border"}`}>
                        <div className="wallet-table__header__row" style={{width: '50%'}}>
                            <div className={clsx("wallet-table__header__row__button", handleGetStatusColor(item?.status))}>
                                <span className="wallet-table__header__row__button--text">{handleGetStatus(item.status)}</span>
                            </div>
                        </div>
                        <div className="wallet-table__header__row" style={{width: '50%', justifyContent: 'flex-end'}}>
                            <span className="wallet-table__header__row--text">{item.wallet}</span>
                        </div>
                        {/* {
                            isEdit && item.status !== 'ARCHIVE' && (
                                    <Cross className="wallet-table__header--cross" onClick={() => {
                                        dispatch(setPutWallet({
                                            wallet_id: item.id,
                                            page: list.pagination.currentPage
                                        }))
                                    }} />
                            )
                        } */}
                    </div>
                ))
            }
            {
                isEdit && (
                    <div className="wallet-table__container">
                        <div
                            onClick={() => handleUpdateShowWalletCreate()}
                            className="wallet-table__container__button"
                        >
                            <span className="wallet-table__container__button--text">{translate('common.265')}</span>
                        </div>
                    </div>
                )
            }
            {
                list.pagination.totalPage > 1 && <Pagination pagination={list.pagination} activeTab={activeTab} />
            }
        </div>
    )
}

export default Table;