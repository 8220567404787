import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetSocialNetwork } from '../../../../../store/profile/thunk/setGetSocialNetwork';
import { ReactComponent as User } from '../../../../components/assets/user2.svg';
import { ReactComponent as Telegram } from '../../../../components/assets/tg.svg';
import { ReactComponent as WhatsApp } from '../../../../components/assets/whatsapp.svg';
import { ReactComponent as Skype } from '../../../../components/assets/skype.svg';
import { translate } from '../../../../../settings/dictionary';

const ProfileINformation = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetSocialNetwork());
    }, [])

    const profile = useSelector(state => state.profile.view);
    const social_network = useSelector(state => state.profile.social_network);

    const information = [
        {
            label: translate('common.131'),
            description: profile?.name || "-",
        },
        {
            label: translate("common.260"),
            description: profile?.phone || "-"
        },
        {
            label: translate("common.261"),
            description: profile?.email || "-",
        }
    ]

    const handleGetIcon = (value) => {
        const social_network = value?.toString().toLowerCase();
        switch (social_network) {
            case "tg": return Telegram;
            case "whatsapp": return WhatsApp;
            case "skype": return Skype;
        }
    }

    const handleGetSocialNetwork = (value) => {
        return value?.map((item) => ({
            Icon: handleGetIcon(item.network),
            label: item.username,
        }))
    }


    const formatter_social_network = React.useMemo(() => handleGetSocialNetwork(social_network), [social_network]);

    return (
        <div className="profile-information" style={{width: 'auto'}}>
            <div className="profile-information__container">
                <div className="profile-information__container__avatar">
                    <User className="profile-information__container__avatar--icon" />
                </div>
            </div>
            <div className="profile-information__main-information">
                <span className="profile-information__main-information--login">{profile?.email}</span>
                <span className="profile-information__main-information--description">{translate('common.479')} {format(new Date(Number(profile.created_at) || 0), 'dd.MM.yyyy')}</span>
            </div>
            <div className="profile-information__additional-information">
                {
                    information.map((item) => (
                        <div className="profile-information__additional-information__container">
                            <span className="profile-information__additional-information__container--label">{item.label}</span>
                            <span className="profile-information__additional-information__container--description">{item.description}</span>
                        </div>
                    ))
                }
            </div>
            <div className="profile-information__social-network">
                {
                    formatter_social_network.map(({ Icon, label }) => (
                        <div className="profile-information__social-network__item">
                            <Icon />
                            <span className="profile-information__social-network__item--label">{label}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ProfileINformation;