import React from 'react';
import { ReactComponent as Sort } from '../../../icons/sort.svg';
import Pagination from './pagination';
import { ReactComponent as Copy } from '../../../icons/copy.svg';
import { ReactComponent as View } from '../../../icons/view.svg';
import { ReactComponent as Edit } from '../../../icons/edit.svg';
import { ReactComponent as Statistics } from '../../../icons/statistics.svg';
import { Link } from 'react-router-dom';
import FakeTable from '../../../../desktop2/components/profile/block/FakeTable';

const TableComponent = ({
  list,
  columns,
  handleChangePage,
  handleChangeIsShowModalEdit,
  disabled_settings
}) => {
  return (
    <div className="finance-table" style={{ overflow: 'hidden', }}>
      <div className="finance-table__table" style={{ paddingBottom: 20 }}>
        <table>
          <thead>
            <tr className="finance-table__header">
              {
                columns.map((item) => item.name === 'settings' && !disabled_settings ? (
                  <th className="finance-table__header__column" style={{ display: 'flex', justifyContent: 'center', minWidth: 150, width: 150, maxWidth: 150, position: 'fixed', right: 30, background: "#ffffff" }}>
                    <span className="finance-table__header__column--text">{item.label}</span>
                  </th>
                ) : (
                  <th className="finance-table__header__column" style={{ paddingLeft: 20, minWidth: item.width }}>
                    <span className="finance-table__header__column--text">{item.label}</span>
                    <Sort className="finance-table__header__column--sort-icon" />
                  </th>
                ))
              }
            </tr>
          </thead>
          <tbody >
            {
              list.data.map((item, key) => (
                <tr className="finance-table__header" style={{ borderTop: key && '1px solid #F2F2F2', display: 'flex', alignItems: 'center', paddingLeft: 20, position: 'relative' }}>
                  {
                    columns.map((data, key) => data.name !== 'settings'
                      && (
                        <td className="finance-table__header__row" style={{ minWidth: data.width, width: data.width, maxWidth: data.width }}>
                          <span className="finance-table__header__row--text">{data?.multiply && data.selector ? data.selector(item) : data.selector ? data.selector(item[data.name]) : item[data.name] || '-'}</span>
                        </td>
                      )
                    )
                  }
                  {
                    columns?.[columns.length - 1]?.name === 'settings' && !disabled_settings && (
                      <td className="finance-table__header__row" style={{ minWidth: 150, width: 150, maxWidth: 150, position: 'fixed', height: 57, right: 30, background: "#ffffff", display: 'flex', justifyContent: 'center' }}>
                        <div
                          onClick={() => navigator.clipboard.writeText(item.url)}
                          style={{
                            cursor: 'pointer'
                          }}>
                          <Copy style={{ width: 20, height: 20 }} />
                        </div>
                        <a href={item.url} target="_blank" style={{
                          cursor: 'pointer'
                        }}>
                          <View style={{ width: 20, height: 20 }} />
                        </a>
                        <div
                          onClick={handleChangeIsShowModalEdit}
                          style={{
                            cursor: 'pointer'
                          }
                          }>
                          <Edit style={{ width: 20, height: 20, stroke: '#acacac' }} />
                        </div>
                        <Link to="/statistic/promo" style={{
                          cursor: 'pointer'
                        }}>
                          <Statistics style={{ width: 20, height: 20 }} />
                        </Link>
                      </td>

                    )
                  }
                </tr>
              )
              )
            }
            {
                list?.loading && (
                    <FakeTable
                        rows={10}
                        columns={columns.length}
                        height_row={53}
                        pagination={false}
                    />
                )
            }
          </tbody>
        </table>

      </div>

      <Pagination
        pagination={list.pagination}
        handleChangePage={handleChangePage}
      />
    </div>
  )
}

export default TableComponent;