import React from 'react';
import {ReactComponent as CheckboxNoActive} from '../../../../../../../../icons/checkbox_noactive.svg';
import {ReactComponent as CheckboxActive} from '../../../../../../../../icons/checkbox_active.svg';

const Checkbox = ({name, watch, setValue, label, type}) => {
    const value = watch(name);

    return (
        <div 
            style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 6,
                cursor: 'pointer'
            }}
            onClick={() => {
                if (value === type) {
                    setValue(name, '');
                } else {
                    setValue(name, type)
                }
            }} 
        >
            {
                value === type && <CheckboxActive />
            }
            {
                value !== type && <CheckboxNoActive/>
            }
            <span style={{
                color: '#0D183F',
                fontFamily: 'Work Sans, sans-serif',
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '16px',
                userSelect: 'none'
            }}>{label}</span>
        </div>
    )
}

export default Checkbox;