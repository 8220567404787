import React from 'react';
import {ReactComponent as Cash} from './cash.svg';
import {ReactComponent as Seven} from './seven.svg';
import {ReactComponent as Calendar} from './calendar.svg';
import {ReactComponent as Money} from './money.svg';
import { translate } from '../../../../settings/dictionary';

const Rules = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 40}}>
            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column'}}> 
                <span style={{
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>{translate('common.82')}</span>
                <span style={{
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>{translate('common.83')}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', rowGap: 40}}>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10}}>
                    <Cash />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.101')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.102')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10}}>
                    <Seven />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.85')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10}}>
                    <Calendar />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.86')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.87')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 30, padding: 28, border: '3px solid #FFD951', borderRadius: 10}}>
                    <Money />
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.88')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 17,
                            fontWeight: 700,
                            textTransform: 'uppercase',
                        }}>{translate('common.89')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rules;