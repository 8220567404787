import React from 'react';
import {ReactComponent as Visa} from './visa.svg';
import {ReactComponent as Mastercard} from './mastercard.svg';
import {ReactComponent as Tether} from './tether.svg';
import {ReactComponent as Etherium} from './etherium.svg';
import {ReactComponent as Litecoin} from './litecoin.svg';
import {ReactComponent as Tron} from './tron.svg';
import {ReactComponent as Ripple} from './ripple.svg';
import {ReactComponent as Dogecoin} from './dogecoin.svg';
import {ReactComponent as Vertical} from './vertical.svg';
import {ReactComponent as Horizontal} from './horizontal.svg';
import Btn from '../components/btn';
import { translate } from '../../../../settings/dictionary';

const Withdrawals = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 80 }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    color: '#FFF',
                    fontSize: 48,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '-1.1px',
                    lineHeight: '60px'
                }}>{translate('common.521')}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', rowGap: 39, position: 'relative', marginBottom: 66}}>
                <div style={{
                    minWidth: 329,
                    width: 329,
                    maxWidth: 329,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 12,
                    paddingLeft: 5,
                }}>
                    <Visa />
                </div>
                <div style={{
                    minWidth: 328,
                    width: 328,
                    maxWidth: 328,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 12,
                    paddingLeft: 6
                }}>
                    <Mastercard />
                </div>
                <div style={{
                    minWidth: 325,
                    width: 325,
                    maxWidth: 325,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 12,
                    paddingLeft: 9
                }}>
                    <Tether />
                </div>
                <div style={{
                    minWidth: 327,
                    width: 327,
                    maxWidth: 327,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 10,
                    paddingLeft: 7
                }}>
                    <Etherium />
                </div>
                <div style={{
                    minWidth: 329,
                    maxWidth: 329,
                    width: 329,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 5
                }}>
                    <Litecoin />
                </div>
                <div style={{
                    minWidth: 325,
                    maxWidth: 325,
                    width: 325,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 9
                }}>
                    <Tron />
                </div>
                <div style={{
                    minWidth: 325,
                    maxWidth: 325,
                    width: 325,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 9
                }}>
                    <Ripple />
                </div>
                <div style={{
                    minWidth: 325,
                    maxWidth: 325,
                    width: 325,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingLeft: 9
                }}>
                    <Dogecoin />
                </div>
{/*                
                <Mastercard />
                <Tether />
                <Etherium />
                <Litecoin />
                <Tron />
                <Ripple />
                <Dogecoin /> */}


                <Vertical style={{position: 'absolute', top: 0, left: 337}}/>
                <Vertical style={{position: 'absolute', top: 0, right: 330}}/>
                <Vertical style={{position: 'absolute', top: 0, left: 674}}/>
                <Horizontal style={{position: 'absolute', top: 136, left: 0}} />
            </div>
            {/* <div style={{display: 'flex', justifyContent: 'center'}}>
                <Btn
                    text={translate('common.335')}
                    small
                />
            </div> */}
           
        </div>
    )
}

export default Withdrawals;