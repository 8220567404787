import React from 'react';
import Banner from '../../components/assets/banner.png';
import { ReactComponent as Calendar } from '../../components/assets/calendar.svg';
import { ReactComponent as Link } from '../../components/assets/link.svg';
import { ReactComponent as Registration } from '../../components/assets/registration.svg';
import { ReactComponent as Deposit } from '../../components/assets/deposit.svg';
import { ReactComponent as Cash } from '../../components/assets/cash.svg';
import { format } from 'date-fns';
import { renderer } from '../../../settings/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { setGetInformation } from '../../../store/information/thunk/setGetInformation';
import { translate } from '../../../settings/dictionary';

const Home = () => {
    const dispatch = useDispatch();
    const current_date = new Date();
    current_date.setHours(0)
    current_date.setMinutes(0);
    current_date.setSeconds(0);
    current_date.setMilliseconds(0);
    
    React.useEffect(() => {
        dispatch(setGetInformation({
            date_from: current_date?.getTime()
        }))
    }, [])

    const view = useSelector((state) => state.profile.view);
    const information = useSelector(state => state.information.count);

    const day_of_the_week = new Date().getDay();

    const list_day_of_the_week = [translate('common.512'), translate('common.202'), translate('common.203'), translate('common.542'), translate('common.548'), translate('common.204'), translate('common.513')];

    const tabs = [
        {
            Icon: Calendar,
            label: format(new Date(), 'dd.MM.yyyy'),
            description: list_day_of_the_week[day_of_the_week]
        },
        {
            Icon: Link,
            label: renderer.formatAmount(Number(information.visite) || 0, 0),
            description: translate('common.477')
        },
        {
            Icon: Registration,
            label: renderer.formatAmount(Number(information.registration) || 0, 0),
            description: translate('common.205')
        },
        {
            Icon: Deposit,
            label: renderer.formatAmount(Number(information.total_amount) || 0, 0),
            description: translate('common.206')
        },
        {
            Icon: Cash,
            label: renderer.formatAmount(Number(view.balance_hold) || 0, 2) + "$",
            description: translate('common.514')
        },
    ];


    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {view?.name ? `${translate('common.541')}, ${view?.name}!` : `${translate('common.541')}!`}
                </h3>
            </div>
            <div>
                <img src={Banner} alt="" style={{width: '100%'}} />
            </div>
            <div style={{display: 'flex', flexDirection: 'column', rowGap: 10}}>
                {
                    tabs.map(({
                        Icon,
                        label,
                        description
                    }) => (
                        <div className="home__tabs__item" style={{width: 'calc(100% - 40px)'}}>
                            <div className="home__tabs__item--icon">
                                <Icon />
                            </div>
                            <div className="home__tabs__item__container">
                                <span className="home__tabs__item__container--label">{label}</span>
                                <span className="home__tabs__item__container--description">{description}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Home;