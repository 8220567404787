import {types} from './types';

const initialState = {
    list: {
        count: 0,
        data: []
    }
}

export function trafficReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_TRAFFIC: 
            return { ...state, list: action.payload};
        default:
            return state
    }
}