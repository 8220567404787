import React from 'react';
import Filter from './block/filter';
import Control from '../../control';
import { setGetPromo } from '../../../../store/statistics/thunk/setGetPromo';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';

const Promo = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPromo({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.statistics.promo);

    const columns = [
        // {
        //     label: translate('common.152'),
        //     name: 'day', 
        //     width: 100,
        //     selector: (item) => {
        //         return format(new Date(Number(item)), 'dd.MM.yyyy')
        //     }
        // },
        // {label: translate('common.485'), name: 'offer', width: 200},
        // {label: translate('common.18'), name: 'geo', width: 100},
        // {label: translate('common.174'), name: 'promo_label', width: 300},
        {label: translate('common.19'), name: 'stream', width: 300},
        {
            label: 'SubID2',
            name: 'sub_2',
            width: 200,
        },
        {
            label: 'SubID3',
            name: 'sub_3',
            width: 200,
        },
        {
            label: 'SubID4',
            name: 'sub_4',
            width: 200,
        },
        {
            label: 'SubID5',
            name: 'sub_5',
            width: 200,
        },
        {
            label:  translate('common.164'), 
            name: 'visit_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.166'), 
            name: 'registration_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.165'), 
            name: 'registration_unique_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: 'FD', 
            name: 'fd_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: 'RD', 
            name: 'rd_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        
        // {
        //     label: translate('common.301'),
        //     name: 'total_fiat_amount', 
        //     width: 300,
        //     selector: (v) => renderer.formatAmount(v) + '$'
        // },
    ];

    const handleChangePage = (page) => {
        dispatch(setGetPromo({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div>
            {/* <Filter /> */}
            <Control.Table
                columns={columns}
                list={list}
                handleChangePage={handleChangePage}
            />
        </div>
    )
}

export default Promo;