import { ReactComponent as Home } from '../../icons/navigation/home.svg';
import { ReactComponent as Promo } from '../../icons/navigation/promo.svg';
import { ReactComponent as Statistic } from '../../icons/navigation/statistic.svg';
import { ReactComponent as Finance } from '../../icons/navigation/finance.svg';
import { ReactComponent as Faq } from '../../icons/navigation/faq.svg';
import { translate } from '../../../settings/dictionary';

export const navigation = [
    {
        label: translate('common.104'),
        Icon: Home,
        id: 'home',
        path: '/home'
    },
    {
        label: translate('common.105'),
        Icon: Promo,
        children: [
            {
                label: translate('common.106'),
                id: "create_promo",
                path: "/promo/create"
            },
            {
                label: translate('common.107'),
                id: "my_promo",
                path: "/promo/my"
            },
            // {
            //     label: translate('common.108'),
            //     id: "personal_promo",
            //     path: "/promo/personal"
            // },
        ]
    },
    {
        label: translate('common.109'),
        Icon: Statistic,
        children: [
            {
                label: translate('common.110'),
                id: "general_statistics",
                path: "/statistic/general"
            },
            {
                label: translate('common.111'),
                id: "promo_statistics",
                path: "/statistic/promo"
            },
            // {
            //     label: translate('common.112'),
            //     id: "referral_statistics",
            //     path: "/statistic/referral"
            // },
            {
                label: translate('common.113'),
                id: "gamer_statistics",
                path: "/statistic/gamer"
            },
            // {
            //     label: translate('common.114'),
            //     id: "minus_statistics",
            //     path: "/statistic/minus"
            // },
            {
                label: translate('common.115'),
                id: "kohort_statistics",
                path: "/statistic/kohort"
            },
            {
                label: translate('common.116'),
                id: "day_statistics",
                path: "/statistic/days"
            },
            {
                label: translate('common.117'),
                id: "subaffiliats",
                path: "/statistic/subaffiliats"
            },
        ]
    },
    {
        label: translate('common.118'),
        Icon: Finance,
        id: 'finance',
        path: "/finance"
    },
    // {
    //     label: translate('common.119'),
    //     Icon: News,
    //     id: 'news',
    //     path: "/news"
    // },
    {
        label: translate('common.120'),
        Icon: Faq,
        id: 'faq',
        path: "/faq"
    }
];