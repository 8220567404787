import React from 'react';
import Header from './header';

import SignIn from './signin';
import SignUp from './signup';
import ModalSuccessRegistration from './verification';
import Home from './home';
import { Route } from 'react-router-dom';
import Agreement from './Agreement';
import Privacy from './Privacy';
import { translate } from '../../../settings/dictionary';

const Landing = () => {
    const [isShowSignIn, setIsShowSignIn] = React.useState(false);
    const [isShowSignUp, setIsShowSignUp] = React.useState(false);
    const [isShowSuccessRegistration, setIsShowSuccessRegistration] = React.useState(false);

    const handleIsShowSignin = () => {
        setIsShowSignIn(!isShowSignIn);
    }

    const handleIsShowSignup = () => {
        setIsShowSignUp(!isShowSignUp);
    }

    const handleIsShowSuccesRegistration = () => {
        setIsShowSuccessRegistration(!isShowSuccessRegistration);
        setIsShowSignUp(false);
    }

    const handleOpenIsShowSuccessRegistration = () => {
        setIsShowSuccessRegistration(true)
    }

    return (
        <div style={{ width: '100%', background: '#050021', paddingBottom: 100 }}>
            <Header handleIsShowSignup={handleIsShowSignup} handleIsShowSignin={handleIsShowSignin} />
            <Route exact={true} path="/privacy" render={() => <Privacy />} />
            <Route exact={true} path="/agreements" render={() => <Agreement />} />
            <Route exact={true} path="/" render={() => <Home handleIsShowSignup={handleIsShowSignup} />} />
            <div style={{ textAlign: 'center', padding: '0 20px', marginTop: 20 }}>
                <span style={{ color: '#fff' }}>
                    {translate('common.596')}
                </span>
            </div>

            {
                isShowSignIn && (
                    <SignIn handleIsShowSignin={handleIsShowSignin} />
                )
            }
            {
                isShowSignUp && (
                    <SignUp handleIsShowSignup={handleIsShowSignup} handleOpenIsShowSuccessRegistration={handleOpenIsShowSuccessRegistration} />
                )

            }
            {
                isShowSuccessRegistration && (
                    <ModalSuccessRegistration handleIsShowSuccesRegistration={handleIsShowSuccesRegistration} />
                )
            }
        </div>
    )
}

export default Landing;