import React from 'react';
import Filter from './block/filter';
import Control from '../../../../desktop2/components/control';
import { setGetPromo } from '../../../../store/statistics/thunk/setGetPromo';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';

const Promo = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPromo({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.statistics.promo);

    const columns = [
        {
            label: translate('common.152'),
            name: 'day', 
            width: 100,
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
        },
        {label: translate('common.485'), name: 'offer', width: 200},
        // {label: translate('common.18'), name: 'geo', width: 100},
        {label: translate('common.174'), name: 'promo_label', width: 300},
        {label: translate('common.19'), name: 'stream', width: 300},
        {
            label: 'SubID',
            multiply: true,
            width: 200,
            selector: (item) => [item.sub_1, item.sub_2, item.sub_3, item.sub_4, item.sub_5]?.filter(item => item).join(', ') || '-'
        },
        {
            label:  translate('common.164'), 
            name: 'visit_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.124'), 
            name: 'registration_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.165'), 
            name: 'registration_unique_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: 'FD', 
            name: 'fd_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.301'),
            name: 'total_fiat_amount', 
            width: 300,
            selector: (v) => renderer.formatAmount(v) + '$'
        },
    ];

    const handleChangePage = (page) => {
        dispatch(setGetPromo({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.319')}
                </h3>
            </div>
            <div>
                {/* <Filter /> */}
                <Control.Table
                    columns={columns}
                    list={list}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    )
}

export default Promo;