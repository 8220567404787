import React from 'react';
import Checkbox from '../checkbox';
import { translate } from '../../../../../../../../../settings/dictionary';

const ItemTemplatePromo = ({
    watch,
    setValue,
    id,
    title,
    image,
    country
}) => {
    return (
        <div style={{
            padding: 10,
            background: '#ffffff',
            borderRadius: 5,
            boxShadow: '0px 4px 4px 0px rgba(133, 133, 133, 0.25)',
            width: 'calc(33% - 40px)'
        }}>
            <div style={{
                marginBottom: 10
            }}>
                <span style={{
                    color: '#0D183F',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '22px',
                    letterSpacing: '0.32px'
                }}>{title}</span>
            </div>
            <div style={{background: `url(${image})`, backgroundwidth: '100%', height: 350, backgroundSize: 'cover'}} />
            <div style={{display: 'flex', justifyContent: 'center', columnGap: 6, alignItems: 'center', marginTop: 10}}>
                <Checkbox name="promo" watch={watch} setValue={setValue} type={id} label="" />
                <span style={{
                    color: '#0D183F',
                    fontFamily: 'Work Sans, sans-serif',
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '16px'
                }}>{translate('common.539')}</span>
            </div>
        </div>
    )
}

export default ItemTemplatePromo;