import React from 'react';
import Datepicker from '../../../../datepicker';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { setGetSubaffiliats } from '../../../../../../store/subaffiliats/thunk/setGetSubaffiliats';
import { translate } from '../../../../../../settings/dictionary';

const Filter = () => {
    const dispatch = useDispatch();

    const { handleSubmit, setValue, watch, control, register} = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            dateFrom: "",
            dateTo: ""
        }
    });

    const onSubmit = (store) => {
        dispatch(setGetSubaffiliats({
            page: 1,
            rowsPerPage: 10,
            dateFrom: store.dateFrom,
            dateTo: store.dateTo
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="filter-statistics-days" style={{
            display: 'flex',
            justifyContent: 'flex-end',
            columnGap: 10,
            alignItems: 'center'
        }} >
            <Datepicker register={register} />
            <button type="submit" className="wallet__header__edit" style={{border: 'none'}}>
                <span className="wallet__header__edit--text">{translate('common.302')}</span>
            </button>
        </form>
    )
}

export default Filter;