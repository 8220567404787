import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as Cross } from '../../../../components/assets/cross2.svg';
import { useDispatch } from 'react-redux';
import { setPostWallet } from '../../../../../store/wallet/thunk/setPostWallet';
import { setGetWallet } from '../../../../../store/wallet/thunk/setGetWallet';
import { translate } from '../../../../../settings/dictionary';

const WalletCreate = ({
    handleUpdateShowWalletCreate,
    activeTab
}) => {
    const dispatch = useDispatch();

    const { handleSubmit, 
        reset, 
        register 
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            payment: "",
            wallet: "",
            status: ""
        }
    });

    const onSubmit = (store) => {
        const params = {
            payment: store?.payment,
            status: store?.status === 'active',
            wallet: store.wallet
        };
        dispatch(setPostWallet(params, () => {
            reset({});
            handleUpdateShowWalletCreate();
            dispatch(setGetWallet({
                page: 1,
                rowsPerPage: 5,
                is_active: activeTab
            }));
        }));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="wallet__create">
            <div className="wallet__create__wrap" style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                minWidth: 'auto',
                padding: 40,
                position: 'relative'
            }}>
                <div className="wallet__create__wrap__cross" style={{position: 'absolute', top: 50, right: 10}}>
                    <div 
                        onClick={() => handleUpdateShowWalletCreate()}
                        className="wallet__create__wrap__cross__box"
                        style={{width: 50, height: 50}}
                    >
                        <Cross 
                            className="wallet__create__wrap__cross__box--icon"
                        />
                    </div>
                </div>
                <div className="wallet__create__wrap__title">
                    <h3 className="wallet__create__wrap__title--text">{translate('common.265')}</h3>
                </div>
                <div>
                    <select className="wallet__create__wrap__select" {...register('payment')}>
                        <option value="" className="wallet__create__wrap__select--none">{translate('common.62')}</option>
                        <option value="mastercard">MasterCard</option>
                        <option value="visa">VISA</option>
                        <option value="world">{translate('common.188')}</option>
                        <option value="usdt_trc_20">USDT(TRC20)</option>
                    </select>
                </div>
                <div>
                    <input 
                        {...register('wallet')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.67')}
                    />
                </div>
                <div className="wallet__create__wrap__container">
                    <button 
                        type="submit"
                        className="wallet__create__wrap__container__button"
                    >
                        <span className="wallet__create__wrap__container__button--text">
                            {translate('common.60')}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default WalletCreate;