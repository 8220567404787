import React from 'react';
import Filter from './block/filter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetGamers } from '../../../../store/statistics/thunk/setGetGamers';
import Control from '../../../../desktop2/components/control';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';

const Gamer = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetGamers({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const columns = [
        { label: translate('common.16'), name: 'id', width: 100, },
        { label: translate('common.17'), name: 'platform_id', width: 150, },
        { label: translate('common.19'), name: 'stream', width: 300, },
        {
            label: translate('common.534'),
            multiply: true,
            width: 200,
            selector: (item) => [item.sub_1, item.sub_2, item.sub_3, item.sub_4, item.sub_5]?.filter(item => item).join(', ') || '-'
        },
        { 
            label: translate('common.47'), 
            name: 'created_at', 
            width: 200,
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
         },
        { 
            label: translate('common.23'), 
            name: 'last_visite',
            width: 200, 
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy HH:mm')
            }
        },
        { label: translate('common.25'), name: 'currency', width: 100, },
        { 
            label: translate('common.49'), 
            name: 'count_deposit', 
            width: 200, 
            selector: renderer.formatAmount
        },
        { 
            label: translate('common.27'), 
            name: 'sum_deposit',
            width: 200,
            selector: renderer.formatAmount
        },
        { 
            label: translate('common.494'),
            name: 'count_withdrawals', 
            width: 200,
            selector: renderer.formatAmount
        },
        { 
            label: translate('common.31'), 
            name: 'sum_withdrawals', 
            width: 200,
            selector: renderer.formatAmount
         },
        { 
            label: translate('common.276'), 
            name: 'profit', 
            width: 200,
            selector: renderer.formatAmount
        }
    ];

    const list = useSelector(state => state.statistics.gamers);

    const handleChangePage = (page) => {
        dispatch(setGetGamers({
            page,
            rowsPerPage: 10,
        }));
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.316')}
                </h3>
            </div>
            <div>
                {/* <Filter /> */}
                <Control.Table
                    columns={columns}
                    list={list}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    )
}

export default Gamer;