import React from 'react';
import FaqItem from './block/FaqItem';
import { faq_list } from './faq.config';

const Faq = () => {
    return (
        <div className="faq" style={{ display: 'flex', flexDirection: 'column', rowGap: 15 }}>
            {
                faq_list.map((item) => <FaqItem {...item} />)
            }
        </div>
    )
}

export default Faq;