import React from 'react';
import TabsHome from './block/tabs';
import BannerHome from './block/banner';
import ChartHome from './block/chart';
import LastNews from './block/lastNews';
import { useDispatch } from 'react-redux';
import { setGetInformation } from '../../../store/information/thunk/setGetInformation';

const Home = () => {
    const dispatch = useDispatch();
    const current_date = new Date();
    current_date.setHours(0)
    current_date.setMinutes(0);
    current_date.setSeconds(0);
    current_date.setMilliseconds(0);
    
    React.useEffect(() => {
        dispatch(setGetInformation({
            date_from: current_date?.getTime()
        }))
    }, [])

    return (
        <div className="home">
            <TabsHome />
            <BannerHome />

            {/* <div className="home__content">
                <ChartHome />
                <LastNews />
            </div> */}
            
        </div>
    )
}

export default Home;