import React from 'react';
import {ReactComponent as ArrowDown} from '../../../../icons/arrowdown.svg';
import {ReactComponent as ArrowUp} from '../../../../icons/arrowup.svg';

const FaqItem = ({
    title,
    description
}) => {
    const [isShowDescription, setIsShowDescription] = React.useState(false);

    const handleChangeShowDescription = () => {
        setIsShowDescription(!isShowDescription);
    }

    return (
        <div className="faq__item">
            <div 
                className="faq__item__header"
                onClick={() => handleChangeShowDescription()}
            >
                <span className="faq__item__header--text">{title}</span>
                {
                    isShowDescription 
                        ? <ArrowUp /> 
                        : <ArrowDown />
                }
            </div>
            {
                isShowDescription && (
                    <div className="faq__item__description">
                        <span className="faq__item__description__text" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                )
            }
        </div>
    )
}

export default FaqItem;