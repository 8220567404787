
import { isFunction } from "lodash";
import { setGetPostbackApplicationAction } from "../action";
import { getPostbackApplication } from "../api/getPostbackApplication";

export const setGetPostbackApplication = (params, handleShowMessage) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPostbackApplication(params);
            dispatch(setGetPostbackApplicationAction(data.data));

        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else if (isFunction(handleShowMessage)) {
                handleShowMessage(error?.response?.data);
            }
        }
    }
}