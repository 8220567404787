import React from 'react';
import {ReactComponent as ArrowLeftBack} from '../../../../../../icons/arrowleftback.svg';
import Select from '../../../../../select';
import { translate } from '../../../../../../../settings/dictionary';

const Step3 = ({
    step,
    setValue,
    register
}) => {
    const options = [{
        label: translate('common.551'),
        value: 'aso_brand'
    },{
        label: translate('common.280'),
        value: 'aso_cross'
    },{
        label: translate('common.499'),
        value: 'context_brand'
    },{
        label: translate('common.281'),
        value: 'context_cross'
    },{
        label: translate('common.501'),
        value: 'seo_brand'
    },{
        label: translate('common.282'),
        value: 'seo_cross'
    },{
        label: translate('common.538'),
        value: 'fb_webview'
    },{
        label: translate('common.94'),
        value: 'fb_native'
    },{
        label: translate('common.537'),
        value: 'uac_webview'
    },{
        label: translate('common.95'),
        value: 'uac_native'
    },{
        label: translate('common.96'),
        value: 'tt_webview'
    },{
        label: translate('common.97'),
        value: 'tt_native'
    },{
        label: 'InnApp',
        value: 'innapp'
    },{
        label: translate('common.497'),
        value: 'schemes_without_support'
    },{
        label: translate('common.98'),
        value: 'schemes_with_support'
    },{
        label: translate('common.99'),
        value: 'influencers'
    },{
        label: translate('common.283'),
        value: 'teaser'
    },{
        label: translate('common.284'),
        value: 'newsletter'
    },{
        label: translate('common.285'),
        value: 'streams'
    },{
        label: translate('common.496'),
        value: 'another'
    }];

    return (
        <div className="create-promo__step-one">
            <span className="create-promo__step-one--title">{translate('common.286')}</span>
            {
                step >= 3 && (
                    <>
                        <span lassName="create-promo__step-one--text">{translate('common.287')}</span>
                        <Select
                            options={options}
                            placeholder={translate('common.37')}
                            register={register}
                            name="traffic_type"
                        />
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="create-promo__step-one__next" onClick={() => setValue('step', step - 1)}>
                                <ArrowLeftBack />
                                <span lassName="create-promo__step-one--text">{translate('common.532')}</span>
                            </div>
                            <button type="submit" style={{border: 'none', background: 'none'}}>
                                <span style={{
                                    color: '#0D183F',
                                    fontFamily: 'Work Sans, sans-serif',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '16px',
                                    cursor: 'pointer'
                                }}>{translate('common.500')}</span>
                            </button>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default Step3;