import RU from './ru.json';
import EN from './en.json';

export const translate = (values) => {
    const language = localStorage.getItem('language');
    
    switch (language?.toUpperCase()) {
        case 'US': {
            if (!EN[values]) {
                return values
            }
            return EN[values]; 
        }
        default: {
            if (!RU[values]) {
                return values
            }
            return RU[values]; 
        }
    }
}

export const i18n = (replaceObject, dictionary) => {
    let textTranslate = translate(dictionary);
    for (let key in replaceObject) {
        textTranslate = textTranslate.replace(`{${key}}`, replaceObject[key]);
    }
    return textTranslate;
}