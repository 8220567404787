import React from 'react';
import ItemOffers from './block/itemOffer';
import { useDispatch, useSelector } from 'react-redux';
import { setGetOffers } from '../../../../../../../store/offers/thunk/setGetOffers';

const ContentStep1 = ({
    watch,
    setValue
}) => {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetOffers())
    }, [])

    const offers = useSelector(state => state.offers.list);

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%',
                rowGap: 20
            }}>
                {
                    offers?.data?.map((item) => <ItemOffers watch={watch} setValue={setValue} {...item} />)
                }
            </div>
        </div>
    )
}

export default ContentStep1;