import React from 'react';
import { ReactComponent as User } from '../../../../../components/assets/revshare.svg';
import { Tooltip } from 'antd';
import { translate } from '../../../../../../settings/dictionary';

const WithdrawalItem = ({
    title,
    description,
    btn_text,
    disabled,
    handleClick
}) => {
    return (
        <div className="withdrawal__item" style={{width: 'calc(100% - 40px)', flexDirection: 'column', rowGap: 10}}>
            <div className="withdrawal__item__information">
                <div className="withdrawal__item__information__title">
                    <User className="withdrawal__item__information__title--icon-before" />
                    <span className={`withdrawal__item__information__title--text`}>{title}</span>
                </div>
                <div>
                    <span className={`withdrawal__item__information--description ${disabled && 'withdrawal__item__information--description--disabled'}`}>{description}</span>
                </div>
            </div>
            <div style={{width: 'calc(100% - 40px)'}}>
                {
                    disabled && (
                        <Tooltip title={translate('common.505')}>
                            <div 
                                className={`withdrawal__item__btn ${disabled && 'withdrawal__item__btn--disabled'}`}
                                style={{width: '100%', textAlign: 'center'}}
                            >
                                <span className="withdrawal__item__btn--text">{btn_text}</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    !disabled && (
                        <div 
                            onClick={handleClick}
                            className={`withdrawal__item__btn ${disabled && 'withdrawal__item__btn--disabled'}`}
                            style={{width: '100%', textAlign: 'center'}}    
                        >
                            <span className="withdrawal__item__btn--text">{btn_text}</span>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default WithdrawalItem;