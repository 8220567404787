import React from 'react';
import Filter from './block/filter';
import Control from '../../../../desktop2/components/control';
import { useDispatch, useSelector } from 'react-redux';
import { setGetForDays } from '../../../../store/statistics/thunk/setGetForDays';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';

const Days = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetForDays({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.statistics.for_days);

    const columns = [
        {
            label: translate('common.66'),
            name: 'day',
            width: 100,
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
        },
        {
            label:  translate('common.164'),
            name: 'visite_count',
            width: 150,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.124'),
            name: 'registration_count',
            width: 150,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.165'),
            name: 'registration_unique',
            width: 150,
            selector: renderer.formatAmount
        },
        {
            label: 'FD',
            name: 'fd_count',
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: 'RD',
            name: 'rd_count',
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.169'),
            name: 'total_amount',
            width: 200,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.33'),
            name: 'profit',
            width: 200,
            selector: renderer.formatAmount
        }
    ];

    const handleChangePage = (page) => {
        dispatch(setGetForDays({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.315')}
                </h3>
            </div>
            <div>
                {/* <Filter /> */}
                <Control.Table
                    columns={columns}
                    list={list}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    )
}

export default Days;