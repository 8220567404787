// import { format } from 'date-fns';


const formatAmount = (value = 0, float = 0) => new Intl.NumberFormat('ru-RU').format(Number(value)?.toFixed(float));

const renderer = {
    formatAmount
}


export {
    renderer
}