import clsx from 'clsx';
import React from 'react';

const Btn = ({
    text,
    small,
    handleClick
}) => {
    return (
        <div className="landing__btn" onClick={handleClick}>
            <span className={clsx("landing__btn--text", small && "landing__btn--text--small")}>{text}</span>
        </div>
    )
}

export default Btn;