import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as Cross } from '../../../../../../components/assets/cross2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setPostPostbackGlobal } from '../../../../../../../store/postback/thunk/setPostPostbackGlobal';
import { useSnackbar } from 'notistack';
import { setGetPostbackGlobal } from '../../../../../../../store/postback/thunk/setGetPostbackGlobal';
import { translate } from '../../../../../../../settings/dictionary';

const ModalGlobal = ({
    handleUpdateShowModalGlobal
}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPostbackGlobal())
    }, []);

    const global = useSelector(state => state.postback.global);

    React.useEffect(() => {
        reset({
            ...global
        })
    }, [global])

    const { handleSubmit, 
        reset, 
        register,
        setValue,
        watch
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            send_zero_postback: false,
            registration: "",
            fd: "",
            rd: "",
            rs: "",
            baseline: ""
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    
    const handleShowMessage = ({message, status}) => {
        if (status === 'success') {
            reset();
            handleUpdateShowModalGlobal();
        }
        enqueueSnackbar(message, {variant: status});
    }

    const onSubmit = data => dispatch(setPostPostbackGlobal(data, handleShowMessage))

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="wallet__create">
            <div className="wallet__create__wrap">
                <div className="wallet__create__wrap__cross">
                    <div 
                        onClick={() => handleUpdateShowModalGlobal()}
                        className="wallet__create__wrap__cross__box"
                    >
                        <Cross 
                            className="wallet__create__wrap__cross__box--icon"
                        />
                    </div>
                </div>
                <div className="wallet__create__wrap__title">
                    <h3 className="wallet__create__wrap__title--text">{translate('common.314')}</h3>
                </div>
                <div>
                    <input 
                        {...register('registration')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.214')}
                    />
                </div>
                <div>
                    <input 
                        {...register('fd')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.248')}
                    />
                </div>
                <div>
                    <input 
                        {...register('rd')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.167')}
                    />
                </div>
                <div>
                    <input 
                        {...register('rs')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.259')}
                    />
                </div>
                <div>
                    <input 
                        {...register('baseline')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.256')}
                    />
                </div>
                {/* <div>
                    <Checkbox name="send_zero_postback" watch={watch} setValue={setValue} label={translate('common.92')}/>
                </div> */}
                <div className="wallet__create__wrap__container">
                    <button 
                        type="submit"
                        className="wallet__create__wrap__container__button"
                    >
                        <span className="wallet__create__wrap__container__button--text">
                            {translate('common.138')}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ModalGlobal;