import React from 'react';
import ItemOffers from './block/itemOffer';
import { useDispatch, useSelector } from 'react-redux';
import { setGetOffers } from '../../../../../../../store/offers/thunk/setGetOffers';
import { translate } from '../../../../../../../settings/dictionary';

const ContentStep1 = ({
    watch,
    setValue
}) => {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetOffers())
    }, [])

    const offers = useSelector(state => state.offers.list);

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 20
            }}>
                <div style={{width: '33%', textAlign: 'center'}}>
                    <span style={{
                        color: '#0D183F',
                        fontFamily: 'Work Sans, sans-serif',
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '16px'
                    }}>{translate('common.52')}</span>
                </div>
                <div style={{width: '33%', textAlign: 'center'}}>
                    <span style={{
                        color: '#0D183F',
                        fontFamily: 'Work Sans, sans-serif',
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '16px'
                    }}>{translate('common.147')}</span>
                </div>
                <div style={{width: '33%', textAlign: 'center'}}>
                    <span style={{
                        color: '#0D183F',
                        fontFamily: 'Work Sans, sans-serif',
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '16px'
                    }}>{translate('common.458')}</span>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    marginTop: 10,
                    flexDirection: 'column',
                    rowGap: 10,
                    width: '33%'
                }}>
                    {
                        offers?.data?.map((item) => <ItemOffers watch={watch} setValue={setValue} {...item} />)
                    }
                </div>
                <div style={{
                    display: 'flex',
                    marginTop: 10,
                    flexDirection: 'column',
                    rowGap: 10,
                    width: '33%'
                }}>
                    {
                        offers?.data?.map((item) => item.offer === 'casino' && <ItemOffers watch={watch} setValue={setValue} {...item} />)
                    }
                </div>
                <div style={{
                    display: 'flex',
                    marginTop: 10,
                    flexDirection: 'column',
                    rowGap: 10,
                    width: '33%'
                }}>
                    {
                        offers?.data?.map((item) => item.offer === 'partners' && <ItemOffers watch={watch} setValue={setValue} {...item} />)
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default ContentStep1;