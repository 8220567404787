import { setGetWithdrawalsAction } from "../action";
import { getWithdrawals } from "../api/getWithdrawals";

export const setGetWithdrawals = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getWithdrawals(params);
            if (data?.status === 'success') {
                dispatch(setGetWithdrawalsAction({
                    data: data.data,
                    pagination: data.pagination
                }))
            }
        } catch(error) {

            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}