import React from 'react';
import { ReactComponent as Sort } from '../../../../components/assets/sort.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setGetHistoryEvent } from '../../../../../store/history_event/thunk/setGetHistoryEvent';
import { format } from 'date-fns';
import PaginationLogs from '../paginationLogs';
import { translate } from '../../../../../settings/dictionary';

const Logs = ({
}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetHistoryEvent({
            page: 1,
            rowsPerPage: 5,
        }));
    }, []);

    const list = useSelector(state => state.eventHistory.list);

    const columns = [
        { label: translate('common.134'), value: "action", align: 'flex-start' },
        { label: "IP", value: "ip", align: 'flex-end'}
    ];

    const handleGetName = (value) => {
        const name = value?.toString().toLowerCase();
        switch(name) {
            case "login": return translate('common.244');
            case "created wallet": return translate('common.245');
            case "change password": return translate('common.465');
        }
    }

    return (
        <div>
            <div className="wallet-table__header">
                {
                    columns.map((item) => (
                        <div className="wallet-table__header__column" style={{width: '50%', justifyContent: item.align}}>
                            {
                                item.align === 'flex-end' && (
                                    <Sort className="wallet-table__header__column--sort-icon" />
                                )
                            }
                            <span className="wallet-table__header__column--text">{item.label}</span>
                            {
                                item.align === 'flex-start' && (
                                    <Sort className="wallet-table__header__column--sort-icon" />
                                )
                            }
                            
                        </div>
                    ))
                }
            </div>

            {
                list?.data?.map((item, key) => (
                    <div className={`wallet-table__header ${key && "wallet-table__header--border"}`} >
                        <div className="wallet-table__header__row" style={{width: '50%', textAlign: 'left'}}>
                            <span className="wallet-table__header__row--text">{handleGetName(item.event)}</span>
                        </div>
                        <div className="wallet-table__header__row" style={{width: '50%', justifyContent: 'flex-end'}}>
                            <span className="wallet-table__header__row--text">{item.ip}</span>
                        </div>
                    </div>
                ))
            }
            {
                list.pagination.totalPage > 1 && <PaginationLogs pagination={list.pagination} />
            }
        </div>
    )
}

export default Logs;