import React from 'react';
import Withdrawal from './block/withdrawal';
import Table from './block/table';
import ModalWithdarawal from './components/Modal/ModalWithdrawal';

const Finance = () => {
    const [isShowModal, setIsShowModal] = React.useState(false);

    const handleChangeShowModal = () => {
        setIsShowModal(!isShowModal)
    }
    

    return (
        <div>
            <Withdrawal handleChangeShowModal={handleChangeShowModal}/>
            <Table />
            {
                isShowModal && (
                    <ModalWithdarawal handleChangeShowModal={handleChangeShowModal}/>
                )
            }
            
        </div>
    )
}

export default Finance;