import React from 'react';
import ImageBackground from './bg.png';
import {ReactComponent as Logo} from './logo.svg';
import { translate } from '../../../settings/dictionary';
import Btn from '../../components/landing/components/btn';

const HeaderLanding = ({
    handleIsShowSignin,
    handleIsShowSignup
}) => {
    return (
        <div style={{ background: `url(${ImageBackground})`, height: 768, backgroundSize: 'cover' }}>
            <div style={{ width: 1337, margin: '0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: 20, height:  138, alignItems: 'center'}}>
                    <Btn
                        text={translate('common.214')}
                        handleClick={handleIsShowSignup}
                    />
                    <Btn
                        text={translate('common.510')}
                        handleClick={handleIsShowSignin}
                    />
                </div>
                <div style={{textAlign: 'center', padding: '106px 40px', display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: 60}}>
                    <span style={{
                        color: '#FFF',
                        fontFamily: 'Figtree, sans-serif',
                        fontSize: 40.581,
                        fontWeight: 700,
                        textTransform: 'uppercase'
                    }}>{translate('common.590')}</span>
                    <Logo style={{width: 518}}/>
                </div>
            </div>

        </div>
    )
}

export default HeaderLanding;