import React from 'react';
import { useForm } from 'react-hook-form';
import Select from '../../../../select';
import { useDispatch, useSelector } from 'react-redux';
import { setGetWallet } from '../../../../../../store/wallet/thunk/setGetWallet';
import { setPostWithdrawals } from '../../../../../../store/withdrawals/thunk/setPostWithdrawals';
import { useSnackbar } from 'notistack';
import {ReactComponent as Cross} from './cross.svg';
import { translate } from '../../../../../../settings/dictionary';

const ModalWithdarawal = ({
    handleChangeShowModal
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        dispatch(setGetWallet({
            is_active: true,
            page: 1,
            rowsPerPage: 2147483647
        }))
    }, [])

    const handleShowMessage = ({message, status}) => {
        handleChangeShowModal();
        enqueueSnackbar(message, {variant: status});
    }

    const { handleSubmit, register,
        // setValue, watch, control 
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            wallet: '',
            sum: '',

        }
    });

    const onSubmit = (store) => {
        dispatch(setPostWithdrawals(store, handleShowMessage))
    }

    const wallet = useSelector((state) => state.wallet.list);

    return (
        <div 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(0,0,0,.2)'
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)} 
                style={{
                    background: '#FFFFFF',
                    padding: '88px 80px 40px',
                    minWidth: 500,
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 40,
                    position: 'relative',
                    borderRadius: 5
                }}>
                    <Cross style={{position: 'absolute', top: 40, right: 80, cursor: 'pointer'}} onClick={handleChangeShowModal} />
                <div style={{ textAlign: 'center' }}>
                    <span style={{
                        color: '#0D183F',
                        textAlign: 'center',
                        fontFamily: "Work Sans, sans-serif",
                        fontSize: 28,
                        fontWeight: 400,
                        lineHeight: '22px'
                    }}>{translate('common.7')}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                    <Select
                        options={wallet.data?.map((item) => ({ value: item.id, label: item.wallet }))}
                        placeholder={translate('common.506')}
                        register={register}
                        name="wallet"
                    />
                    <input
                        {...register('sum')}
                        type="number"
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.190')}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button className={`withdrawal__item__btn`} style={{ border: 'none' }}>
                            <span className="withdrawal__item__btn--text">{translate('common.191')}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ModalWithdarawal;