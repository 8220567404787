import { Skeleton } from '@mui/material';
import React from 'react';

const FakeTable = ({
    rows,
    columns,
    height_row = 52,
    pagination = true
}) => {
    let randomRows = Array.from({ length: rows }, () => Math.floor(Math.random() * 100) + 1);
    let randomColumns = Array.from({ length: columns }, () => Math.floor(Math.random() * 100) + 1);

    return (
        <div style={{ display: 'flex', rowGap: 2, flexDirection: 'column', width: '100%' }}>
            {
                randomRows?.map((item) => (
                    <div style={{ display: 'flex', columnGap: 2, width: '100%' }}>
                        {
                            randomColumns?.map(() => (
                                <Skeleton variant="rectangular" width={100 / randomColumns.length + '%'} height={height_row + 'px'} style={{ borderRadius: 3 }} />
                            ))
                        }

                    </div>
                ))
            }
            {
                pagination && (
                    <div style={{ marginTop: 8, display: 'flex', justifyContent: 'center' }}>
                        <Skeleton variant="rectangular" width={300} height="52px" style={{ borderRadius: 3 }} />
                    </div>
                )
            }

        </div>
    )
}

export default FakeTable;