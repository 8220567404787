import React from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as Cross } from '../../../../../../icons/cross.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setPostPostbackApplication } from '../../../../../../../store/postback/thunk/setPostPostbackApplication';
import { setGetPostbackApplication } from '../../../../../../../store/postback/thunk/setGetPostbackApplication';
import { translate } from '../../../../../../../settings/dictionary';

const ModalApplication = ({
    handleUpdateShowModalApplication
}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPostbackApplication())
    }, []);

    const application = useSelector(state => state.postback.application);

    React.useEffect(() => {
        reset({
            ...application
        })
    }, [application])

    const { handleSubmit, 
        reset, 
        register
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            send_zero_postback: false,
            registration: "",
            fd: "",
            rd: "",
            baseline: ""
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    
    const handleShowMessage = ({message, status}) => {
        if (status === 'success') {
            reset();
            handleUpdateShowModalApplication();
        }
        enqueueSnackbar(message, {variant: status});
    }

    const onSubmit = data => dispatch(setPostPostbackApplication(data, handleShowMessage))

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="wallet__create">
            <div className="wallet__create__wrap">
                <div className="wallet__create__wrap__cross">
                    <div 
                        onClick={() => handleUpdateShowModalApplication()}
                        className="wallet__create__wrap__cross__box"
                    >
                        <Cross 
                            className="wallet__create__wrap__cross__box--icon"
                        />
                    </div>
                </div>
                <div className="wallet__create__wrap__title">
                    <h3 className="wallet__create__wrap__title--text">{translate('common.253')}</h3>
                </div>
                <div>
                    <input 
                        {...register('registration')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.254')}
                    />
                </div>
                <div>
                    <input 
                        {...register('fd')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.255')}
                    />
                </div>
                <div>
                    <input 
                        {...register('rd')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.167')}
                    />
                </div>
                <div>
                    <input 
                        {...register('baseline')}
                        type="text" 
                        className="wallet__create__wrap__field"
                        placeholder={translate('common.256')}
                    />
                </div>
                <div className="wallet__create__wrap__container">
                    <button 
                        type="submit"
                        className="wallet__create__wrap__container__button"
                    >
                        <span className="wallet__create__wrap__container__button--text">
                            {translate('common.138')}
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ModalApplication;