import { translate } from "../../../settings/dictionary";

export const faq_list = [
    {
        title: translate('faq.1.2'),
        description: translate('faq.1.3'),
    },
    {
        title: translate('faq.1.4'),
        description: translate('faq.1.5'),
    },
    {
        title: translate(`faq.1.6`),
        description: `${translate(`faq.1.7.1`)}
        <br/><br/>
        ${translate(`faq.1.7.2`)}
        <br/><br/>
        ${translate(`faq.1.7.3`)}
        <br/><br/>
        ${translate(`faq.1.7.4`)}
        `,
    },
    {
        title: translate(`faq.1.10`),
        description: translate(`faq.1.11`),
    },
    {
        title: translate(`faq.1.8`),
        description: `${translate(`faq.1.9.1`)}<br/>
        ${translate(`faq.1.9.2`)}
        ${translate(`faq.1.9.3`)}<br/>
        ${translate(`faq.1.9.4`)}
        ${translate(`faq.1.9.5`)}<br/><br/>
        
        ${translate(`faq.1.9.6`)}<br/>
        ${translate(`faq.1.9.7`)}<br/>
        ${translate(`faq.1.9.8`)}<br/>
        ${translate(`faq.1.9.9`)}<br/>
        ${translate(`faq.1.9.10`)}
        `,
    },
    {
        title: translate('faq.1.12'),
        description: translate(`faq.1.13`),
    },
    {
        title: translate('faq.1.14'),
        description: translate('faq.1.15'),
    },
    {
        title: translate('faq.1.16'),
        description: translate('faq.1.17'),
    },
    {
        title: translate('faq.1.18'),
        description: translate('faq.1.19'),
    },
    {
        title: translate('faq.1.20'),
        description: translate('faq.1.21'),
    },
    {
        title: translate('faq.1.22'),
        description: translate('faq.1.23'),
    },
    {
        title: translate('faq.1.24'),
        description: translate('faq.1.25'),
    },
    {
        title: translate('faq.1.26'),
        description: translate('faq.1.27'),
    },
    {
        title: translate('faq.1.28'),
        description: translate('faq.1.29'),
    },
    {
        title: translate('faq.1.30'),
        description: translate('faq.1.31'),
    },
    {
        title: translate('faq.1.32'),
        description: translate('faq.1.33'),
    },
    {
        title: translate('faq.1.34'),
        description: translate('faq.1.35'),
    },
    {
        title: translate('faq.1.36'),
        description: translate('faq.1.37'),
    }
];