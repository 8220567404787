import React from 'react';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import {ReactComponent as Burger} from '../../assets/burger.svg';
import { useRouter } from '../../../../hooks/useRouter';

const LayoutHeader = ({
    handleOpenClose
}) => {
    const router = useRouter();

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Logo onClick={() => router.history.push('/home')}/>
            <Burger onClick={handleOpenClose}/>
        </div>
    )
}

export default LayoutHeader;