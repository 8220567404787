import React from 'react';
import Control from '../../control';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { renderer } from '../../../../settings/renderer';
import { setGetSubaffiliats } from '../../../../store/subaffiliats/thunk/setGetSubaffiliats';
import Filter from './block/filter';
import { translate } from '../../../../settings/dictionary';

const Subaffiliats = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetSubaffiliats({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.subaffiliats.list );

    const columns = [
        {label: translate('common.546'), name: 'login', width: 200, selector: (v) => v.slice(0, 5) + "******" + v.slice(10)},
        {label: translate('common.523'), name: 'count', width: 300},
        // {label: translate('common.305'), name: 'amount', width: 300},
    ];

    const handleChangePage = (page) => {
        dispatch(setGetSubaffiliats({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div>
            <Filter />
            <Control.Table
                columns={columns}
                list={list}
                handleChangePage={handleChangePage}
            />
        </div>
    )
}

export default Subaffiliats;