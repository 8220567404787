import React from 'react';
import Datepicker from '../../../../datepicker';
import { translate } from '../../../../../../settings/dictionary';

const Filter = ({
    control,
    register,
    setValue
}) => {
    const period = [{
        label: translate('common.461'),
        id: "day"
    }, {
        label: translate('common.300'),
        id: "general"
    }];
    
    return (
        <div className="filter-statistics-days">
            
            <div style={{ display: 'flex', justifyContent: 'space-between',  alignItems: 'center', gap: 10 }}>
                    <div style={{ display: 'flex', columnGap: 10 }}>
                        <div style={{
                            width: 123,
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 6
                        }}>
                            <span style={{
                                color: '#0D183F',
                                fontFamily: 'Work Sans, sans-serif',
                                fontSize: 14,
                                fontWeight: 400,
                                lineHeight: '16px'
                            }}>Stream ID</span>
                            <input 
                                {...register('stream_id')}
                                type="text" 
                                className="wallet__create__wrap__field"
                                placeholder="Stream ID"
                                style={{width: 300}}
                            />
                        </div>
                    </div>
                    <Datepicker period={period} control={control} register={register} setValue={setValue} />
                </div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 20
            }}>
                <button type="submit" className="filter-statistics-days__wrap__button" style={{border: 'none', outline: 'none'}}>
                    <span className="filter-statistics-days__wrap__button--text">{translate('common.40')}</span>
                </button>
            </div>
        </div>
    )
}

export default Filter;