import { putPassword } from "../api/putPassword";
import { isFunction } from "lodash";

export const setPutPassword = (params, handleShowMessage) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await putPassword(params);
            if (isFunction(handleShowMessage)) {
                handleShowMessage(data);
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else if (isFunction(handleShowMessage)) {
                handleShowMessage(error?.response?.data);
            }
        }
    }
}