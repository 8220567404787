import React from 'react';
import { ReactComponent as Sort } from '../../../../icons/sort.svg';
import { renderer } from '../../../../../settings/renderer';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { setGetWithdrawals } from '../../../../../store/withdrawals/thunk/setGetWithdrawals';
import PaginationFinance from '../paginationFinance';
import FakeTable from '../../../profile/block/FakeTable';
import { translate } from '../../../../../settings/dictionary';

const Table = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
       dispatch(setGetWithdrawals({
            page: 1,
            rowsPerPage: 10,
        }))
    }, [])

    const columns = [
        { label: translate('common.66'), value: "date" },
        { label: translate('common.67'), value: "wallet" },
        { label: translate('common.68'), value: "sum" },
        { label: translate('common.69'), value: 'course'},
        { label: translate('common.70'), value: 'spread'},
        { label: translate('common.71'), value: "status" },

    ];

    const list = useSelector((state) => state.withdrawals.list);

    const handleGetStatusText = (status) => {
        const value = status?.toString()?.toLowerCase();

        switch(value) {
            case "await": return translate('common.72');
            case "success": return translate('common.73');
            case "canceled": return translate('common.74');
        }
    }

    const handleGetStatusColor = (status) => {
        const value = status?.toString()?.toLowerCase();

        switch(value) {
            case "await": return "#F9C800";
            case "success": return "#48BB78";
            case "canceled": return "#FF403D";
        }
    }

    return (
        <div className="finance-table">
            <div>
                <h3 className="finance-table--title">{translate('common.75')}</h3>
            </div>
            <div className="finance-table__header">
                {
                    columns.map((item) => (
                        <div className="finance-table__header__column">
                            <span className="finance-table__header__column--text">{item.label}</span>
                            <Sort className="finance-table__header__column--sort-icon" />
                        </div>
                    ))
                }
            </div>

            {
                list?.data?.map((item, key) => (
                    <div className="finance-table__header" style={{borderTop: key && '1px solid #F2F2F2', display: 'flex', alignItems: 'center'}}>
                        <div className="finance-table__header__row">
                            <span className="finance-table__header__row--text">{format(new Date(Number(item.created_at)), 'dd.MM.yyyy HH:mm')}</span>
                        </div>
                        <div className="finance-table__header__row">
                            <span className="finance-table__header__row--text">{item.wallet}</span>
                        </div>
                        <div className="finance-table__header__row">
                            <span className="finance-table__header__row--text">{renderer.formatAmount(item.sum, 2)} {item.currency}</span>
                        </div>
                        <div className="finance-table__header__row">
                            <span className="finance-table__header__row--text">{item.course ? renderer.formatAmount(item.course, 2) : '-'}</span>
                        </div>
                        <div className="finance-table__header__row">
                            <span className="finance-table__header__row--text">{item.spread ? `${renderer.formatAmount(item.spread, 2)} ${item?.spread_currency}` : '-'}</span>
                        </div>
                        <div className="finance-table__header__row">
                            <div className="finance-table__header__row__button" style={{background: handleGetStatusColor(item.status)}}>
                                <span className="finance-table__header__row__button--text">{handleGetStatusText(item.status)}</span>
                            </div>
                        </div>
                    </div>
                ))
            }
            {
                list?.loading && (
                    <FakeTable
                        rows={10}
                        columns={6}
                        height_row={51}
                    />
                )
            }
            {
                list.pagination.totalPage > 1 && <PaginationFinance pagination={list.pagination} />
            }
        </div>
    )
}

export default Table;