import React from 'react';
import Banner from '../../../../icons/banner/home.png';

const BannerHome = () => {
    return (
        <div>
            <img src={Banner} alt="" className="home--banner" />
        </div>
    )
}

export default BannerHome;