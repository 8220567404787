import React from 'react';
import { translate } from '../../../../../settings/dictionary';

const SecurityTabs = ({
    activeTab,
    handleUpdateActiveTab
}) => {
    return (
        <div className="wallet__tabs">
            <div 
                onClick={() => handleUpdateActiveTab('logs')}
                className={`wallet__tabs__item ${activeTab === 'logs' && 'wallet__tabs__item--active'}`}>
                <span className={`wallet__tabs__item--text ${activeTab === 'logs' && 'wallet__tabs__item--text--active'}`}>
                    {translate('common.262')}
                </span>
            </div>
            <div
                onClick={() => handleUpdateActiveTab('security')} 
                className={`wallet__tabs__item ${activeTab === 'security' && 'wallet__tabs__item--active'}`}>
                <span className={`wallet__tabs__item--text ${activeTab === 'security' && 'wallet__tabs__item--text--active'}`}>
                    {translate('common.263')}
                </span>
            </div>
            <div
                onClick={() => handleUpdateActiveTab('postback')}
                className={`wallet__tabs__item ${activeTab === 'postback' && 'wallet__tabs__item--active'}`}>
                <span className={`wallet__tabs__item--text ${activeTab === 'postback' && 'wallet__tabs__item--text--active'}`}>
                    {translate('common.264')}
                </span>
            </div>
        </div>
    )
}

export default SecurityTabs;