import React from 'react';
import { useForm } from "react-hook-form";
import {ReactComponent as Eye} from '../../../../components/assets/eye.svg';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setPutPassword } from '../../../../../store/password/thunk/setPutPassword';
import { translate } from '../../../../../settings/dictionary';

const ChangePassword = () => {
    const dispatch = useDispatch();

    const { handleSubmit, register, watch, setValue, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            current_password: '',
            new_password: '',
            is_show_current_password: false,
            is_show_new_password: false
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    
    const handleShowMessage = ({message, status}) => {
        if (status === 'success') {
            reset()
        }
        enqueueSnackbar(message, {variant: status});
    }

    const onSubmit = data => dispatch(setPutPassword(data, handleShowMessage))

    const is_show_current_password = watch('is_show_current_password');
    const is_show_new_password = watch('is_show_new_password');
    const current_password = watch('current_password');
    const new_password = watch('new_password');

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="change-password">
            <label htmlFor="current_password" className="change-password__label" style={{width: 'auto'}}>
                <input 
                    {...register('current_password')}
                    className="change-password__label__field"
                    type={is_show_current_password ? "text" : "password"}
                    id="current_password"
                    placeholder={translate('common.135')}
                    style={{
                        width: 'calc(100% - 20px)'
                    }}
                />
                <Eye className="change-password__label--icon" onClick={() => setValue('is_show_current_password', !is_show_current_password)}/>
            </label>
            <label htmlFor="new_password" className="change-password__label" style={{width: 'auto'}}>
                <input 
                    {...register('new_password')}
                    className="change-password__label__field"
                    type={is_show_new_password ? "text" : "password"}
                    name="new_password" 
                    id="new_password"
                    placeholder={translate('common.483')}
                    style={{
                        width: 'calc(100% - 20px)'
                    }}
                />
                <Eye className="change-password__label--icon" onClick={() => setValue('is_show_new_password', !is_show_new_password)}/>
            </label>
            <div>
                <button type="submit" className="change-password__btn">
                    <span className="change-password__btn--text">{translate('common.138')}</span>
                </button>
            </div>
        </form>
    )
}

export default ChangePassword;