import React from 'react';
import { ReactComponent as Clip } from '../../../icons/clip.svg';
import { useForm } from "react-hook-form";
import Select from '../../select';
import { translate } from '../../../../settings/dictionary';

const PersonalPromo = () => {
    const { handleSubmit, register,
        // setValue, watch, control 
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            
        }
    });

    const onSubmit = data => console.log(data);

    const optionsTypePromo = [{
        label: translate('common.536'),
        value: "banners",
    },{
        label: "Direct Link",
        value: "direct_link",
    },{
        label: translate('common.270'),
        value: "landing",
    },{
        label: translate('common.271'),
        value: "thematics_landing",
    },{
        label: translate('common.273'),
        value: "event_landing",
    }];

    const optionsLanguage = [{
        label: "RU",
        value: "ru",
    },{
        label: "UA",
        value: "ua",
    },{
        label: "KZ",
        value: "kz",
    },{
        label: "TR",
        value: "tr",
    },{
        label: "GEL",
        value: "gel",
    }];

    const optionsCurrency = [{
        label: translate('common.289'),
        value: "rub"
    },{
        label: translate('common.290'),
        value: "usd"
    },{
        label: translate('common.291'),
        value: "tenge"
    },{
        label: translate('common.292'),
        value: "try"
    },{
        label: translate('common.293'),
        value: "gel"
    }]

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="personal-promo">
            <div className="personal-promo__container">
                <div>
                    <Select
                        options={optionsTypePromo}
                        placeholder={translate('common.141')}
                        register={register}
                        name="type_promo"
                    />
                </div>
                <div>
                    <Select
                        options={optionsLanguage}
                        placeholder={translate('common.294')}
                        register={register}
                        name="language"
                    />
                </div>
                <div>
                    <Select
                        options={optionsCurrency}
                        placeholder={translate('common.100')}
                        register={register}
                        name="currency"
                    />
                </div>
                <textarea 
                    {...register('description')}
                     className="personal-promo__container--description"
                    placeholder={translate('common.295')}
                />
                <label htmlFor="file">
                    <div className="personal-promo__container__file">
                        <Clip />
                        <span className="personal-promo__container__file--text">{translate('common.296')}</span>
                    </div>
                    <input {...register('image')} type="file" name="file" id="file" className="personal-promo__container__file--field" />
                </label>
                <div className="personal-promo__container__block">
                    <button type="submit" className="personal-promo__container__block__button">
                        <span className="personal-promo__container__block__button--text">{translate('common.297')}</span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default PersonalPromo;