import { getWallet } from "../api/getWallet";
import { setGetWalletAction } from "../action";

export const setGetWallet = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getWallet(params);

            dispatch(setGetWalletAction({
                data: data.data,
                pagination: data.pagination
            }))

        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}