import React from 'react';
import Rules from '../rules';
import Why from '../why';
import Comission from '../comission';
import Withdrawals from '../withdrawals';
import Faq from '../faq';
import Contacts from '../contacts';
import { useRouter } from '../../../../hooks/useRouter';
import { translate } from '../../../../settings/dictionary';

const Home = ({
    handleIsShowSignup
}) => {
    const router = useRouter();

    return (
        <div style={{ padding: '0 18px 0 17px', display: 'flex', flexDirection: 'column', rowGap: 80 }}>
            <Rules />
            <Why handleIsShowSignup={handleIsShowSignup} />
            <Comission />
            <Withdrawals />
            <Faq />
            <Contacts />
            <div style={{ display: 'flex', columnGap: 10, marginTop: 20 }}>
                <span onClick={() => router.history.push('/privacy')} style={{
                    color: '#FFF',
                    fontSize: 20,
                    fontWeight: 400,
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}>{translate('common.590')}</span>
                <span onClick={() => router.history.push('/agreements')} style={{
                    color: '#FFF',
                    fontSize: 20,
                    fontWeight: 400,
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}>{translate('common.591')}</span>
            </div>
        </div>
    )
}

export default Home;