import { isFunction } from "lodash";
import { postWithdrawals } from "../api/postWithdrawals";
import { setGetProfile } from "../../profile/thunk/setGetProfile";
import { setGetWithdrawals } from "./setGetWithdrawals";
import { setGetWithdrawalsAction } from "../action";
import { translate } from "../../../settings/dictionary";

export const setPostWithdrawals = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await postWithdrawals(params);
            if (isFunction(callback)) {
                callback({ message: translate('common.493'), status: 'success' });
            }

            dispatch(setGetProfile());
            
            dispatch(setGetWithdrawalsAction({
                data: [],
                pagination: {
                    total: 0,
                    currentPage: 0,
                    totalPage: 0,
                },
                loading: true
            }));
            
            dispatch(setGetWithdrawals({
                page: 1,
                rowsPerPage: 10,
            }));
        } catch (error) {
            if (isFunction(callback)) {
                callback({ message: translate('common.322'), status: 'error' });
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}