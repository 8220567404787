import { isFunction } from "lodash";
import { getSignup } from "../api/getSignup";
import { setGetSignin } from "./setSignin";
import { translate } from "../../../settings/dictionary";

export const setGetSignup = (params) => {
    return async (dispatch, getState) => {
        try {
            params.utm_source = localStorage.getItem('utm_source');
            params.utm_medium = localStorage.getItem('utm_medium');
            params.utm_campaign = localStorage.getItem('utm_campaign');
            params.utm_content = localStorage.getItem('utm_content');
            params.utm_term = localStorage.getItem('utm_term');
            params.sub_1 = localStorage.getItem('sub_1');
            params.sub_2 = localStorage.getItem('sub_2');
            params.sub_3 = localStorage.getItem('sub_3');
            params.sub_4 = localStorage.getItem('sub_4');
            params.sub_5 = localStorage.getItem('sub_5');
            params.stream = localStorage.getItem('stream');

            const {data} = await getSignup(params);

            if (isFunction(params?.handleOpenIsShowSuccessRegistration)) {
                params.handleOpenIsShowSuccessRegistration();
            }
            if (isFunction(params?.setIsDisabledRegistration)) {
                params.setIsDisabledRegistration();
            }
        } catch(error) {
            if (isFunction(params?.setIsDisabledRegistration)) {
                params.setIsDisabledRegistration();
            }
            if (isFunction(params?.setError)) {
                params.setError("error_registration", { types: { require: translate('common.321') } });
            }
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}