import React from 'react';
import { useRouter } from '../../../hooks/useRouter';
import { ReactComponent as Search } from '../../icons/search.svg';
import { ReactComponent as User } from '../../icons/user.svg';
import { ReactComponent as Exit } from '../../icons/exit.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setGetProfile } from '../../../store/profile/thunk/setGetProfile';
import { renderer } from '../../../settings/renderer';
import { translate } from '../../../settings/dictionary';

const Header = () => {
    const router = useRouter();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetProfile());
    }, []);
    const user = useSelector(state => state.profile.view);

    const handleGetTitle = () => {
        const { pathname } = router.location;

        switch (pathname) {
            case "/docs/agreement": return translate('common.192')
            case "/docs/documentation": return translate('common.193');
            case "/faq": return "FAQ";
            case "/finance": return translate('common.24');
            case "/news": return translate('common.194');
            case "/statistic/general": return translate('common.110');
            case "/statistic/promo": return translate('common.105');
            case "/statistic/referral": return translate("common.112");
            case "/statistic/gamer": return translate('common.195');
            case "/statistic/minus": return translate('common.58');
            case "/statistic/kohort": return translate('common.115');
            case "/statistic/days": return translate('common.196');
            case "/promo/create": return translate('common.197');
            case "/promo/my": return translate('common.198');
            case "/promo/personal": return translate('common.108');
            case "/home": return user?.name ? `${translate('common.541')}, ${user?.name}!` : `${translate('common.541')}!`;
            case "/profile": return translate('common.199');
            default: return "";
        }
    }
    const title = handleGetTitle();


    return (
        <div className="header">
            <div className="header__title">
                <h3 className="header__title__text">
                    {title}
                </h3>
            </div>
            <div className="header__navbar">
                {/* <div className="header__navbar__search">
                    <input
                        type="text"
                        className="header__navbar__search__field"
                        placeholder={translate('common.200')}
                    />
                    <Search className="header__navbar__search__icon" />
                </div> */}
                <div className="header__navbar__balance">
                    <span className="header__navbar__balance__text">{renderer.formatAmount(Number(user?.balance) || 0, 2)}$</span>
                </div>
                <Link to="/profile" className="header__navbar__circle">
                    <User />
                </Link>
                <div className="header__navbar__circle" onClick={() => {
                    localStorage.clear();
                    window.location.replace(window.location.origin);
                }}>
                    <Exit />
                </div>
            </div>
        </div>
    )
}

export default Header;