import { types } from './types';

export const setGetGamersAction = (payload) => {
  return {
    type: types.SET_GET_GAMERS,
    payload,
  }
}

export const setGetForDaysAction = (payload) => {
  return {
    type: types.SET_GET_FOR_DAYS,
    payload
  }
}

export const setGetGeneralAction = (payload) => {
  return {
    type: types.SET_GET_GENERAL,
    payload
  }
}

export const setGetPromoAction = (payload) => {
  return {
    type: types.SET_GET_PROMO,
    payload
  }
}

export const setGetKohortAction = (payload) => {
  return {
    type: types.SET_GET_KOHORT,
    payload
  }
}