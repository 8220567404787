import React from 'react';
import { useDispatch } from 'react-redux';
import { setGetWallet } from '../../../../../store/wallet/thunk/setGetWallet';
import { setGetWalletAction } from '../../../../../store/wallet/action';
import { translate } from '../../../../../settings/dictionary';
const WalletTabs = ({
    activeTab,
    setActiveTab
}) => {
    const dispatch = useDispatch();
    
    
    const handleUpdateActiveTab = (tab) => {
        setActiveTab(tab);
        dispatch(setGetWalletAction({
            pagination: {
                total: 0,
                currentPage: 0,
                totalPage: 0,
            },
            data: []
        }))

        dispatch(setGetWallet({
            page: 1,
            rowsPerPage: 5,
            is_active: tab === 'active' ? true : tab === 'noactive' ? false : undefined
        }));
    }

    return (
        <div className="wallet__tabs">
            <div 
                onClick={() => handleUpdateActiveTab('all')}
                className={`wallet__tabs__item ${activeTab === 'all' && 'wallet__tabs__item--active'}`}>
                <span className={`wallet__tabs__item--text ${activeTab === 'all' && 'wallet__tabs__item--text--active'}`}>{translate('common.37')}</span>
            </div>
            <div
                onClick={() => handleUpdateActiveTab('active')} 
                className={`wallet__tabs__item ${activeTab === 'active' && 'wallet__tabs__item--active'}`}>
                <span className={`wallet__tabs__item--text ${activeTab === 'active' && 'wallet__tabs__item--text--active'}`}>{translate('common.181')}</span>
            </div>
            <div
                onClick={() => handleUpdateActiveTab('noactive')}
                className={`wallet__tabs__item ${activeTab === 'noactive' && 'wallet__tabs__item--active'}`}>
                <span className={`wallet__tabs__item--text ${activeTab === 'noactive' && 'wallet__tabs__item--text--active'}`}>{translate('common.182')}</span>
            </div>
        </div>
    )
}

export default WalletTabs;