import React from 'react';
import { translate } from '../../../../settings/dictionary';

const Agreement = () => {
    return (
        <div className="agreement">
            <h3 className="agreement__title__big">{translate('common.372')}</h3>
            <ul className="agreement__list">
                <li className="agreement__text"><span className="agreement__bold">{translate('common.358')}</span> — {translate('common.373')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.359')}</span> — {translate('common.374')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.360')}</span> — {translate('common.375')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.361')}</span> — {translate('common.376')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.362')}</span> — {translate('common.377')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.363')}</span> — {translate('common.378')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.364')}</span> — {translate('common.379')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.365')}</span> — {translate('common.380')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.366')}</span> — {translate('common.381')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.367')}</span> — {translate('common.382')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.368')}</span> — {translate('common.383')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.369')}</span> — {translate('common.384')}</li>
                <li className="agreement__text"><span className="agreement__bold">{translate('common.370')}</span> — {translate('common.385')}</li>
                <li className="agreement__text"><span className="agreement__bold"></span> — {translate('common.386')}</li>
            </ul>
            <h3 className="agreement__title">{translate('common.387')}</h3>
            <div className="agreement__rules">
                <span className="agreement__text">{translate('common.388')}</span>
                <span className="agreement__text">{translate('common.389')}</span>
                <span className="agreement__text">{translate('common.390')}</span>
                <span className="agreement__text">{translate('common.391')}</span>
                <span className="agreement__text">{translate('common.392')}</span>
                <span className="agreement__text">{translate('common.393')}</span>
                <span className="agreement__text">{translate('common.394')}</span>
                <span className="agreement__text">{translate('common.395')}</span>
                <span className="agreement__text">{translate('common.396')}</span>
                <span className="agreement__text">{translate('common.397')}</span>
                <span className="agreement__text">{translate('common.398')}</span>
                <span className="agreement__text">{translate('common.399')}</span>
                <span className="agreement__text">{translate('common.400')}</span>
                <span className="agreement__text">{translate('common.401')}</span>
                <span className="agreement__text">{translate('common.402')}</span>
                <span className="agreement__text">{translate('common.403')}</span>
                <span className="agreement__text">{translate('common.404')}</span>
                <span className="agreement__text">{translate('common.405')}</span>
                <span className="agreement__text">{translate('common.406')}</span>
                <span className="agreement__text">{translate('common.407')}</span>
                <span className="agreement__text">{translate('common.408')}</span>
                <span className="agreement__text">{translate('common.409')}</span>
                <span className="agreement__text">{translate('common.410')}</span>
                <span className="agreement__text">{translate('common.411')}</span>
            </div>
            <h3 className="agreement__title">{translate('common.412')}</h3>
            <div className="agreement__comission">
                <span className="agreement__text">{translate('common.413')}</span> 
                <span className="agreement__text">{translate('common.414')}</span>
                <ul className="agreement__list__comission">
                    <li className="agreement__text">{translate('common.415')}</li>
                    <li className="agreement__text">{translate('common.416')}</li>
                </ul>
                <span className="agreement__text">{translate('common.417')}</span>
            </div>
            <h3 className="agreement__title">{translate('common.418')}</h3>
            <div className="agreement__calculation">
                <span className="agreement__text">{translate('common.419')}</span>
                <span className="agreement__text">{translate('common.420')}</span>
                <span className="agreement__text">{translate('common.421')}</span>
                <span className="agreement__text">{translate('common.422')}</span>
                <span className="agreement__text">{translate('common.423')}</span>
                <span className="agreement__text">{translate('common.424')}</span>
                <span className="agreement__text">{translate('common.425')}</span>
                <span className="agreement__text">{translate('common.426')}</span>
                <span className="agreement__text">{translate('common.427')}</span>
                <span className="agreement__text">{translate('common.428')}</span>
                <span className="agreement__text">{translate('common.429')}</span>
                <span className="agreement__text">{translate('common.430')}</span>
            </div>
            <h3 className="agreement__title">{translate('common.431')}</h3>
            <div className="agreement__calculation">
                <span className="agreement__text">{translate('common.432')}</span>
                <ul className="agreement__list__comission">
                    <li className="agreement__text">{translate('common.433')}</li>
                    <li className="agreement__text">{translate('common.434')}</li>
                    <li className="agreement__text">{translate('common.435')}</li>
                    <li className="agreement__text">{translate('common.436')}</li>
                    <li className="agreement__text">{translate('common.437')}</li>
                </ul>
                <span className="agreement__text">{translate('common.438')}</span>
                <span className="agreement__text">{translate('common.439')}</span>
                <span className="agreement__text">{translate('common.440')}</span>
                <span className="agreement__text">{translate('common.441')}</span>
                <ul className="agreement__list__comission">
                    <li className="agreement__text">{translate('common.442')}</li>
                    <li className="agreement__text">{translate('common.443')}</li>
                    <li className="agreement__text">{translate('common.444')}</li>
                    <li className="agreement__text">{translate('common.445')}</li>
                    <li className="agreement__text">{translate('common.446')}</li>
                </ul>
            </div>
            <h3 className="agreement__title">{translate('common.447')}</h3>
            <div className="agreement__calculation">
                <ul className="agreement__list__comission">
                    <li className="agreement__text">{translate('common.448')}</li>
                    <li className="agreement__text">{translate('common.449')}</li>
                    <li className="agreement__text">{translate('common.450')}</li>
                    <li className="agreement__text">{translate('common.451')}</li>
                </ul>
                <span className="agreement__text">{translate('common.452')}</span>
                <span className="agreement__text">{translate('common.453')}</span>
                <span className="agreement__text">{translate('common.454')}</span>
                <span className="agreement__text">{translate('common.455')}</span>
            </div>
        </div>
    )
}

export default Agreement;