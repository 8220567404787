import React from 'react';
import Filter from './block/filter';
import Control from '../../../../desktop2/components/control';
import { setGetKohort } from '../../../../store/statistics/thunk/setGetKohort';
import { useDispatch, useSelector } from 'react-redux';
import { renderer } from '../../../../settings/renderer';
import { translate } from '../../../../settings/dictionary';

const Kohort = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetKohort({
            page: 1,
            rowsPerPage: 10
        }))
    }, []);

    const list = useSelector(state => state.statistics.kohort);

    const columns = [
        // {
        //     label: translate('common.152'), 
        //     name: 'created_at', 
        //     width: 300,
        //     selector: (item) => {
        //         return format(new Date(Number(item)), 'dd.MM.yyyy HH:mm')
        //     } 
        // },


        {label: translate('common.485'), name: 'offer', width: 300},
        {label: translate('common.19'), name: 'stream', width: 300},
        {
            label: translate('common.533'), 
            name: 'sub_id', 
            width: 300,
        },
        {
            label: 'FD', 
            name: 'fd_count', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.153'), 
            width: 300,
            multiply: true,
            selector: (v) => renderer.formatAmount(v.fd_sum / v.fd_count)
        },
        {
            label: 'RD',
            name: 'rd_count',
            width: 300
        },
        {
            label: translate('common.154'), 
            width: 300,
            multiply: true,
            selector: (v) => v.rd_count > 0 ? renderer.formatAmount(v.rd_sum / v.rd_count) : 0
        },
        // {
        //     label: translate('common.155'), 
        //     name: 'active_gamer', 
        //     width: 300,
        //     selector: renderer.formatAmount
        // },
        {
            label: translate('common.169'), 
            name: 'total_replenishment', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.488'), 
            name: 'total_withdrawals', 
            width: 300,
            selector: renderer.formatAmount
        },
        {
            label: translate('common.369'), 
            name: 'hold_fiat_amount', 
            width: 300,
            selector: (v) => renderer.formatAmount(v) + '$'
        },
        {
            label: translate('common.490'), 
            name: 'canceled_fiat_amount', 
            width: 300,
            selector: (v) => renderer.formatAmount(v) + '$'
        },
        {
            label: translate("common.491"), 
            name: 'total_fiat_amount', 
            width: 300,
            selector: (v) => renderer.formatAmount(v) + '$'
        }
    ];

    const handleChangePage = (page) => {
        dispatch(setGetKohort({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20 }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.318')}
                </h3>
            </div>
            <div>
                {/* <Filter /> */}
                <Control.Table
                    columns={columns}
                    list={list}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    )
}

export default Kohort;