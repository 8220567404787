import React from 'react';
import {ReactComponent as Book} from './book.svg';
import {ReactComponent as Line} from './line.svg';
import {ReactComponent as Magnet} from './magnet.svg';
import {ReactComponent as Security} from './security.svg';
import {ReactComponent as Diamond} from './diamond.svg';
import {ReactComponent as Key} from './key.svg';
import {ReactComponent as Present} from './present.svg';
import { translate } from '../../../../settings/dictionary';

const Why = ({
    handleIsShowSignup
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 40 }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <span style={{
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>{translate('common.323')}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', rowGap: 40}}>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 17}}>
                    <div>
                        <Book />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 19,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.324')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 16,
                            fontWeight: 400
                        }}>{translate('common.325')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Line />
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 17}}>
                    <div>
                        <Magnet />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 19,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.327')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 16,
                            fontWeight: 400
                        }}>{translate('common.326')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Line />
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 17}}>
                    <div>
                        <Security />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 19,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.328')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 16,
                            fontWeight: 400
                        }}>{translate('common.103')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Line />
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 17}}>
                    <div>
                        <Diamond />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 19,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.330')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 16,
                            fontWeight: 400
                        }}>{translate('common.329')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Line />
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 17}}>
                    <div>
                        <Key />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 19,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.332')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 16,
                            fontWeight: 400
                        }}>{translate('common.331')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Line />
                </div>
                <div style={{display: 'flex', alignItems: 'center', columnGap: 17}}>
                    <div>
                        <Present />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{
                            color: '#FFF',
                            fontSize: 19,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.333')}</span>
                        <span style={{
                            color: '#FFF',
                            fontSize: 16,
                            fontWeight: 400
                        }}>{translate('common.334')}</span>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                    <div 
                        onClick={handleIsShowSignup}
                        style={{
                            padding: '20px 30px',
                            borderRadius: 20,
                            background: '#7B549E'
                        }}
                    >
                        <span style={{
                            color: '#FFF',
                            fontSize: 18,
                            fontWeight: 700,
                            textTransform: 'uppercase'
                        }}>{translate('common.335')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Why;