import React from 'react';
import { ReactComponent as Edit } from '../../../../components/assets/edit.svg';
import { translate } from '../../../../../settings/dictionary';

const WalletHeader = ({
    handleUpdateMode
}) => {
    return (
        <div className="wallet__header">
            <h3 className="wallet__header--title">
                {translate('common.266')}
            </h3>
            <div className="wallet__header__edit" onClick={() => handleUpdateMode()}>
                <span className="wallet__header__edit--text">{translate('common.267')}</span>
                <Edit style={{stroke: '#FFFFFF'}}/>
            </div>
        </div>
    )
}

export default WalletHeader;