import React from 'react';
import Calendar from '../common/calendar';

const Datepicker = ({
    register,
}) => {
    return (
        <div>
            <div className="filter-statistics-days__wrap">
                <div className="filter-statistics-days__wrap__datepicker">
                    {/* <span className="filter-statistics-days__wrap__datepicker--text">{translate('common.152')}</span> */}
                    <input {...register("dateFrom")} type="date" id=""  className="filter-statistics-days__wrap__datepicker__date"/>
                    &mdash;
                    <input {...register("dateTo")} type="date" id=""  className="filter-statistics-days__wrap__datepicker__date"/>
                    {/* {
                        period.map((item) => <span className="filter-statistics-days__wrap__datepicker--text">{item.label}</span>)
                    } */}
                </div>
            </div>
            {/* <Calendar /> */}
        </div>
    )
}

export default Datepicker;