import { setGetSubaffiliatsAction } from '../action';
import { getSubaffiliats } from '../api/getSubaffiliats';

export const setGetSubaffiliats = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getSubaffiliats(params);
            console.log(data)
            dispatch(setGetSubaffiliatsAction({
                data: data.data,
                pagination: data.pagination
            }))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}