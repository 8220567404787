
import { isFunction } from "lodash";
import { getPostbackGlobal } from "../api/getPostbackGlobal";
import { setGetPostbackGlobalAction } from "../action";

export const setGetPostbackGlobal = (params, handleShowMessage) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPostbackGlobal(params);
            dispatch(setGetPostbackGlobalAction(data.data));

        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else if (isFunction(handleShowMessage)) {
                handleShowMessage(error?.response?.data);
            }
        }
    }
}