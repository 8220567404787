import React from 'react';
import { ReactComponent as Settings } from '../../../../icons/settings.svg';
import ModalGlobal from './block/modal_global';
import ModalApplication from './block/modal_application';
import ModalCreate from './block/modal_create';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPostback } from '../../../../../store/postback/thunk/setGetPostback';
import PaginationLogs from '../paginationLogs';
import { format } from 'date-fns';
import PaginationPostback from './paginationPostback';
import FakeTable from '../FakeTable';
import { translate } from '../../../../../settings/dictionary';

const Postback = () => {
    const dispatch = useDispatch();

    const [isShowModalGlobal, setIsShowModalGlobal] = React.useState(false);
    const [isShowModalApplication, setIsShowModalApplication] = React.useState(false);
    const [isShowModalCreate, setIsShowModalCreate] = React.useState(false);

    const handleUpdateShowModalGlobal = () => {
        setIsShowModalGlobal(!isShowModalGlobal);
    }

    const handleUpdateShowModalApplication = () => {
        setIsShowModalApplication(!isShowModalApplication);
    }

    const handleUpdateShowModalCreate = () => {
        setIsShowModalCreate(!isShowModalCreate);
    }

    React.useEffect(() => {
        dispatch(setGetPostback({
            page: 1,
            rowsPerPage: 5
        }))
    }, [])

    const columns = [
        { label: translate('common.66'), value: "created_at" },
        { label: translate('common.246'), value: "type" },
        { label: translate('common.19'), value: "stream" },
        { label: "URL", value: "url" }
    ];

    const list = useSelector((state) => state.postback.list);

    const handleGetType = (type) => {
        const value = type?.toString().toLowerCase();

        switch(value){
            case "registration": return translate('common.247');
            case "fd": return translate('common.248');
            case "rd": return translate('common.249');
            default: return translate('common.250')
        }
    }

    return (
        <div>
            <div className="postback__header">
                {/* <div className="postback__header__btn" onClick={handleUpdateShowModalGlobal}>
                    <Settings />
                    <span className="postback__header__btn--text">{translate('common.252')}</span>
                </div>
                <div className="postback__header__btn" onClick={handleUpdateShowModalApplication}>
                    <Settings />
                    <span className="postback__header__btn--text">{translate('common.251')}</span>
                </div> */}
                <div className="postback__header__btn" onClick={handleUpdateShowModalCreate}>
                    <span className="postback__header__btn--text">{translate('common.312')}</span>
                </div>
            </div>
            {
                isShowModalGlobal && <ModalGlobal handleUpdateShowModalGlobal={handleUpdateShowModalGlobal} />
            }
            {
                isShowModalApplication && <ModalApplication handleUpdateShowModalApplication={handleUpdateShowModalApplication} />
            }
            {
                isShowModalCreate && <ModalCreate handleUpdateShowModalCreate={handleUpdateShowModalCreate} />
            }
            <div>
                <div className="wallet-table__header">
                    {
                        columns.map((item) => (
                            <div className="wallet-table__header__column">
                                <span className="wallet-table__header__column--text">{item.label}</span>
                                {/* <Sort className="wallet-table__header__column--sort-icon" /> */}
                            </div>
                        ))
                    }
                </div>

                {
                    list?.data?.map((item, key) => (
                        <div className={`wallet-table__header ${key && "wallet-table__header--border"}`} >
                            <div className="wallet-table__header__row">
                                <span className="wallet-table__header__row--text">{format(new Date(Number(item.created_at)), 'HH:mm dd.MM.yyyy')}</span>
                            </div>
                            <div className="wallet-table__header__row">
                                <span className="wallet-table__header__row--text">{handleGetType(item.type)}</span>
                            </div>
                            <div className="wallet-table__header__row">
                                <span className="wallet-table__header__row--text">{item.stream}</span>
                            </div>
                            <div className="wallet-table__header__row">
                                <span className="wallet-table__header__row--text">{item.url}</span>
                            </div>
                        </div>
                    ))
                }
                {
                    list?.loading && (
                        <FakeTable
                            rows={5}
                            columns={4}
                        />
                    )
                } 
                {
                    list.pagination.totalPage > 1 && <PaginationPostback pagination={list.pagination} />
                }
            </div>
        </div>
    )
}

export default Postback;