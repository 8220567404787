import React from 'react';
import { ReactComponent as ChipTopLeft } from './chipTopLeft.svg';
import { ReactComponent as ChipTopRight } from './chipTopRight.svg';
import { ReactComponent as ChipBottomRight } from './chipBottomRight.svg';
import { ReactComponent as ChipBottomLeft } from './chipBottomLeft.svg';
import { ReactComponent as ChipPaddingLeft } from './chipPaddingLeft.svg';
import { ReactComponent as ChipPaddingRight } from './chipPaddingRight.svg';
import { ReactComponent as ChipPaddingBottom } from './chipPaddingBottom.svg';
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from 'react-redux';
import Messanger from '../messanger';
import { ReactComponent as Rus } from './rus.svg';
import { ReactComponent as Cross } from './cross.svg';
import { setGetSignup } from '../../../../store/sign/thunk/setSignup';
import { translate } from '../../../../settings/dictionary';

const SignUp = ({
    handleIsShowSignup,
    handleOpenIsShowSuccessRegistration
}) => {
    const dispatch = useDispatch();
    const { handleSubmit, register, control, setValue, setError, formState: { errors } } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            password: "",
            name: "",
            email: "",
            phone: "",
            messanger: "",
            messanger_type: "telegram",
            password: "",
            repeat_password: ""
        }
    });
    const [isDisabledRegistration, setIsDisabledRegistration] = React.useState(false);

    const email = useWatch({
        control,
        name: 'email'
    });

    const name = useWatch({
        control,
        name: 'name'
    })

    const phone = useWatch({
        control,
        name: 'phone'
    })

    const messanger = useWatch({
        control,
        name: 'messanger'
    });

    const password = useWatch({
        control,
        name: 'password'
    })

    const repeat_password = useWatch({
        control,
        name: 'repeat_password'
    })

    const messanger_type = useWatch({
        control,
        name: "messanger_type"
    })

    const isFormValid = !email
        || !phone
        || !messanger
        || !password
        || !repeat_password
        || !name
        || (password !== repeat_password);

    const onSubmit = (store) => {
        if (!isFormValid && !isDisabledRegistration) {
            setIsDisabledRegistration(true)
            store.setError = setError;
            store.setIsDisabledRegistration = setIsDisabledRegistration;
            store.handleOpenIsShowSuccessRegistration = handleOpenIsShowSuccessRegistration;
            dispatch(setGetSignup(store))
        }
    }

    const handleCheckValidation = () => {
        if (!email) {
            setError("email", { types: { require: translate('common.220') } });
        }

        if (!phone) {
            setError("phone", { types: { require: translate('common.221') } });
        }

        if (!phone) {
            setError("name", { types: { require: translate('common.222') } });
        }

        if (!password) {
            setError("password", { types: { require: translate('common.223') } });
        }

        if (!repeat_password) {
            setError("repeat_password", { types: { require: translate('common.224') } });
        }
        if (!messanger) {
            setError("messanger", { types: { require: `${translate('common.225')} ${messanger_type?.[0].toUpperCase() + messanger_type.slice(1)}` } });
        }

        if (repeat_password !== password) {
            setError("password", { types: { require: translate('common.90') } });
            setError("repeat_password", { types: { require: translate('common.90') } });
        }
    }

    const error_name = errors?.name?.types?.require;
    const error_email = errors?.email?.types?.require;
    const error_phone = errors?.phone?.types?.require;
    const error_messanger = errors?.messanger?.types?.require;
    const error_password = errors?.password?.types?.require;
    const error_repeat_password = errors?.repeat_password?.types?.require;
    const error_registration = errors?.error_registration?.types?.require;
    return (
        <form onSubmit={handleSubmit(onSubmit)} 
            style={{ 
                position: 'fixed', 
                zIndex: 999999, 
                top: 0, 
                left: 0, 
                width: '100vw', 
                height: '100vh', 
                background: '#211F1C',
            }}>
            
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: 40,
                    overflow: 'hidden'
                }}>
                    <div>
                        <span style={{
                            color: '#FFF',
                            fontSize: 40,
                            fontWeight: 400
                        }}>{translate('common.247')}</span>
                    </div>
                    <div style={{ display: 'flex', columnGap: 40, flexDirection: 'column', position: 'relative' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            position: 'relative',
                            width: '100%',
                        }}>
                            <label htmlFor='name' style={{ position: 'relative' }}>
                                <input
                                    {...register('name')}
                                    type="text"
                                    style={{
                                        borderRadius: 5,
                                        border: error_name ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        width: 280
                                    }}
                                    placeholder={translate('common.525')}
                                />
                                {
                                    error_name && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_name}</span>
                                    )
                                }
                            </label>
                            <label htmlFor="" style={{ position: 'relative' }}>
                                <input
                                    {...register('email')}
                                    type="text"
                                    style={{
                                        borderRadius: 5,
                                        border: error_email ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        width: 280
                                    
                                    }}
                                    placeholder={translate('common.516')}
                                />
                                {
                                    error_email && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_email}</span>
                                    )
                                }
                            </label>

                            <label htmlFor="phone" style={{ position: 'relative' }}>
                                <Rus style={{ position: 'absolute', top: 12, left: 20, borderRadius: '50%' }} />
                                <input
                                    {...register('phone')}
                                    type="text"
                                    style={{
                                        borderRadius: 5,
                                        border: error_phone ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px 12px 55px',
                                        minWidth: 245,
                                        width: 245,
                                        maxWidth: 245
                                    }}
                                    placeholder={translate('common.526')}
                                />
                                {
                                    error_phone && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_phone}</span>
                                    )
                                }
                            </label>
                            <Messanger
                                register={register}
                                control={control}
                                setValue={setValue}
                                error_messanger={error_messanger}
                            />
                            <label htmlFor="" style={{ position: 'relative' }}>
                                <input
                                    {...register('password')}
                                    type="password"
                                    style={{
                                        borderRadius: 5,
                                        border: error_password ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        minWidth: 280,
                                        width: 280,
                                        maxWidth: 280
                                    }}
                                    placeholder={translate('common.527')}
                                />
                                {
                                    error_password && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_password}</span>
                                    )
                                }
                            </label>

                            <label htmlFor="" style={{ position: 'relative' }}>
                                <input
                                    {...register('repeat_password')}
                                    type="password"
                                    style={{
                                        borderRadius: 5,
                                        border: error_repeat_password ? '1px solid #e6252c' : '1px solid #8F8F8F',
                                        background: '#FFF',
                                        outline: 'none',
                                        color: '#000',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        padding: '12px 20px',
                                        minWidth: 280,
                                        width: 280,
                                        maxWidth: 280
                                    }}
                                    placeholder={translate('common.226')}
                                />
                                {
                                    error_repeat_password && (
                                        <span style={{
                                            fontSize: 12,
                                            color: '#e6252c',
                                            fontWeight: 400,
                                            position: 'absolute',
                                            bottom: -15,
                                            left: 0
                                        }}>{error_repeat_password}</span>
                                    )
                                }
                            </label>
                        </div>
                    </div>
                    <div style={{width: 300, wordBreak: 'break-word', textAlign: 'center'}}>
                        <span style={{
                                color: 'rgb(255, 255, 255)',
                                fontSize: 20,
                                fontWeight: 400
                        }}>
                            {translate('common.592')} <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location.replace('/agreements')}>{translate('common.593')}</span> {translate('common.594')} <span  style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => window.location.replace('/privacy')}>{translate('common.595')}</span>
                        </span>
                    </div>
                    <div>
                        <div onClick={() => {
                            if (isFormValid) {
                                handleCheckValidation()
                            }
                        }}>
                            <button
                                type={isFormValid ? "button" : "submit"}
                                style={{
                                    background: '#7B549E',
                                    borderRadius: 15,
                                    padding: '10px 40px',
                                    borderRadius: 15,
                                    cursor: 'pointer',
                                    border: 'none'
                                }}
                            >
                                <span style={{
                                    color: '#FFF',
                                    fontSize: 20,
                                    fontWeight: 400,
                                }}>{translate('common.227')}</span>
                            </button>
                        </div>
                    </div>
                    {
                            error_registration && (
                                <div style={{textAlign: 'center'}}>
                                    <span style={{
                                        color: '#E6252C',
                                        fontSize: 16,
                                        fontWeight: 400,
                                      
                                    }}>{error_registration}</span>
                                </div>
                            )
                        }
                    <Cross style={{ position: 'absolute', top: 30, right: 30, cursor: 'pointer' }} onClick={handleIsShowSignup} />
                </div>
          
        </form>
    )
}

export default SignUp;