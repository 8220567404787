import { types } from './types';

export const setGetPostbackGlobalAction = (payload) => {
  return {
    type: types.SET_GET_GLOBAL_POSTBACK,
    payload,
  }
}

export const setGetPostbackApplicationAction = (payload) => {
  return {
    type: types.SET_GET_APPLICATION_POSTBACK,
    payload,
  }
}

export const setGetPostbackAction = (payload) => {
  return {
    type: types.SET_GET_POSTBACK,
    payload
  }
}