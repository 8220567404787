import React from 'react';
import Navigation from './components/navigation';
import { Route, Switch, Redirect } from 'react-router-dom';
import './scss/index.scss';
import Agreement from './components/docs/Agreement';
import Header from './components/header';
import Documentation from './components/docs/Documentation';
import Faq from './components/faq';
import Finance from './components/finance';
import Profile from './components/profile';
import Home from './components/home';
import Referral from './components/referral';
import PersonalPromo from './components/promo/personalPromo';
import General from './components/statistics/general';
import PromoStatistics from './components/statistics/promo';
import GamerStatistics from './components/statistics/gamer';
import KohortStatistics from './components/statistics/kohort';
import DaysStatistics from './components/statistics/days';
import MyPromo from './components/promo/my';
import Authorization from './components/authorization';
import CreatePromo from './components/promo/create';
import Registration from './components/registration';
import Landing from './components/landing';
import Subaffiliats from './components/statistics/subaffiliats';
import Politica from './politika';
import AgreementRules from './rules';

const Desktop = () => {
    if (!localStorage.getItem('token')) {
        return (
            <Switch>
                <Route exact={true} path="/signin" render={() => <Authorization />} />  
                <Route exact={true} path="/signup" render={() => <Registration />} />  
                <Route exact={true} path="/" render={() => <Landing />} />
                <Route exact={true} path="/privacy" render={() => <Politica />} /> 
                <Route exact={true} path="/agreements" render={() => <AgreementRules />} /> 
                <Redirect exact from="*" to="/" />
            </Switch>
        )
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F5F6FA',
            height: '100%',
        }}>
            <div style={{
                width: 270,
                minWidth: 270,
                maxWidth: 270
            }}>
                <Navigation />
            </div>
        <div style={{ width: '100%', padding: '0 20px', overflow: 'hidden', height: '100vh', overflowY: 'scroll' }}>
            <Header />
            <Switch>
                <Route exact={true} path="/docs/agreement" render={() => <Agreement />} />  
                <Route exact={true} path="/docs/documentation" render={() => <Documentation />} />
                <Route exact={true} path="/faq" render={() => <Faq />} />
                <Route exact={true} path="/finance" render={() => <Finance />} />
                <Route exact={true} path="/profile" render={() => <Profile />} />
                <Route exact={true} path="/home" render={() => <Home />} />
                <Route exact={true} path="/statistic/referral" render={() => <Referral />} />
                <Route exact={true} path="/statistic/general" render={() => <General />} />
                <Route exact={true} path="/statistic/promo" render={() => <PromoStatistics />} />
                <Route exact={true} path="/statistic/gamer" render={() => <GamerStatistics />} />
                <Route exact={true} path="/statistic/kohort" render={() => <KohortStatistics />} />
                <Route exact={true} path="/statistic/days" render={() => <DaysStatistics />} />
                <Route exact={true} path="/promo/my" render={() => <MyPromo />} />
                <Route exact={true} path="/promo/personal" render={() => <PersonalPromo />} />
                <Route exact={true} path="/promo/create" render={() => <CreatePromo />} />
                <Route exact={true} path="/statistic/subaffiliats" render={() => <Subaffiliats />} />
                <Redirect exact from="/" to="home" />
            </Switch>
        </div>
        </div >
    )
}

export default Desktop;