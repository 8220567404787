import React from 'react';
import { ReactComponent as Whatsapp } from './whatsapp.svg';
import { ReactComponent as Telegram } from './telegram.svg';
import { ReactComponent as Skype } from './skype.svg';
import { useWatch } from 'react-hook-form';
import { translate } from '../../../../settings/dictionary';

const Messanger = ({
    register,
    setValue,
    control,
    error_messanger
}) => {

    const messanger_type = useWatch({
        control,
        name: 'messanger_type'
    })

    const handleGetActiveIcon = (value) => {
        switch (value) {
            case "whatsapp": return <Whatsapp style={{ position: 'absolute', top: 10, left: 20, cursor: 'pointer' }} />
            case "telegram": return <Telegram style={{ position: 'absolute', top: 10, left: 20, cursor: 'pointer' }} />
            case "skype": return <Skype style={{ position: 'absolute', top: 10, left: 20, cursor: 'pointer' }} />
        }
    }

    const handleGetActivePlaceholder = (value) => {
        switch (value) {
            case "whatsapp": return translate('common.549');
            case "telegram": return "Telegram";
            case "skype": return "Skype";
        }
    }

    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <label htmlFor="" style={{ position: 'relative' }}>
            <span onClick={() => setIsOpen(!isOpen)}>{handleGetActiveIcon(messanger_type)}</span>
            {
                isOpen && (
                    <div style={{ padding: '12px 20px', width: 'calc(100% - 42px)', position: 'absolute', display: 'flex', flexDirection: 'column', rowGap: 10, zIndex: 100, top: 48, left: 0, background: '#fff', borderRadius: 5, border: '1px solid #8F8F8F' }}>
                        <div
                            style={{ display: 'flex', alignItems: 'center', columnGap: 10, cursor: 'pointer' }}
                            onClick={() => {
                                setValue('messanger_type', 'telegram')
                                setIsOpen(!isOpen)
                            }}
                        >
                            <Telegram />
                            <span style={{
                                color: '#000',
                                fontSize: 16,
                                fontWeight: 400
                            }}>Telegram</span>
                        </div>
                        <div
                            style={{ display: 'flex', alignItems: 'center', columnGap: 10, cursor: 'pointer' }}
                            onClick={() => {
                                setValue('messanger_type', 'skype')
                                setIsOpen(!isOpen)
                            }}
                        >
                            <Skype />
                            <span style={{
                                color: '#000',
                                fontSize: 16,
                                fontWeight: 400
                            }}>Skype</span>
                        </div>
                    </div>
                )
            }
            <input
                {...register('messanger')}
                type="text"
                style={{
                    borderRadius: 5,
                    border: error_messanger ? '1px solid #e6252c' : '1px solid #8F8F8F',
                    background: '#FFF',
                    outline: 'none',
                    color: '#000',
                    fontSize: 16,
                    fontWeight: 400,
                    padding: '12px 20px 12px 55px',
                    minWidth: 245,
                    width: 245,
                    maxWidth: 245,
                }}
                placeholder={handleGetActivePlaceholder(messanger_type)}
            />
            {
                error_messanger && (
                    <span style={{
                        fontSize: 12,
                        color: '#e6252c',
                        fontWeight: 400,
                        position: 'absolute',
                        bottom: -15,
                        left: 0
                    }}>{error_messanger}</span>
                )
            }
        </label>
    )
}

export default Messanger;