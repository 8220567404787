import React from 'react';
import FaqItem from './block/FaqItem';
import { faq_list } from './faq.config';
import { translate } from '../../../settings/dictionary';

const Faq = () => {
    return (
        <div className="faq" style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            paddingBottom: 50
        }}>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.311')}
                </h3>
            </div>

            {
                faq_list.map((item) => <FaqItem {...item} />)
            }

        </div>
    )
}

export default Faq;