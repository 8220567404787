import React from 'react';
import { ReactComponent as ChipTopLeft } from './chipTopLeft.svg';
import { ReactComponent as ChipTopRight } from './chipTopRight.svg';
import { ReactComponent as ChipBottomRight } from './chipBottomRight.svg';
import { ReactComponent as ChipBottomLeft } from './chipBottomLeft.svg';
import { ReactComponent as ChipPaddingLeft } from './chipPaddingLeft.svg';
import { ReactComponent as ChipPaddingRight } from './chipPaddingRight.svg';
import { ReactComponent as ChipPaddingBottom } from './chipPaddingBottom.svg';
import { ReactComponent as Cross } from './cross.svg';
import { useForm, useWatch } from "react-hook-form";
import { setGetSignin } from '../../../../store/sign/thunk/setSignin';
import { useDispatch } from 'react-redux';
import { translate } from '../../../../settings/dictionary';

const SignIn = ({
    handleIsShowSignin
}) => {
    const dispatch = useDispatch();
    const { handleSubmit, register, control, setError, formState: { errors }, clearErrors } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            login: "",
            password: ""
        }
    });

    const login = useWatch({
        control,
        name: 'login'
    })

    const password = useWatch({
        control,
        name: 'password'
    })

    React.useEffect(() => {
        clearErrors('wrong_password');
    }, [login, password])

    const onSubmit = (store) => {
        store.setError = setError;
        if (store.login && store.password) {
            dispatch(setGetSignin(store))
        } else {
            setError("wrong_password", { types: { require: translate('common.218') } });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'fixed', zIndex: 999999, top: 0, left: 0, width: '100vw', height: '100vh', background: '#211F1C', }}>
       
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    rowGap: 40,
                    overflow: 'hidden'
                }}>
                    <div>
                        <span style={{
                            color: '#FFF',
                            fontSize: 40,
                            fontWeight: 400
                        }}>{translate('common.510')}</span>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                        position: 'relative'
                    }}>
                        <input
                            {...register('login')}
                            type="text"
                            style={{
                                borderRadius: 5,
                                border: '1px solid #8F8F8F',
                                background: '#FFF',
                                outline: 'none',
                                color: '#000',
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '12px 20px',
                                minWidth: 280,
                                width: 280,
                                maxWidth: 280
                            }}
                            placeholder={translate('common.516')}
                        />
                        <input
                            {...register('password')}
                            type="password"
                            style={{
                                borderRadius: 5,
                                border: '1px solid #8F8F8F',
                                background: '#FFF',
                                outline: 'none',
                                color: '#000',
                                fontSize: 16,
                                fontWeight: 400,
                                padding: '12px 20px',
                                minWidth: 280,
                                width: 280,
                                maxWidth: 280
                            }}
                            placeholder={translate('common.524')}
                        />
                        {
                            errors?.wrong_password?.types?.require && (
                                <span style={{
                                    color: '#E6252C',
                                    fontSize: 16,
                                    fontWeight: 400,
                                    position: 'absolute',
                                    bottom: -28
                                }}>{errors?.wrong_password?.types?.require}</span>
                            )
                        }
                    </div>
                    
                    <div>
                        <button type="submit" style={{
                            background: '#7B549E',
                            borderRadius: 15,
                            padding: '10px 40px',
                            borderRadius: 15,
                            cursor: 'pointer',
                            border: 'none'
                        }}>
                            <span style={{
                                color: '#FFF',
                                fontSize: 20,
                                fontWeight: 400,
                            }}>{translate('common.219')}</span>
                        </button>
                    </div>
                    <Cross style={{ position: 'absolute', top: 30, right: 30, cursor: 'pointer' }} onClick={handleIsShowSignin}/>
                </div>
     
        </form>
    )
}

export default SignIn;