import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetPromo } from '../../../../store/promo/thunk/setGetPromo';
import format from 'date-fns/format';
import Control from '../../../../desktop2/components/control';
import { translate } from '../../../../settings/dictionary';

const MyPromo = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetPromo({
            page: 1,
            rowsPerPage: 10,
        }))
    }, []);

    const list = useSelector(state => state.promo.list);
    const handleGetTrafficsType = (value) => {
        const type = value?.toString()?.toLowerCase();

        switch (type) {
            case "aso_brand": return translate('common.551');
            case "aso_cross": return translate('common.280');
            case "context_brand": return translate('common.499');
            case "context_cross": return translate('common.281');
            case "seo_brand": return translate('common.501');
            case "seo_cross": return translate('common.282');
            case "fb_webview": return translate('common.538');
            case "fb_native": return translate('common.94');
            case "uac_webview": return translate('common.537');
            case "uac_native": return translate('common.95');
            case "tt_webview": return translate('common.96');
            case "tt_native": return translate('common.97');
            case "innapp": return translate('common.498');
            case "schemes_without_support": return translate('common.497');
            case "schemes_with_support": return translate('common.98');
            case "influencers": return translate('common.99');
            case "teaser": return translate('common.283');
            case "newsletter": return translate('common.284');
            case "streams": return translate('common.285');
            case "another": return translate('common.496');
            default: return translate('common.250')
        }
    };

    const handleGetTypePromo = (value) => {
        const type = value?.toString().toLowerCase();

        switch (type) {
            case "landing": return translate('common.270');
            case "direct_link": return "Direct Link";
            case "thematics_landing": return translate('common.271');
            case "banners": return translate('common.536');
            case "frame_slots": return translate('common.495');
            case "video": return translate('common.272');
        }
    }

    const columns = [
        { 
            label: translate('common.287'), 
            name: 'type_traffic',
            width: 200,
            selector: handleGetTrafficsType
        },
        { label: translate('common.148'), name: 'label', width: 300 },
        { label: translate('common.484'), name: 'stream', width: 300 },
        { label: translate('common.485'), name: 'offer', width: 100 },
        { 
            label: translate('common.141'),
            name: 'type_promo', 
            width: 200,
            selector: handleGetTypePromo
        },
        { label: 'URL', name: 'url', width: 500 },
        { 
            label: translate('common.486'), 
            name: 'created_at', 
            width: 250,
            selector: (item) => {
                return format(new Date(Number(item)), 'dd.MM.yyyy')
            }
        }
    ];

   

    const handleChangePage = (page) => {
        dispatch(setGetPromo({
            page,
            rowsPerPage: 10,
        }));
    }

    return (
        <div>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.198')}
                </h3>
            </div>
            <Control.Table
                columns={columns}
                list={list}
                handleChangePage={handleChangePage}
                // handleChangeIsShowModalEdit={() => console.log('+++')}
                disabled_settings
            />
        </div>
    )
}

export default MyPromo;