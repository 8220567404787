import React from 'react';
import Wallet from './block/wallet';
import ProfileInformation from './block/profileInformation';
import Security from './block/security';

const Profile = () => {
    return (
        <div style={{ display: 'flex', columnGap: 20, width: '100%', alignItems: 'flex-start', paddingBottom: 70}}>
            <ProfileInformation />
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20, width: '80%' }}>
                <Wallet />
                <Security />
            </div>
        </div>
    )
}

export default Profile;