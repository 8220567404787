
import { isFunction } from "lodash";
import { postPostback } from "../api/postPostback";
import { setGetPostback } from "./setGetPostback";
import { setGetPostbackAction } from "../action";

export const setPostPostback = (params, handleShowMessage) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postPostback(params);

            if (isFunction(handleShowMessage)) {
                handleShowMessage(data);
            }

            dispatch(setGetPostbackAction({
                data: [],
                pagination: {
                    total: 0,
                    currentPage: 0,
                    totalPage: 0
                },
                loading: true
            }));
            
            dispatch(setGetPostback({
                page: 1,
                rowsPerPage: 5
            }))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else if (isFunction(handleShowMessage)) {
                handleShowMessage(error?.response?.data);
            }
        }
    }
}