import React from 'react';
import {ReactComponent as Visa} from './visa.svg';
import {ReactComponent as Line} from './line.svg';
import {ReactComponent as Mastercard} from './mastercard.svg';
import {ReactComponent as Tether} from './tether.svg';
import {ReactComponent as Etherium} from './etherium.svg';
import {ReactComponent as Litecoin} from './litecoin.svg';
import {ReactComponent as Tron} from './tron.svg';
import {ReactComponent as Ripple} from './ripple.svg';
import {ReactComponent as Dogicoin} from './dogicoin.svg';
import { translate } from '../../../../settings/dictionary';

const Withdrawals = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 40 }}>
            <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                <span style={{
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>{translate('common.528')}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 40}}>
                <Visa />
                <Line />
                <Mastercard />
                <Line />
                <Tether />
                <Line />
                <Etherium />
                <Line />
                <Litecoin />
                <Line />
                <Tron />
                <Line />
                <Ripple />
                <Line />
                <Dogicoin />
            </div>
        </div>
    )
}

export default Withdrawals;