import React from 'react';
import {ReactComponent as DirectLink} from '../../../../../../components/assets/direct_link.svg';
import {ReactComponent as Landing} from '../../../../../../components/assets/direct_link.svg';
import {ReactComponent as ThematicsLanding} from '../../../../../../components/assets/direct_link.svg';
import {ReactComponent as Banners} from '../../../../../../components/assets/direct_link.svg';
import {ReactComponent as FrameSlots} from '../../../../../../components/assets/direct_link.svg';
import {ReactComponent as Video} from '../../../../../../components/assets/direct_link.svg';

import ItemPromo from './block/itemPromo';
import { translate } from '../../../../../../../settings/dictionary';

const ContentStep2 = ({
    watch,
    setValue
}) => {
    return (
        <div style={{display: 'flex', alignItems: 'flex-start', gap: 20, flexWrap: 'wrap'}}>
            <ItemPromo 
                Icon={DirectLink}
                label="Direct Link"
                type="direct_link"
                watch={watch}
                setValue={setValue}
            />
            <ItemPromo 
                Icon={Landing}
                label={translate('common.270')}
                type="landing"
                watch={watch}
                setValue={setValue}
            />
            <ItemPromo 
                Icon={ThematicsLanding}
                label={translate('common.271')}
                type="thematics_landing"
                watch={watch}
                setValue={setValue}
            />
            <ItemPromo 
                Icon={Banners}
                label={translate('common.536')}
                type="banners"
                watch={watch}
                setValue={setValue}
            />
            <ItemPromo 
                Icon={FrameSlots}
                label={translate('common.495')}
                type="frame_slots"
                watch={watch}
                setValue={setValue}
            />
            <ItemPromo 
                Icon={Video}
                label={translate('common.272')}
                type="video"
                watch={watch}
                setValue={setValue}
            />
        </div>
    )
}

export default ContentStep2;