import {types} from './types';

const initialState = {
    gamers: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0
        },
        data: []
    },
    for_days: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0
        },
        data: []
    },
    general: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0
        },
        data: []
    },
    promo: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0
        },
        data: []
    },
    kohort: {
        pagination: {
            total: 0,
            currentPage: 0,
            totalPage: 0
        },
        data: []
    }
}

export function statisticsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_GAMERS:
            return { ...state, gamers: action.payload};

        case types.SET_GET_FOR_DAYS:
            return { ...state, for_days: action.payload };

        case types.SET_GET_GENERAL: 
            return { ...state, general: action.payload };

        case types.SET_GET_PROMO: 
            return { ...state, promo: action.payload };

        case types.SET_GET_KOHORT:
            return { ...state, kohort: action.payload };

        default:
            return state
    }
}