import React from 'react';
import Withdrawal from './withdrawal';
import ModalWithdrawals from './components/Modal/ModalWithdrawal';
import Table from './table';
import { translate } from '../../../settings/dictionary';

const Finance = () => {
    const [isShowModal, setIsShowModal] = React.useState(false);

    const handleChangeShowModal = () => {
        setIsShowModal(!isShowModal)
    }

    return (
        <div>
            <div className="header__title">
                <h3 className="header__title__text" style={{ fontSize: 19 }}>
                    {translate('common.118')}
                </h3>
            </div>
            <Withdrawal handleChangeShowModal={handleChangeShowModal} />
            <Table />
            {
                isShowModal && (
                    <ModalWithdrawals handleChangeShowModal={handleChangeShowModal} />
                )
            }

        </div>
    )
}

export default Finance;